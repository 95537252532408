import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DistrictAdd({ onCancel }) {
  let [checkboxAdd, setCheckboxAdd] = useState(true);
  const [addValue, setAddValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('DIYAN_IE_authToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        districtName: addValue,
        isActive: checkboxAdd
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Territory/SaveDistrict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        if (responseData.message === "Record is already exists") {
          toast.error("Duplicate entry is not permissable");
        } else {
          toast.success("Record Inserted successfully");
          setAddValue("");
        }
        // console.log("Record Inserted successfully:", responseData.message);
        setTimeout(() => {
          onCancel();
          // window.location.reload(); // Reload the page or fetch updated list
        }, 1500);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing District / Province ration:", error);
      toast.error("Error performing District / Province operation");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
          <h1 className="text-xl font-weight-[400]">Add District / Province</h1>
          <div className="flex  item-center text-sm"></div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-2 mx-auto">
            <table className="max-w-full popup-table">
              <tbody>
                <tr>
                  <td>
                    <CustomTextField2
                      label={"District / Province"}
                      placeholder={"ENTER"}
                      value={addValue}
                      onChange={setAddValue}
                    ></CustomTextField2>
                  </td>
                  <td>
                    <CustomCheckBox2
                      label={"Is Active"}
                      state={checkboxAdd}
                      setState={setCheckboxAdd}
                    ></CustomCheckBox2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-center gap-5">
            <div>
              <CustomButton1 label={isLoading ? "Loading..." : "Submit"} className="text-white bg-prp-color" type="submit" />
            </div>
            <div onClick={onCancel}>
              <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
            </div>
          </div>
        </form>
      </div>

      <ToastContainer autoClose={2000} />
    </>
  )
}
