// CORE
import * as React from "react";

// COMPONENTS
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewInvoiceGenerated({ selectedValue }) {
    const iN_IsInvoiceGenerateClose = selectedValue?.iN_IsInvoiceGenerateClose ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const iN_InvoiceGenerateClosedDate = formatDateTest(selectedValue?.iN_InvoiceGenerateClosedDate);
    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">

                    {/* Mapping through containersUnderLoadingList array to display each item */}
                    {selectedValue?.invoiceList?.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label={`Invoice Number ${index + 1}`} value={item.invoiceNumber} />
                            <CustomFileViewer fileURL={item.invoiceImageURL} fileName={item.invoiceOriginalFileName ?? ''} label={`Invoice Generated Upload`} />
                        </React.Fragment>
                    ))}
                </div>

                <div className="max-w-full grid grid-cols-2 mt-4 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Invoice Generate Closed Date" value={iN_InvoiceGenerateClosedDate} />
                    <CustomViewLabel label="Invoice Generate Closed" value={iN_IsInvoiceGenerateClose} />
                    <CustomViewLabel label="Invoice Generate Closed Date & Time" value={formatDateTest1(selectedValue?.cuL_ContainersUnderLoadingClosedDateTime)} />
                </div>
            </div>
        </>
    )
}
