import React, { useState } from 'react';

function CustomAlphatextPoNumber(props) {
    const { label, isRequired, readOnly, value, onChange, disabled } = props;
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const newValue = event.target.value.toUpperCase();
    
        // Allow alphanumeric characters, forward slash, backslash, and hyphen
        if (/^[a-zA-Z0-9/\\-]*$/.test(newValue)) {
            setErrorMessage('');
            onChange(newValue);
        } else {
            setErrorMessage('Only alphanumeric characters, /, \\, and - are allowed');
        }
    };    

    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <input
                type="text"
                required={isRequired}
                readOnly={readOnly}
                disabled={disabled}
                value={value}
                className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                id="id"
                placeholder={props.placeholder}
                onChange={handleChange}
            />
            {errorMessage && <span className="text-red-500 text-xs">{errorMessage}</span>}
        </div>
    );
}

CustomAlphatextPoNumber.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => { },
};

export default CustomAlphatextPoNumber;
