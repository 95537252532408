// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";

// ICONS
import { AiOutlineImport, AiOutlineDownload } from "react-icons/ai";

import AllLeads from "../components/section-components/ManageLeads/AllLeads";
import AcceptedLeads from "../components/section-components/ManageLeads/AcceptedLeads";
import RejectedLeads from "../components/section-components/ManageLeads/RejectedLeads";
import PendingLeads from "../components/section-components/ManageLeads/PendingLeads";

export default function Leads() {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage Leads"} title2={"Manage Leads"} />

                <div className="px-2 lg:px-4 minbox mt-5">
                    {/* 18 TABS */}
                    <TabContainer showArrow={true}>
                        <Tab label="All leads" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Pending" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Approved" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Rejected" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>
                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <AllLeads />
                    </div>
                </TabContent>
                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <PendingLeads />
                    </div>
                </TabContent>

                <TabContent index={2} activeTab={activeTab}>
                    <div>
                        <AcceptedLeads />
                    </div>
                </TabContent>

                <TabContent index={3} activeTab={activeTab}>
                    <div>
                        <RejectedLeads />
                    </div>
                </TabContent>


            </div>
        </div>
    );
}
