import React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomViewTextArea from '../../general-components/CustomViewTextArea';
import CustomButton2 from "../../general-components/CustomButton2.component";

const ManageBranchDetailsView = ({ selectedBranchDetail, onCancel }) => {
    const getValueOrDash = (value) => value ?? '-';

    const createdDate = selectedBranchDetail?.createdDate ? new Date(selectedBranchDetail.createdDate).toLocaleDateString('en-GB') : 'N/A';

    return (
        <div className="pb-10 bg-white rounded-lg">
            <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                <h1 className="text-xl font-weight-[300]">View Branch</h1>
                <div className="flex item-center text-sm"></div>
            </div>

            <div className="p-5 mx-auto overflow-scroll overflow-x-auto overflow-y-auto" style={{ maxHeight: "500px" }}>
                <table className="max-w-full popup-table w-[1050px] ">
                    <tbody>
                        <tr>
                            <td>
                                <CustomViewLabel label="Branch Name" value={getValueOrDash(selectedBranchDetail?.branchName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Company" value={getValueOrDash(selectedBranchDetail?.companyName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Mobile Number" value={getValueOrDash(selectedBranchDetail?.mobileNo)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Email" value={getValueOrDash(selectedBranchDetail?.emailId)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Department Head" value={getValueOrDash(selectedBranchDetail?.departmentHead)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Address" value={getValueOrDash(selectedBranchDetail?.addressLine1)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewTextArea label="Country" value={getValueOrDash(selectedBranchDetail?.countryName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="State" value={getValueOrDash(selectedBranchDetail?.stateName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="District / Province" value={getValueOrDash(selectedBranchDetail?.districtName)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="PinCode" value={getValueOrDash(selectedBranchDetail?.pincode)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Branch Total User Allow" value={getValueOrDash(selectedBranchDetail?.noofUserAdd)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Status" value={selectedBranchDetail?.isActive ? 'Active' : 'Inactive'} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Created Date" value={getValueOrDash(createdDate)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Created By" value={getValueOrDash(selectedBranchDetail?.creatorName)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="flex justify-center">
                <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                </div>
            </div>
        </div>
    );
};

export default ManageBranchDetailsView;
