import React from 'react';
import { ImEnlarge } from 'react-icons/im';
import { FaDownload } from 'react-icons/fa';

const CustomFileViewer = ({ fileURL, fileName, label }) => {
  const handleEnlarge = () => {
    // Open the file URL in a new tab
    window.open(fileURL, '_blank');
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  };

  const isPDF = fileURL && getFileExtension(fileName) === 'pdf';

  return (
    <div className="flex flex-col gap-1 relative">
      <label className="text-xs font-[400]">
        {label.toUpperCase()}
      </label>
      <div className="flex px-2 justify-between items-center">
        <p className="text-gray-400 text-xs">{fileName}</p>
        {fileURL && (
          <div className="flex items-center space-x-2">
            <button onClick={handleDownload} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
              <FaDownload h-8 w-8 />
            </button>
            <button onClick={handleEnlarge} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
              <ImEnlarge h-8 w-8 />
            </button>
          </div>
        )}
        <div>
          {!fileURL && (
            <div className="flex w-10 h-10 rounded-full border border-[#3D3D3D66]">
              <span className="text-[#3D3D3D66] flex items-center justify-center text-[10px] text-center">
                No Preview
              </span>
            </div>
          )}
          {fileURL && isPDF ? (
            <embed
              src={fileURL}
              type="application/pdf"
              className="w-10 h-10 border border-[#3D3D3D66]"
            />
          ) : (
            <img
              src={fileURL}
              alt="Preview"
              className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomFileViewer;
