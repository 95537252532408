// CORE
import * as React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import { BsCheck } from "react-icons/bs";

export default function BillingDetails({
    allTerData,
    setSelectedCountry,
    streetNameBilling,
    setStreetNameBilling,
    checkboxSameAddress,
    setCheckboxSameAddress,   

    next,
    back,
    onCancel
}) {

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
        } else {
            setSelectedCountry(null); // Reset to null when cleared
        }
    }

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <CustomTextArea
                                        label={"Street"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={streetNameBilling}
                                        onChange={setStreetNameBilling}
                                    ></CustomTextArea>
                                </td>
                                <td className="w-1/2">
                                    <CustomSelect1 label={"Country"} placeholder={"ENTER"}
                                        isRequired={false}
                                        options={allTerData.map(data => ({
                                            value: data.id,
                                            label: data.countryName
                                        }))}
                                        onChange={handleCountryChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* <hr className="mx-5 mt-5" /> */}
                <div className="flex items-center gap-4 mt-3 ps-5">
                    <label htmlFor="id" className="text-sm font-400">
                        Is Shipping and Billing address is same ?
                    </label>
                    <div
                        className={`${checkboxSameAddress ? "bg-prp-color" : "bg-white"
                            } border border-gray-300 flex justify-center items-center rounded h-[25px] w-[25px]`}
                        onClick={() => setCheckboxSameAddress(!checkboxSameAddress)}
                    >
                        <BsCheck className="text-2xl text-white" />
                    </div>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={next}>
                        <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}
