// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-modal';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "../ManageInvoiceGenerated/ViewInvoiceGenerated";
import CustomImgPdfSingle from "../../general-components/CustomImgPdfSingle.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";

import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { ImEnlarge } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PopUp from "../../general-components/PopUp.componenet";
import Remarks from "./Remarks";
import { Button } from "@mui/material";

export default function ManageBiDraftIssuedEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(9);
    const [orderDate, setOrderData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [conAttach, setConAttach] = useState([]);
    const [remarksAdd, setRemarksAdd] = useState("");
    const [newRemarks, setNewRemarks] = useState([]); // To store the remarks passed from the child
    const [checkboxClose, setCheckboxClose] = useState(false)

    const [conAttachNew, setConAttachNew] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [popupRemarkOpen, setPopupRemarkOpen] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    const handleConAttachChange = (file) => {
        if (file) {
            setConAttachNew([file]); // Only allow one file to be uploaded
        } else {
            setConAttachNew([]); // Set to an empty array if no file is selected
        }
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderData(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue && selectedValue.biDraftIssuedImagesList) {
            const conAttach = selectedValue.biDraftIssuedImagesList.map(img => ({
                id: img.id,
                name: img.originalFileName,
                url: img.imageURL,
                type: img.imageURL.endsWith('.pdf') ? 'pdf' : 'image',
                base64: "", // Set empty string for existing images
                isNew: false // Mark existing images
            }));
            setConAttach(conAttach);
        }
        if (selectedValue) {
            setCheckboxClose(selectedValue?.biD_BIDraftIssueClosedDate);
        }
    }, [selectedValue]);

    const getMaxUploadsForRecord = (existingImagesCount, newImagesCount) => {
        return Math.max(0, 10 - existingImagesCount - newImagesCount);
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.slice(0, getMaxUploadsForRecord(conAttach.length, 0));
        const updatedConAttach = [...conAttach];

        for (const file of newFiles) {
            const base64 = await fileToBase64(file);
            updatedConAttach.push({
                name: file.name,
                url: URL.createObjectURL(file),
                base64: base64.split(',')[1], // Store the Base64 string
                type: file.type.includes('pdf') ? 'pdf' : 'image',
                isNew: true // Mark as a new file
            });
        }

        setConAttach(updatedConAttach);
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = (file) => {
        setModalFile(file);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalFile(null);
    };


    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            console.log("Selected Value ID:", selectedValue.id);
            console.log("Existing Attachments (conAttach):", conAttach);
            console.log("New Attachments (conAttachNew):", conAttachNew);

            const biDraftIssuedImagesList = [];

            if (selectedValue.id) {
                // Add existing attachments
                biDraftIssuedImagesList.push(...conAttach.map(file => ({
                    id: file.id || 0,
                    imageName: file.name,
                    originalFileName: file.name,
                    image_Base64: file.isNew ? file.base64 : "" // Pass base64 for new files, empty string for existing
                })));
            }

            // Add new attachment
            if (conAttachNew.length > 0) {
                biDraftIssuedImagesList.push({
                    id: 0,
                    imageName: conAttachNew[0].name,
                    originalFileName: conAttachNew[0].name,
                    image_Base64: conAttachNew[0].base64 // Include Base64 for new files only
                });
            }
            console.log("biDraftIssuedImagesList before submission:", biDraftIssuedImagesList);

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftRemark: remarksAdd || newRemarks.map(remark => remark.remarks).join("\n"),  // Use the remarks passed from the child
                biD_BIDraftIssueClosedDate: orderDate || null,
                biD_IsBIDraftIssueClose: checkboxClose || false,
                biDraftIssuedImagesList
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecordImage = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteBIDraftIssuedImages?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedConAttach = conAttach.filter((_, i) => i !== index);
                setConAttach(updatedConAttach);

                toast.success('Record deleted successfully');
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit BL Draft Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex === 7}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex === 8}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"BL Draft Issued"}
                        isOpen={openAccordionIndex === 9}
                        onClick={() => handleAccordionClick(9)}
                    >
                        {/* <EditBiDraftIssued /> */}
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400] mb-2">
                                        BL Draft Issued Date
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderData(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                {conAttach.length > 0 ? (
                                    <div className="flex flex-col gap-2 relative">
                                        <label htmlFor="mainlable" className="text-xs font-[400] uppercase">
                                            BL Draft Issued Upload
                                        </label>
                                        <div>
                                            {conAttach.map((file, index) => (
                                                <div key={index} className="relative flex justify-between items-center space-x-2">
                                                    <div className="flex justify-around gap-2">
                                                        <button onClick={() => handleDownload(file)} className="bg-prp-color text-white p-2 rounded">
                                                            <FaDownload />
                                                        </button>
                                                        <button onClick={() => handleDeleteRecordImage(index, file.id)} className="px-2 bg-white border-red-500 border rounded">
                                                            <RiDeleteBin6Line className="text-red-500" />
                                                        </button>
                                                        <button onClick={() => handleEnlarge(file)} className="bg-prp-color text-white p-2 rounded">
                                                            <ImEnlarge />
                                                        </button>
                                                    </div>
                                                    <div>
                                                        {file.type === 'pdf' ? (
                                                            <embed
                                                                src={file.url}
                                                                type="application/pdf"
                                                                className="w-12 h-12 rounded border border-[#3D3D3D66]"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={file.url}
                                                                alt={file.name}
                                                                className="w-12 h-12 rounded-full border border-[#3D3D3D66]"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <CustomImgPdfSingle
                                            label="BL Draft Issued Upload"
                                            id="poissuede435345edit-edit-0"
                                            value={conAttachNew[0]} // Passing the first item in the array, since only one file should be present
                                            onChange={handleConAttachChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <td>
                                <div className="flex w-full ">
                                    <Button
                                        variant={"contained"}
                                        size={"small"}
                                        className={` flex mt-7 w-[50%] gap-2 justify-center items-center relative uppercase`}
                                        type={"button"}
                                        style={{ backgroundColor: "#643c94" }}
                                        onClick={() => setPopupRemarkOpen(true)}
                                    >
                                        <span className="whitespace-nowrap font-[400] font-Mitr text-white">
                                            Remarks
                                        </span>
                                    </Button>
                                </div>
                            </td>
                            <div className="">
                                <CustomCheckBox2
                                    label={" BL Draft Issued"}
                                    state={checkboxClose}
                                    setState={setCheckboxClose}
                                ></CustomCheckBox2>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Enlarge File"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="coman-modal-flex">
                    {modalFile && (
                        <div className="coman-modal-text">
                            <p>{modalFile.name}</p>
                        </div>
                    )}
                    <div className="coman-modal">
                        {modalFile && (
                            <>
                                <button onClick={() => handleDownload(modalFile)} className="modal-btn-comman modal-btn-dwl">
                                    <FaDownload />
                                </button>
                                <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl">
                                    <MdCancel />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {modalFile && (
                    modalFile.type === 'pdf' ? (
                        <embed
                            src={modalFile.url}
                            type="application/pdf"
                            className="w-full h-[600px]" // Adjust height as needed
                        />
                    ) : (
                        <img
                            src={modalFile.url || `data:image/png;base64,${modalFile.base64}`}
                            alt="Enlarged"
                            className="w-full h-auto"
                        />
                    )
                )}
            </Modal>

            {popupRemarkOpen && (
                <PopUp>
                    <Remarks
                        selectedValue={selectedValue}
                        remarksAdd={remarksAdd}
                        setRemarksAdd={setRemarksAdd}
                        onCancel={() => setPopupRemarkOpen(false)}
                        onOk={(remarks) => {
                            setPopupRemarkOpen(false);
                            setNewRemarks(remarks);  // Store the remarks received from the child
                        }}
                    />
                </PopUp>
            )}

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
