// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewFinalBiIssued({ selectedValue }) {
    const fbI_FinalBIDraftIssueClosedDate = selectedValue?.fbI_FinalBIDraftIssueClosedDate ? new Date(selectedValue.fbI_FinalBIDraftIssueClosedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-3 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="Final BL Issued Date" value={fbI_FinalBIDraftIssueClosedDate} />
                    <CustomFileViewer fileURL={selectedValue?.fbI_FinalBIImageURL} fileName={selectedValue?.fbI_FinalBIOriginalFileName} label="Final BL Issued Upload" />
                    <CustomViewLabel label="Final BL Issued Close" value={selectedValue?.fbI_IsFinalBIDraftIssueClose} />
                </div>
            </div>
        </>
    )
}

