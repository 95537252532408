import { useState, useEffect } from "react";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import Dash1 from "../assets/User.svg";
import Dash2 from "../assets/Mobile App User.svg";
import Dash3 from "../assets/Lead.svg";
import Dash4 from "../assets/Customer.svg";
import { useAuth } from "../context";


function Dashboard() {
    const { user } = useAuth()
    const [inputData, setInputData] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    const [leadCount, setLeadCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [mobileUserCount, setMobileUserCount] = useState(0);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const apiData = { searchText: "", customerId: 0, countryId: 0, poNumber: "", statusId: 0 };
    const dataLead = { searchText: "", leadStatusId: 0, customerId: 0, parentCustomerId: 0 };
    const dataEmployee = { searchText: "", isActive: true };
    const dataMobile = { searchText: "", customerId: 0 };
    const dataPOList = { pageNo: 0, pageSize: 0, searchText: "", isActive: null, customerId: 0, countryId: 0, statusId: 0, trakingStatusId: 0, trakingNumber: "", isPIConfirmation: 0, isPaymentOrLCReceived: 0 };

    const fetchData = async (url, data, setCount, errorMessage) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess) {
                const totalCounts = response.data.total || 0;
                setCount(totalCounts);
                if (url === '/ManageTracking/GetPurchaseOrderList') {
                    const sortedData = response.data.data || [];
                    setInputData(sortedData);
                    processChartData(sortedData);
                }
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMessage);
        }
    };

    // Adjust the dataEmployee based on the user's roleId
    if (user.roleId !== 1) {
        dataEmployee.companyId = user.companyId; // Assuming each user has a companyId
    }

    const fetchDataEmp = async (url, data, setCount, errorMessage) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess) {
                const visibleUsers = response.data.data || [];
                const visibleUserCount = visibleUsers.length;

                setCount(visibleUserCount);

                if (url === '/ManageTracking/GetPurchaseOrderList') {
                    const sortedData = visibleUsers;
                    setInputData(sortedData);
                    processChartData(sortedData);
                }
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMessage);
        }
    };

    const fetchEmployeeData = () => fetchDataEmp('/User/GetUserList', dataEmployee, setUserCount, "Error fetching Employee Profile Details");

    const fetchDataTrack = async (url, data, setCount, errorMessage) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess) {
                const totalCounts = response.data.total || 0;
                setCount(totalCounts);
                if (url === '/ManageTracking/GetPurchaseOrderList') {
                    const sortedData = response.data.data || [];
                    setInputData(sortedData);
                    processChartData(sortedData);
                }
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMessage);
        }
    };

    const fetchApiData = () => fetchDataTrack('/ManageTracking/GetPurchaseOrderList', dataPOList, setInputData, "Error fetching Purchase Order Tracking List Data");

    const fetchLeads = () => fetchData('/Customer/GetCustomerList', dataLead, (count) => {
        setLeadCount(count);
        setCustomerCount(count);
    }, "Error fetching Leads Data");
    const fetchMobileUser = () => fetchData('/Customer/GetLoginCredentialsList', dataMobile, setMobileUserCount, "Error fetching Mobile user Details");

    useEffect(() => {
        fetchApiData();
        fetchLeads();
        fetchEmployeeData();
        fetchMobileUser();
    }, []);

    const processChartData = (data) => {
        // Count occurrences of each poCurrentStatus
        const statusCount = data.reduce((acc, item) => {
            acc[item.poCurrentStatus] = (acc[item.poCurrentStatus] || 0) + 1;
            return acc;
        }, {});

        // Map counts to dataChart format
        const updatedDataChart = [
            { name: 'No action performed', value: statusCount['No action performed'] || 0 },
            { name: 'PO Received', value: statusCount['PO Received Closed'] || 0 },
            { name: 'PI Issued', value: statusCount['PI Issue Closed'] || 0 },
            { name: 'PI Confirmation', value: statusCount['PI Confirmed'] || 0 },
            { name: 'Payment Received / Lc Received', value: statusCount['Payment/LC Closed'] || 0 },
            { name: 'Order Accepted', value: statusCount['Order Accepted'] || 0 },
            { name: 'Order Under Process', value: statusCount['Order Under Processed'] || 0 },
            { name: 'Booking Issued', value: statusCount['Booking Issue Closed'] || 0 },
            { name: 'Containers Under Loading', value: statusCount['Containers UnderLoading Closed'] || 0 },
            { name: 'Invoice Generated', value: statusCount['Invoice Generated'] || 0 },
            { name: 'BL Draft Issued', value: statusCount['BL Draft Issued Closed'] || 0 },
            { name: 'Final BL Issued', value: statusCount['Final BL Issued Closed'] || 0 },
            { name: 'Final Amount To Pay', value: statusCount['Final Amount To Pay Closed'] || 0 },
            { name: 'Payment Received', value: statusCount['Payment Received'] || 0 },
            { name: 'Document Send Dhl / Submitted', value: statusCount['Document Send Through DHL / Submitted'] || 0 },
            { name: 'PO Closed', value: statusCount['PO Closed'] || 0 },
            { name: 'Order Completed Pending', value: statusCount['Order Completed-Pending'] || 0 },
        ];
        setDataChart(updatedDataChart);
    };

    const colors = [
        "#f4e1ff", "#e6c5ff", "#d8aaff", "#cb8eff",
        "#bf74ff", "#b35aff", "#a842ff", "#9e2bff",
        "#9416ff", "#8a00ff", "#7f00e6", "#7400cc",
        "#6800b3", "#5d0099", "#510080", "#643c94",
        "#450066",
    ];

    // Adding colors to each data entry
    const dataWithColors = dataChart.map((entry, index) => ({
        ...entry,
        fill: colors[index % colors.length]
    }));

    return (
        <>
            <div className="max-h-screen bg-white p-t-4 uppercase">
                <div className="p-5 mt-5 mb-5 rounded-sm bg-[#643c94] text-white font-xl">
                    <h1>My Dashboard</h1>
                </div>

                <div className="p-5 mx-auto rounded-md bg-[#E8ECF3] shadow-md">
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                        <div className="relative flex flex-col items-center gap-0">
                            <img src={Dash3} alt="Image 1" className="w-full h-auto rounded-md" />
                            <p className="absolute bottom-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl">
                                {leadCount}
                            </p>
                        </div>

                        <div className="relative flex flex-col items-center gap-0">
                            <img src={Dash4} alt="Image 1" className="w-full h-auto rounded-md" />
                            <p className="absolute bottom-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl">
                                {customerCount}
                            </p>
                        </div>

                        <div className="relative flex flex-col items-center gap-0">
                            <img src={Dash1} alt="Image 1" className="w-full h-auto rounded-md" />
                            <p className="absolute bottom-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl">
                                {userCount}
                            </p>
                        </div>

                        <div className="relative flex flex-col items-center gap-0">
                            <img src={Dash2} alt="Image 1" className="w-full h-auto rounded-md" />
                            <p className="absolute bottom-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl">
                                {mobileUserCount}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row space-x-6">
                    <div className="w-full m-2 rounded-md bg-white shadow-md border">
                        <h2 className="text-center py-2 text-2xl">Tracking Status Count</h2>
                        <ResponsiveContainer width="100%" height={500}>
                            <ComposedChart
                                data={dataWithColors}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 100, // Increase bottom margin for better label visibility
                                }}
                                barSize={20}
                            >
                                <CartesianGrid stroke="#f5f5f5" />

                                <XAxis
                                    dataKey="name"
                                    scale="point"
                                    padding={{ left: 20, right: 10 }}
                                    tick={{ fontSize: 12, angle: -45, textAnchor: 'end', }} // Rotate and align text
                                    interval={0} // Display every tick
                                />
                                <YAxis />
                                <Tooltip formatter={(value) => `Count: ${value}`} />
                                <Bar dataKey="value" background={{ fill: '#eee' }} />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={2000} />
        </>
    );
}

export default Dashboard;
