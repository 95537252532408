// CORE
import { useState } from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import Navbar from "./components/general-components/NavBar.component";
import NavTabs from "./components/general-components/NavTabs.components";


let [onlyIcon, setOnlyIcon] = [false, null];

function Admin({ children }) {
    [onlyIcon, setOnlyIcon] = useState(true);

    return (
        <section className="flex flex-col h-screen p-2 bg-white uppercase">
            {/* NAVIGATION BAR */}
            <section>
                <Navbar />
            </section>

            {/* ASIDE TABS + DYNAMIC SECTIONS  */}
            <section className="flex gap-4 grow h-[100vh]">
                {/* ASIDE */}
                <div
                    className={` bg-white${onlyIcon ? " w-[7.2vw]  min-w-[7.2vw]" : " w-[20vw]  "
                        } p-3 hidden lg:block rounded mt-2 overflow-x-hidden border border-[#3D3D3D66]
                overflow-auto  transition-all`}
                    style={{ borderRadius: "10px", overflow: "auto", height: "90vh" }}

                >
                    <NavTabs />
                </div>

                {/* DYNAMIC SECTIONS */}
                <div className="mt-1 rounded grow overflow-hidden " style={{ borderRadius: "10px", overflowY: "auto", height: "91vh", width: "22vw" }}>
                    {children}
                </div>
            </section>
        </section>
    );
}

export default Admin;
export { onlyIcon, setOnlyIcon };


