import React from 'react';
import CustomButton2 from '../../general-components/CustomButton2.component';

export default function PopupVisible({ onCancel, piIssuedLogList }) {

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg max-w-full w-[500px]">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[400]">PI Issued Status History</h1>
        </div>

        <div className="p-4 mx-4 my-4">
          {piIssuedLogList && piIssuedLogList.length > 0 ? (
            piIssuedLogList.map((log, index) => (
              <div key={index} className="flex flex-col gap-3 text-sm border border-prp p-4 mb-4 rounded-lg ">
                <div className=''>
                  <p>Date: <span className='txt-prp-color'>{formatDateTest(log.createdDate)}</span></p>
                </div>
                <div>
                  <p>Status: <span className='txt-prp-color'>{log.statusName}</span></p>
                </div>
                <div>
                  <p>Remarks: <span className='txt-prp-color'>{log.remarks}</span></p>
                </div>
                {/* <div>
                  <p>Creator: {log.creatorName}</p>
                </div> */}
              </div>
            ))
          ) : (
            <p>No logs available.</p>
          )}
        </div>

        <div className="flex justify-center gap-5">
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>
    </>
  );
}
