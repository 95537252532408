// CORE
import * as React from "react";
import { useState, useEffect } from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";

// ICONS
import AcceptedPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/AcceptedPIConfirmedbyCustomer";
import RejectedPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/RejectedPIConfirmedbyCustomer";
import AllPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/AllPIConfirmedbyCustomer";

import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";
import PendingPIConfirmedbyCustomer from "../components/section-components/ManagePIConfirmedbyCustomer/PendingPIConfirmedbyCustomer";

export default function ManagePIConfirmedbyCustomer() {
    const [activeTab, setActiveTab] = useState(0);
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE TRACKING > MANAGE PI CONFIRMATION"))
        }
    }, [user])

    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage PI Confirmation"} title2={"Manage Tracking"} />

                <div className="px-2 lg:px-4 minbox mt-5">
                    <TabContainer showArrow={true}>
                        <Tab label="Pending PI" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Approved PI" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Rejected PI" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="All PI" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <PendingPIConfirmedbyCustomer Permission={Permission} />
                    </div>
                </TabContent>

                <TabContent index={1} activeTab={activeTab} >
                    <div>
                        <AcceptedPIConfirmedbyCustomer Permission={Permission} />
                    </div>
                </TabContent>

                <TabContent index={2} activeTab={activeTab}>
                    <div>
                        <RejectedPIConfirmedbyCustomer Permission={Permission} />
                    </div>
                </TabContent>

                <TabContent index={3} activeTab={activeTab}>
                    <div>
                        <AllPIConfirmedbyCustomer Permission={Permission} />
                    </div>
                </TabContent>
            </div>
        </div>
    );
}

