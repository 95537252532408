// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import Accordion from "../../../general-components/Accodion";
import ManagePOView from "../../ManagePO/ManagePOView";
import ViewPOIssued from "../../ManagePOIssued/ViewPOIssued";
import CustomButton2 from "../../../general-components/CustomButton2.component";
import ViewPiConfirmPending from "./ViewPiConfirmPending";

export default function PendingPiView({ onCancel, selectedValue }) {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(2);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">View PI Confirmation</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">

          <Accordion
            title={"PO received"}
            isOpen={openAccordionIndex === 0}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion
            title={"PI Issued"}
            isOpen={openAccordionIndex === 1}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion
            title={"PI Confirmation"}
            isOpen={openAccordionIndex === 2}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPiConfirmPending
              selectedValue={selectedValue}
            />
          </Accordion>
        </div>
        <div className="flex justify-center gap-5 pt-3">
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>
    </>
  );
}
