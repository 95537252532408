import * as React from "react";
import { useState, useEffect } from "react";
import { Tab, TabContainer, TabContent } from "../../general-components/Tab.component";
import axios from 'axios';

import EditClientDetails from "../../section-components/ManageParentCustomersEdit/EditClientDetails";
import EditContactDetails from "../../section-components/ManageCustomersEdit/EditContactDetails";
import EditBillingDetails from "../../section-components/ManageCustomersEdit/EditBillingDetails";
import EditShippingDetails from "../../section-components/ManageCustomersEdit/EditShippingDetails";
import LoginCredentials from "../../section-components/ManageCustomersEdit/LoginCredentials";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ManageParentCustomersEdit({
  parentCustomerId,
  currentCustomerId,
  cName,
  setCName,
  mobile,
  setMobile,
  parentCId,
  setParentCId,
  landLineNumber,
  setLandLineNumber,
  customerId,
  setCustomerId,
  email,
  setEmail,
  countryId,
  setCountryId,
  contactName,
  setContactName,
  leadStatusId,
  setLeadStatusId,
  active,
  parentCName,
  setParentCName,
  customerName,
  setCustomerName,
  leadStatusName,
  setLeadStatusName,
  mainCountryData,
  setMainCountryData,
  mainCountryId,
  setMainCountryId,
  mainCountryName,
  setMainCountryName,

  contactPersonCon,
  emailIdCon,

  customerIdBilling,
  setCustomerIdBilling,
  streetNameBilling,
  setStreetNameBilling,
  countryIdBilling,
  setCountryIdBilling,
  stateIdBilling,
  setStateIdBilling,
  postalZipCoddeBilling,
  setPostalZipCoddeBilling,
  isActiveBilling,
  setisActiveBilling,
  checkboxSameAddress,
  setCheckboxSameAddress,
  customerNameBilling,
  setCustomerNameBilling,
  countryNameBilling,
  setCountryNameBilling,
  stateNameBilling,
  setStateNameBilling,

  customerIdShipping,
  setCustomerIdShipping,
  streetNameShipping,
  setStreetNameShipping,
  countryIdShipping,
  setCountryIdShipping,
  stateIdShipping,
  setStateIdShipping,
  postalZipCoddeShipping,
  setPostalZipCoddeShipping,
  isActiveShipping,
  customerNameShipping,
  setCustomerNameShipping,
  countryNameShipping,
  setCountryNameShipping,
  stateNameShipping,
  setStateNameShipping,

  loginId,
  setLoginId,
  loginName,
  setLoginName,
  userName,
  setUserName,
  password,
  setPassword,
  loginActive,
  setLoginActive,

  selectedCustomer,
  onCancel,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const handleSaveAndContinue = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const handleBack = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('DIYAN_IE_authToken');
  console.log("Current_customer_id-->", currentCustomerId);
  const dataActive = { searchText: "", customerId: selectedCustomer?.id };

  console.log("Current_customer_id-->", currentCustomerId);

  const fetchData = async (url, setData, errorMsg) => {
    setIsLoading(true);
    try {
      const response = await axios.post(url, dataActive, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, setMainCountryData, "Error fetching Country Data");
  }, []);

  const handleCustomerDataSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        id: selectedCustomer.id,
        moduleType: "Cust",
        customerName: cName,
        mobileNo: mobile,
        parentCustomerId: parentCustomerId,
        landlineNumber: landLineNumber,
        customerTypeId: customerId,
        emailId: emailIdCon,
        countryId: mainCountryId || 0,
        contactName: contactPersonCon,
        leadStatusId: 2,
        isActive: true,
        contactDetailsList: [],
        billingDetailsList: [],
        shippingDetailsList: []
      };

      // console.log("abc--->", requestBody);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        // console.log("Record Inserted successfully:", responseData.message);
        setTimeout(() => {
          window.location.reload(); // Reload the page or fetch updated list
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing Customer operation:", error);
      toast.error("Error performing Customer operation");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[400]">Edit Customers</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="flex flex-col h-full uppercase">
          <div className="p-6 mt-2 bg-white rounded grow">
            <div className="px-2  lg:px-4 minbox ">
              <TabContainer>
                <Tab label="Client Details" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Contact Details" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Billing Details" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Shipping Address" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
                <Tab label="Login Credentials" index={4} activeTab={activeTab} setActiveTab={setActiveTab} />
              </TabContainer>
            </div>

            <TabContent index={0} activeTab={activeTab}>
              <EditClientDetails
                currentCustomerId={currentCustomerId}
                cName={cName}
                setCName={setCName}
                mobile={mobile}
                setMobile={setMobile}
                parentCId={parentCId}
                setParentCId={setParentCId}
                landLineNumber={landLineNumber}
                setLandLineNumber={setLandLineNumber}
                customerId={customerId}
                setCustomerId={setCustomerId}
                email={email}
                setEmail={setEmail}
                countryId={countryId}
                setCountryId={setCountryId}
                contactName={contactName}
                setContactName={setContactName}
                leadStatusId={leadStatusId}
                setLeadStatusId={setLeadStatusId}
                active={active}
                parentCName={parentCName}
                setParentCName={setParentCName}
                customerName={customerName}
                setCustomerName={setCustomerName}
                leadStatusName={leadStatusName}
                setLeadStatusName={setLeadStatusName}
                mainCountryData={mainCountryData}
                setMainCountryData={setMainCountryData}
                mainCountryId={mainCountryId}
                setMainCountryId={setMainCountryId}
                mainCountryName={mainCountryName}
                setMainCountryName={setMainCountryName}

                next={handleSaveAndContinue}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={1} activeTab={activeTab}>
              <EditContactDetails
                currentCustomerId={currentCustomerId}
                cName={cName}
                next={handleSaveAndContinue}
                back={handleBack}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={2} activeTab={activeTab}>
              <EditBillingDetails
                currentCustomerId={currentCustomerId}
                cName={cName}
                next={handleSaveAndContinue}
                back={handleBack}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={3} activeTab={activeTab}>
              <EditShippingDetails
                currentCustomerId={currentCustomerId}
                cName={cName}
                next={handleSaveAndContinue}
                back={handleBack}
                onCancel={onCancel}
              />
            </TabContent>

            <TabContent index={4} activeTab={activeTab}>
              <LoginCredentials
                currentCustomerId={currentCustomerId}
                cName={cName}
                back={handleBack}
                onCancel={onCancel}
                handleCustomerDataSubmit={handleCustomerDataSubmit}
              />
            </TabContent>
          </div >
        </div >
      </div>

      <ToastContainer autoClose={2000} />
    </>
  )
}

