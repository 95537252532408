import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

import LoginCredentialsAddPopup from "../../forms-components/EditLoginCredentials/LoginCredentialsAddPopup";
import LoginCredentialsEditPopup from "../../forms-components/EditLoginCredentials/LoginCredentialsEditPopup";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from "@mui/material";

export default function LoginCredentials({
    currentCustomerId,
    cName,

    handleCustomerDataSubmit,
    back,
    onCancel
}) {
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupOpenEdit, setPopupOpenEdit] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [dataList, setDataList] = useState([]);
    const [selectedDataList, setSelectedDataList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loginIdEdit, setLoginIdEdit] = useState("");
    const [contactNameEdit, setContactNameEdit] = useState("");
    const [usernameEdit, setUsernameEdit] = useState("");
    const [passwordEdit, setPasswordEdit] = useState("");
    const [statusEdit, setStatusEdit] = useState(true);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "", customerId: currentCustomerId };

    const fecthDataList = async () => {
        if (!currentCustomerId) return; // Prevent API call if ID is null or undefined
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetLoginCredentialsList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                setDataList(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Billing list");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (currentCustomerId !== null && currentCustomerId !== undefined) {
            fecthDataList();
        }
    }, [currentCustomerId]); // This will ensure fetchDataList only runs when currentCustomerId changes and is valid

    const paginatedDataList = dataList.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fecthDataList();
    }, [rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(dataList.length / rowsPerPage));
    }, [dataList, rowsPerPage]);

    const handleEditButtonClick = (genId) => {
        fetchDataListById(genId);
        setPopupOpenEdit(true);
    };

    const fetchDataListById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Customer/GetLoginCredentialsById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                setSelectedDataList(response.data.data);
                setLoginIdEdit(response.data.data.id);
                setContactNameEdit(response.data.data.contactName);
                setUsernameEdit(response.data.data.username);
                setPasswordEdit(response.data.data.passwords);
                setStatusEdit(response.data.data.isActive);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Login credentials details");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <div className="flex items-center gap-5 mt-5 ps-5">
                        <label htmlFor="id" className="text-sm font-[400]">
                            Customer Name :
                        </label>
                        <input
                            type="text"
                            readOnly
                            className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs text-gray-500 uppercase max-w-[300px]"
                            id="id"
                            placeholder="Lorem Ipsum"
                            value={cName}
                        />
                    </div>
                </div>

                <div className="px-2 lg:px-4">
                    {/* 4 BUTTONS */}
                    <div className="  flex md:justify-between items-center flex-row flex-wrap justify-start gap-1 md:flex-nowrap">
                        <button className="smlbtn"> Excel</button>
                    </div>

                    <div className="flex flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap">
                        <CustomButton1
                            label={"Add "}
                            icon={<IoMdAdd />}
                            className="bg-prp-color text-white shrink grow md:grow-0 max-w-[50%]"
                            onClick={() => setPopupOpen(true)}
                        />

                        <div className="flex items-center border justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                            <input
                                type="text"
                                className="w-[200px] grow  capitalize bg-transparent placeholder:text-sm"
                                placeholder="SEARCH"
                            />
                            <AiOutlineSearch className="min-w-[20px]" />
                        </div>
                    </div>

                    {/* TABLE */}
                    <div className="mt-2 max-w-[80vw] hide-scrollbar overflow-auto table-container">
                        <table className="w-full custom-table" border={1}>
                            <thead>
                                <tr className="table-heading">
                                    <td className="">S.No</td>
                                    <td>Status</td>
                                    <td>Contact Name</td>
                                    <td>Mobile number</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="6" className="text-center">Loading...</td>
                                    </tr>
                                ) : (
                                    paginatedDataList.map((inputData, index) => (
                                        <tr key={inputData.id}>
                                            <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                            <td>
                                                <div>
                                                    <h2 style={{ color: inputData.isActive ? 'green' : 'red' }}>{inputData.isActive ? 'Active' : 'Inactive'}</h2>
                                                </div>
                                            </td>
                                            <td>{inputData.contactName}</td>
                                            <td>{inputData.username}</td>
                                            <td>
                                                <div className="gap-2">
                                                    <Tooltip title="EDIT" arrow placement="left">
                                                        <span>
                                                            <CustomButton1
                                                                className="bg-sixt text-white grow max-w-[50px]"
                                                                icon={<BiEdit />}
                                                                onClick={() => handleEditButtonClick(inputData.id)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="text-sm text-black font-normal mt-5">
                        Showing {(currentPage - 1) * rowsPerPage + 1} to {Math.min(currentPage * rowsPerPage, dataList.length)} of {dataList.length} entries
                    </div>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={handleCustomerDataSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>

                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} className="text-white bg-prp-color" />
                    </div>
                </div>
            </div>

            {/* POPUP 1 : ADD */}
            {popupOpen && (
                <PopUp>
                    <LoginCredentialsAddPopup
                        currentCustomerId={currentCustomerId}
                        onCancel={() => {
                            setPopupOpen(false);
                            fecthDataList();
                        }}
                    />

                </PopUp>
            )}

            {/* POPUP 1 : EDIT */}
            {popupOpenEdit && (
                <PopUp>
                    <LoginCredentialsEditPopup
                        currentCustomerId={currentCustomerId}
                        loginIdEdit={loginIdEdit}
                        contactNameEdit={contactNameEdit}
                        setContactNameEdit={setContactNameEdit}
                        usernameEdit={usernameEdit}
                        setUsernameEdit={setUsernameEdit}
                        passwordEdit={passwordEdit}
                        setPasswordEdit={setPasswordEdit}
                        statusEdit={statusEdit}
                        setStatusEdit={setStatusEdit}

                        onCancel={() => {
                            setPopupOpenEdit(false);
                            fecthDataList();
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
