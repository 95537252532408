// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";

import EditFinalBiIssued from "./EditFinalBiIssued";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "../ManageInvoiceGenerated/ViewInvoiceGenerated";
import ViewBiDraftIssued from "../ManageBiDraftIssued/ViewBiDraftIssued";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import { useEffect } from 'react';
import PopUp from "../../general-components/PopUp.componenet";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";
import CustomOnlyPdfFileUpload from "../../general-components/CustomOnlyPdfFileUpload.component";

export default function ManageFinalBiIssuedEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(10);
    const [updatePopup, setUpdatePopup] = useState(false);

    const [orderDate, setOrderData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkboxClose, setCheckboxClose] = useState(false)
    const [docUpload, setDocUpload] = useState("");

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderData(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setCheckboxClose(selectedValue?.fbI_IsFinalBIDraftIssueClose);
        }
    }, [selectedValue]);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftComment: "",
                fbI_FinalBIDraftIssueClosedDate: orderDate || null,
                fbI_FinalBIOriginalFileName: docUpload.name || "",
                fbI_FinalBIImage_Base64: docUpload.base64 || "",
                fbI_IsFinalBIDraftIssueClose: checkboxClose || false
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Final BL Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex === 7}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex === 8}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"BL Draft Issued"}
                        isOpen={openAccordionIndex === 9}
                        onClick={() => handleAccordionClick(9)}
                    >
                        <ViewBiDraftIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Final BL Issued"}
                        isOpen={openAccordionIndex === 10}
                        onClick={() => handleAccordionClick(10)}
                    >
                        {/* <EditFinalBiIssued /> */}
                        <div className=" max-w-full grid grid-cols-3 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Final BL Issued Date
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderData(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <CustomOnlyPdfFileUpload
                                    id="Booking343IssueUpload123dfgfd"
                                    label="Final BL Issued Upload"
                                    isRequired={false}
                                    value={docUpload}
                                    onChange={setDocUpload}
                                // initialFileName={fileOriginalName}
                                // initialFileUrl={fileUrl}
                                />
                            </div>
                            <div className="">
                                <CustomCheckBox2
                                    label={"Final BL Issued"}
                                    state={checkboxClose}
                                    setState={setCheckboxClose}
                                ></CustomCheckBox2>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
