// CORE
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";
import ManageTrackingStatusCustomerClosed from "../components/section-components/ManageTrackingStatusCustomer/ManageTrackingStatusCustomerClosed";
import ManageTrackingStatusCustomerPending from "../components/section-components/ManageTrackingStatusCustomer/ManageTrackingStatusCustomerPending";
import CustomButton2 from "../components/general-components/CustomButton2.component";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

export default function ManageTrackingStatusCustomer() {
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cId = searchParams.get("customerId");

    const navigate = useNavigate();
    useEffect(() => {
        const savedTab = localStorage.getItem('diyanCustomerTracking');
        if (savedTab) {
            setActiveTab(parseInt(savedTab, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('diyanCustomerTracking', activeTab);
    }, [activeTab]);

    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage Customer Tracking Status"} title2={"Manage Customers"} />

                <div className="  flex md:justify-between items-center flex-row flex-wrap justify-start gap-1 mt-5 md:flex-nowrap pb-5">
                    {/* <button className="smlbtn"> Excel</button> */}

                    <div className="flex flex-wrap items-center justify-between gap-3  ml-auto">
                        <CustomButton2
                            label={"Back"}
                            icon={<IoArrowBack />}
                            variant="outlined"
                            className="txt-prp-color"
                            onClick={() => {
                                // localStorage.setItem('diyanActiveTrackingStatusTab', tabId); // Ensure the Role tab is active
                                navigate("/admin/app/customers");
                            }}

                        />
                    </div>
                </div>

                <div className="px-2 lg:px-4 minbox">
                    {/* 18 TABS */}
                    <TabContainer showArrow={true}>
                        <Tab label="Pending" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Closed" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <ManageTrackingStatusCustomerPending cId={cId} />
                    </div>
                </TabContent>

                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <ManageTrackingStatusCustomerClosed cId={cId} />
                    </div>
                </TabContent>
            </div>
        </div>
    );
}
