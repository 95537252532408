import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditShippingDetailsAddPopup({ onCancel, currentCustomerId }) {
    const [isLoading, setIsLoading] = useState(false);    
    const [streetName, setStreetName] = useState("");
    const [allTerData, setAllTerData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, setAllTerData, "Error fetching Country Data");
    }, []);

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
        } else {
            setSelectedCountry(null); // Reset to null when cleared
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                customerId: currentCustomerId,
                streetName: streetName || "",
                countryId: selectedCountry || 0,
                isActive: true
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveShippingDetails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                } else {
                    toast.success("Record Inserted successfully");
                }
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Edit Shipping operation:", error);
            toast.error("Error performing Edit Shipping operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Add Shipping Details</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[700px]">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <CustomTextArea
                                        isRequired={false}
                                        label={"Street"}
                                        placeholder={"ENTER"}
                                        value={streetName}
                                        onChange={setStreetName}
                                    ></CustomTextArea>
                                </td>
                                <td className="w-1/2">
                                    <CustomSelect1 label={"Country"} placeholder={"ENTER"}
                                        isRequired={false}
                                        options={allTerData.map(data => ({
                                            value: data.id,
                                            label: data.countryName
                                        }))}
                                        onChange={handleCountryChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Submit"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
