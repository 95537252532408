import * as React from "react";
import { useState } from "react";
import { useRef } from 'react';
import axios from 'axios';
import { Button } from "@mui/material";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";

// ICONS
import { AiOutlineImport, AiOutlineDownload } from "react-icons/ai";

import Region from "../components/section-components/ManageAttributes/Region";
import State from "../components/section-components/ManageAttributes/State";
import District from "../components/section-components/ManageAttributes/District";

// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function ManageAttributes() {
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const tablabel = {
        0: "Country",
        1: "State",
        2: "District / Province",
    };

    const regionRef = useRef(null);
    const stateRef = useRef(null);
    const districtRef = useRef(null);

    // Define the excelDownload function
    const excelDownload = async () => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        setIsLoading(true);

        // Determine the correct API endpoint based on the active tab
        let apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/ExportCountryData`;

        if (activeTab === 1) {
            apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/ExportStateData`;
        } else if (activeTab === 2) {
            apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/ExportDistrictData`;
        }

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess) {
                const base64Data = response.data.data;
                const binaryString = atob(base64Data);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${tablabel[activeTab].toLowerCase()}.xlsx`); // Set the file name dynamically
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.success(response.data.message);
            } else {
                console.error(`Failed to export ${tablabel[activeTab].toLowerCase()} data.`);
            }
        } catch (error) {
            console.error(`Error fetching ${tablabel[activeTab].toLowerCase()} data details`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        setIsLoading(true);

        // Determine the correct API endpoint based on the active tab
        let apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/DownloadCountryTemplate`;

        if (activeTab === 1) {
            apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/DownloadStateTemplate`;
        } else if (activeTab === 2) {
            apiUrl = `${process.env.REACT_APP_BASE_URL}/Territory/DownloadDistrictTemplate`;
        }

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const result = response.data;

            if (result.isSuccess && result.data) {
                const byteCharacters = atob(result.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${tablabel[activeTab].toLowerCase()}.xlsx`; // Dynamic file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.success(`${tablabel[activeTab]} template downloaded successfully.`);
            } else {
                console.error(`Failed to download ${tablabel[activeTab]} template.`);
            }
        } catch (error) {
            console.error(`Error while downloading ${tablabel[activeTab]} template: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const getApiEndpoint = () => {
        switch (activeTab) {
            case 0: return `${process.env.REACT_APP_BASE_URL}/Territory/ImportCountry`;
            case 1: return `${process.env.REACT_APP_BASE_URL}/Territory/ImportState`;
            case 2: return `${process.env.REACT_APP_BASE_URL}/Territory/ImportDistrict`;
            default: return `${process.env.REACT_APP_BASE_URL}/Territory/ImportCountry`;
        }
    };

    const handleFileUpload = async (file) => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        const formData = new FormData();
        formData.append("FileUpload", file);

        setIsLoading(true);

        try {
            const response = await axios.post(
                getApiEndpoint(),
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.isSuccess) {
                console.success("File uploaded successfully.");
                // Optionally fetch updated data or handle post-upload logic here
                // Call a function to refresh the data in the child component
                refreshChildData();
            } else {
                console.error("File upload failed.");
            }
        } catch (error) {
            console.error("An error occurred during the file upload.");
            console.error("File upload error:", error);
        } finally {
            setIsLoading(false);
            setSelectedFile(null);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            await handleFileUpload(file);
        }
    };

    const handleClick = () => {
        document.getElementById("upload-file-input").click();
    };

    const refreshChildData = () => {
        switch (activeTab) {
            case 0:
                if (regionRef.current) regionRef.current.fetchApiData(""); // Fetch data for Region
                break;
            case 1:
                if (stateRef.current) stateRef.current.fetchApiData(""); // Fetch data for State
                break;
            case 2:
                if (districtRef.current) districtRef.current.fetchApiData(""); // Fetch data for District
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="flex flex-col h-full uppercase">
                <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Attributes"} title2={"ADMINISTRATOR"} />

                    <div className="flex md:justify-between items-center flex-row flex-wrap justify-start gap-1 mt-5 md:flex-nowrap pb-5">
                        <Button
                            variant="contained"
                            size="small"
                            className="flex gap-2 justify-center items-center relative uppercase"
                            type="button"
                            style={{ backgroundColor: "#643c94" }}
                            onClick={excelDownload}
                        >
                            Excel
                        </Button>

                        <div className="flex flex-wrap items-center justify-between gap-3 ml-auto">
                            <div className="">
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="upload-file-input"
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="flex gap-2 justify-center items-center relative uppercase"
                                    type="button"
                                    style={{ backgroundColor: "#643c94" }}
                                    onClick={handleClick}
                                >
                                    <span className="text-sm"><AiOutlineImport /></span>
                                    <span className="whitespace-nowrap font-[400] font-Mitr text-white">
                                        {"Import " + tablabel[activeTab]}
                                    </span>
                                </Button>
                            </div>

                            <div onClick={handleDownload}>
                                <CustomButton1
                                    label={"Sample Download "}
                                    icon={<AiOutlineDownload />}
                                    className=" bg-prp-color text-white grow "
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-2 lg:px-4 minbox">
                        <TabContainer showArrow={true}>
                            <Tab label="Country" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="State" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="District / Province" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                        </TabContainer>
                    </div>

                    <TabContent index={0} activeTab={activeTab}>
                        <div>
                            <Region ref={regionRef} />
                        </div>
                    </TabContent>

                    <TabContent index={1} activeTab={activeTab}>
                        <div>
                            <State ref={stateRef} />
                        </div>
                    </TabContent>

                    <TabContent index={2} activeTab={activeTab}>
                        <div>
                            <District ref={districtRef} />
                        </div>
                    </TabContent>
                </div>
            </div>

            {/* <ToastContainer autoClose={2000} /> */}
        </>
    );
}

export default ManageAttributes;
