// CORE
import * as React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

export default function ManageTerritoryView({ trData, onCancel }) {
    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[300]">View Territories</h1>
                    <div className="flex items-center text-sm"></div>
                </div>
                <div className="p-4 mx-auto">
                    <div className="w-[700px] grid grid-cols-2 gap-4 md:grid-cols-3">
                        <CustomViewLabel label="Country" value={trData.countryName} />
                        <CustomViewLabel label="State" value={trData.stateName} />
                        <CustomViewLabel label="District / Province" value={trData.districtName} />
                        <CustomViewLabel label="Status" value={trData?.isActive ? 'Active' : 'Inactive'} />
                        <CustomViewLabel label="Created Date" value={new Date(trData?.createdDate).toLocaleDateString('en-GB')} />
                        <CustomViewLabel label="Created By " value={trData.creatorName} />
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}
