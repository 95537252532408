// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component"
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomNumber from '../../general-components/CustomAlphatext.component';
import CustomCheckBox3 from '../../general-components/CustomCheckBox3.component';
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../../context";
import CustomSelect2 from "../../general-components/CustomSelect2.component";

export default function ManageBranchDetailsEdit({ selectedBranchDetail, onCancel }) {
    const { user } = useAuth()

    const [isLoading, setIsLoading] = useState(false);
    const [checkboxBranchEdit, setCheckboxBranchEdit] = useState(true)
    const [bName, setBname] = useState("");
    const [moNo, setMoNo] = useState("");
    const [email, setEmail] = useState("");
    const [depHead, setDepHead] = useState("");
    const [adrs, setAdrs] = useState("");
    const [pincode, setPincode] = useState("");
    const [bUser, setBUser] = useState("");

    const [compData, setCompData] = useState([]);
    const [selectedComp, setSelectedComp] = useState("");
    const [selectedCompName, setSelectedCompName] = useState("");

    const [allTerData, setAllTerData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [selectedCountryName, setSelectedCountryName] = useState(null);
    const [selectedStateName, setSelectedStateName] = useState(null);
    const [selectedDistrictName, setSelectedDistrictName] = useState(null);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true, companyId: 0 };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`, setCompData, "Error fetching Company Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");
    }, []);

    const handleCompChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedComp(selectedOption.value);
            setSelectedCompName(selectedOption.label); // Update the department name label
        } else {
            setSelectedComp(null); // Reset to null when cleared
            setSelectedCompName(''); // Clear the department name label
        }
    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
            setSelectedCountryName(selectedOption.label);
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        } else {
            setSelectedCountry(null);
            setSelectedCountryName("");
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedState(selectedOption.value);
            setSelectedStateName(selectedOption.label);
            setSelectedDistrict(null); // Reset district when state changes
        } else {
            setSelectedState(null);
            setSelectedStateName("");
            setSelectedDistrict(null); // Reset district when state changes
        }
    }

    const handleDistrictChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDistrict(selectedOption.value);
            setSelectedDistrictName(selectedOption.label);
        } else {
            setSelectedDistrict(null);
            setSelectedDistrictName("");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                id: selectedBranchDetail.id,
                branchName: bName,
                companyId: selectedComp,
                emailId: email,
                mobileNo: moNo,
                departmentHead: depHead,
                addressLine1: adrs,
                countryId: selectedCountry,
                stateId: selectedState,
                districtId: selectedDistrict,
                pincode: pincode || "",
                noofUserAdd: bUser,
                isActive: checkboxBranchEdit
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Branch/SaveBranch`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");

                setTimeout(() => {
                    onCancel();
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Manage Branch operation:", error);
            toast.error("Error performing Manage Branch operation");
        } finally {
            setIsLoading(false);
        }
    };

    const getUniqueOptions = (data, key, valueKey, labelKey) => {
        const unique = new Set();
        return data
            .filter(item => {
                if (!unique.has(item[key])) {
                    unique.add(item[key]);
                    return true;
                }
                return false;
            })
            .map(item => ({
                value: item[valueKey],
                label: item[labelKey]
            }));
    };

    const getStatesForCountry = () => {
        return getUniqueOptions(allTerData.filter(data => data.countryId === selectedCountry), 'stateId', 'stateId', 'stateName');
    };

    const getDistrictsForState = () => {
        return getUniqueOptions(allTerData.filter(data => data.stateId === selectedState), 'districtId', 'districtId', 'districtName');
    };

    useEffect(() => {
        if (selectedBranchDetail) {
            setBname(selectedBranchDetail.branchName);
            setMoNo(selectedBranchDetail.mobileNo);
            setEmail(selectedBranchDetail.emailId);
            setDepHead(selectedBranchDetail.departmentHead);
            setAdrs(selectedBranchDetail.addressLine1);
            setPincode(selectedBranchDetail.pincode);
            setBUser(selectedBranchDetail.noofUserAdd);
            setSelectedComp(selectedBranchDetail.companyId);
            setSelectedCompName(selectedBranchDetail.companyName);
            setCheckboxBranchEdit(selectedBranchDetail.isActive);

            setSelectedCountry(selectedBranchDetail.countryId);
            setSelectedState(selectedBranchDetail.stateId);
            setSelectedDistrict(selectedBranchDetail.districtId);
            setSelectedCountryName(selectedBranchDetail.countryName);
            setSelectedStateName(selectedBranchDetail.stateName);
            setSelectedDistrictName(selectedBranchDetail.districtName);
        }
    }, [selectedBranchDetail])

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Branch Details</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-2 mx-auto">
                        <table className="max-w-full popup-table w-[1050px]">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField2
                                            label={"Branch Name"}
                                            placeholder={"ENTER"}
                                            value={bName}
                                            onChange={setBname}
                                        ></CustomTextField2>
                                    </td>
                                    <td>
                                        <div className="flex flex-col gap-1">
                                            <label htmlFor="id" className="text-xs font-400">
                                                Company
                                            </label>
                                            {user?.roleId === 1 ? (
                                                <Select
                                                    options={compData.map(data => ({
                                                        value: data.id,
                                                        label: data.companyName
                                                    }))}
                                                    className="text-black text-xs select-bar"
                                                    id="comp-id"
                                                    isSearchable
                                                    isClearable
                                                    value={{ value: selectedComp, label: selectedCompName }} // Set initial value here
                                                    onChange={handleCompChange}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#e5e7eb',
                                                        },
                                                    })}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    value={selectedCompName}
                                                    readOnly
                                                    className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase text-gray-500"
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <CustomNumber label="Mobile Number" placeholder="Enter"
                                            value={moNo}
                                            onChange={setMoNo}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomEmail label="Email" placeholder="Enter"
                                            value={email}
                                            onChange={setEmail}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField2
                                            label={" Branch Head  "}
                                            placeholder={"ENTER"}
                                            value={depHead}
                                            onChange={setDepHead}
                                        ></CustomTextField2>
                                    </td>
                                    <td>
                                        <CustomTextArea
                                            label={" Address "}
                                            placeholder={"ENTER"}
                                            value={adrs}
                                            onChange={setAdrs}
                                        ></CustomTextArea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomSelect2
                                            label={"Country"}
                                            placeholder={"Select..."}
                                            options={getUniqueOptions(allTerData, 'countryId', 'countryId', 'countryName')}
                                            value={{ value: selectedCountry, label: selectedCountryName }} // Set initial value here
                                            onChange={handleCountryChange}
                                            isClearable // Pass isClearable prop for conditional rendering
                                        />
                                    </td>
                                    <td>
                                        <CustomSelect2
                                            label={"State"}
                                            placeholder={"Select..."}
                                            options={selectedCountry ? getStatesForCountry() : []}
                                            value={{ value: selectedState, label: selectedStateName }} // Set initial value here
                                            onChange={handleStateChange}
                                            isClearable // Pass isClearable prop for conditional rendering
                                        />
                                    </td>
                                    <td>
                                        <CustomSelect2
                                            label={"District / Province"}
                                            placeholder={"Select..."}
                                            options={selectedState ? getDistrictsForState() : []}
                                            value={{ value: selectedDistrict, label: selectedDistrictName }} // Set initial value here
                                            onChange={handleDistrictChange}
                                            isClearable // Pass isClearable prop for conditional rendering
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomInputNumber
                                            label={" PinCode "}
                                            placeholder={"Enter"}
                                            value={pincode}
                                            onChange={setPincode}
                                        ></CustomInputNumber>
                                    </td>
                                    {user?.roleId === 1 && (
                                        <td>
                                            <CustomInputNumber
                                                label={"Branch Total User Allow"}
                                                placeholder={"Enter"}
                                                value={bUser}
                                                onChange={setBUser}
                                            ></CustomInputNumber>
                                        </td>
                                    )}
                                    <td>
                                        <div className="mt-5">
                                            <CustomCheckBox3
                                                label={"Is Active"}
                                                state={checkboxBranchEdit}
                                                setState={setCheckboxBranchEdit}
                                            ></CustomCheckBox3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-center gap-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading" : "Submit"} className="text-white bg-first" type="Submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
