import React, { useState, useEffect } from 'react';
import { BsCheck } from "react-icons/bs";
import Select from 'react-select';
import axios from 'axios';

import CustomAlphatext from '../../general-components/CustomAlphatext.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomDate from '../../general-components/CustomDate.component';
import CustomInputNumber from '../../general-components/CustomInputNumber.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomButton2 from '../../general-components/CustomButton2.component';
import CustomTextField2 from '../../general-components/CustomTextField2.component';

export default function PaymentReceivedEditInEdit({ onCancel, record, selectedValue }) {
    const [iNo, setINo] = useState("");
    const [amt, setAmt] = useState("");
    const [date, setDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paymentTermsData, setPaymentsTermsData] = useState([]);
    const [currencyTypeData, setCurrencyTypeData] = useState([]);
    const [paymentRecivedData, setPaymentRecivedData] = useState([]);
    const [paymentTermsId, setPaymentsTermsId] = useState("");
    const [currencyTypeId, setCurrencyTypeId] = useState("");
    const [paymentRecivedId, setPaymentRecivedId] = useState("");

    const [paymentTermsName, setPaymentsTermsName] = useState("");
    const [currencyTypeName, setCurrencyTypeName] = useState("");
    const [paymentRecivedName, setPaymentRecivedName] = useState("");
    const [poAmt, setPoAmt] = useState("")
    const [remainAmt, setRemainAmt] = useState("")
    const [paymentTermsId1, setPaymentsTermsId1] = useState("");
    const [paymentTermsName1, setPaymentsTermsName1] = useState("");
    const [currencyTypeId1, setCurrencyTypeId1] = useState("");
    const [currencyTypeName1, setCurrencyTypeName1] = useState("");
    const [totalAmt1, setTotalAmt1] = useState("");


    const dataActive = { searchText: "", isActive: true };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData, "Error fetching Payment Terms Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData, "Error fetching Currency Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData, "Error fetching Payment Received Data");
    }, []);

    useEffect(() => {
        if (record) {
            setINo(record.invoiceNumber || "");
            setAmt(record.amount || 0);
            setDate(record.paymentReceivedDate ? record.paymentReceivedDate.split('T')[0] : "");
            setPaymentsTermsId(record.paymentTermsId || "");
            setPaymentsTermsName(record.paymentTerms || "");
            setCurrencyTypeId(record.currencyTypeId || "");
            setCurrencyTypeName(record.currencyType || "");
            setPaymentRecivedId(record.paymentReceivedId || "");
            setPaymentRecivedName(record.paymentReceived || "");
            setPoAmt(record.poAmount)
            // setRemainAmt(record.remainingAmount)
            // setTotalAmt1(record.totalReceivedAmount)

            setRemainAmt(record.remainingAmount || poAmt - amt);
            setTotalAmt1(record.totalReceivedAmount || amt);
        }
    }, [record]);

    const parseDate = (formattedDate) => {
        const [year, month, day] = formattedDate.split('-');
        return new Date(`${year}-${month}-${day}`).toISOString();
    };

    const handleDateChange = (e) => {
        const formattedDate = e.target.value;
        setDate(formattedDate);
        const isoDate = parseDate(formattedDate);
        console.log('ISO Date:', isoDate);
    };

    useEffect(() => {
        if (selectedValue) {
            setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
            setCurrencyTypeName1(selectedValue.pO_CurrencyType);
            setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
            setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
        }
    }, [selectedValue])
    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handlePaymentReceivedChange1 = (value) => {
        setAmt(value);
        const remaining = poAmt - value;  // calculate remaining amount
        setRemainAmt(remaining);
    };

    const handlePaymentReceivedChange = (value) => {
        if (value > poAmt) {
            toast.error("Payment Received cannot exceed PO Amount");
            return;
        }
        setAmt(value);
        setRemainAmt(poAmt - value);
        setTotalAmt1(value);
    };

    const [invoiceError, setInvoiceError] = useState("");
    const [paymentTypeError, setPaymentTypeError] = useState("");

    const handleChange = (event) => {
        const newValue = event.target.value.toUpperCase();

        // Check if the value contains only alphanumeric characters
        if (/^[a-zA-Z0-9]*$/.test(newValue)) {
            setInvoiceError('');
            setINo(newValue);
        } else {
            setInvoiceError('Only alphanumeric characters are allowed');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Reset errors
        setInvoiceError("");
        setPaymentTypeError("");

        // Validate fields
        let hasError = false;
        if (!iNo) {
            setInvoiceError("Invoice Number is required");
            hasError = true;
        }
        if (!paymentRecivedId) {
            setPaymentTypeError("Payment Type is required");
            hasError = true;
        }

        if (hasError) {
            return; // Prevent submission if there are errors
        }
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                paymentReceived_Or_LCReceivedDetails: {
                    paymentOrLCReceived: 1,
                    paymentOrLCClosed: selectedValue.plR_IsPaymentOrLCClosed,
                    paymentReceivedDetail: [
                        {
                            id: record.id,
                            invoiceNumber: iNo || "",
                            paymentTermsId: paymentTermsId1 || 0,
                            paymentReceivedDate: date || "",
                            currencyTypeId: currencyTypeId1 || 0,
                            amount: amt || 0,
                            paymentReceivedId: paymentRecivedId || 0,
                            poAmount: poAmt || 0,
                            remainingAmount: remainAmt || 0,
                            totalReceivedAmount: totalAmt1 || 0
                        },
                    ],
                    lcReceivedDetail: []
                }
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");

                onCancel();
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaymentChange = (selectedOption) => {
        if (selectedOption) {
            setPaymentsTermsId(selectedOption.value);
            setPaymentsTermsName(selectedOption.label); // Update the department name label
        } else {
            setPaymentsTermsId(null); // Reset to null when cleared
            setPaymentsTermsName(''); // Clear the department name label
        }
    }

    const handlePaymentRecChange = (selectedOption) => {
        if (selectedOption) {
            setPaymentRecivedId(selectedOption.value);
            setPaymentRecivedName(selectedOption.label); // Update the department name label
        } else {
            setPaymentRecivedId(null); // Reset to null when cleared
            setPaymentRecivedName(''); // Clear the department name label
        }
    }

    const handleCurChange = (selectedOption) => {
        if (selectedOption) {
            setCurrencyTypeId(selectedOption.value);
            setCurrencyTypeName(selectedOption.label); // Update the department name label
        } else {
            setCurrencyTypeId(null); // Reset to null when cleared
            setCurrencyTypeName(''); // Clear the department name label
        }
    }

    return (
        <>
            <div className="pb-10 bg-white rounded-lg max-w-full">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Payment Received Record Edit</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-2 my-4">
                    <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-2 w-[650px] border border-prp p-5 rounded-lg">
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label className="text-xs font-400">
                                    Currency Type
                                </label>
                                <Select
                                    className="text-black text-xs select-bar uppercase"
                                    id="id"
                                    value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                                    menuPosition="fixed"
                                    isDisabled={true}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <CustomTextField2
                                label={"PO Amount"}
                                placeholder={"ENTER"}
                                value={poAmt}
                                // onChange={setPoAmt}
                                readOnly
                                isRequired={false}
                            ></CustomTextField2>
                        </div>
                        <div>
                            <CustomInputNumber
                                label={"Total Received Amount"}
                                placeholder={"ENTER"}
                                value={totalAmt1}
                                // onChange={setTotalAmt1}
                                readOnly
                                isRequired={false}
                            // readOnly
                            ></CustomInputNumber>
                        </div>
                        <div>
                            <CustomInputNumber
                                label={"Remaining Amount"}
                                placeholder={"ENTER"}
                                value={remainAmt}
                                onChange={setRemainAmt}
                                isRequired={false}
                                readOnly
                            ></CustomInputNumber>
                        </div>
                        <div>
                            <div className="flex flex-col gap-1">
                                <label className="text-xs font-400">
                                    Payment Terms
                                </label>
                                <Select
                                    className="text-black text-xs select-bar uppercase"
                                    id="id"
                                    value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                                    menuPosition="fixed"
                                    isDisabled={true}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400]">
                                    Payment Received Date
                                </label>
                                <input
                                    type="date"
                                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                    id="id"
                                    value={date}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                        <div>
                            {/* <CustomAlphatext
                                label={"Invoice Number"}
                                placeholder={"Enter"}
                                value={iNo}
                                onChange={setINo}
                            /> */}
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400]">
                                    Invoice Number<span className="text-red-500 gap-3">*</span>
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={iNo}
                                    className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                    id="id"
                                    placeholder={"Enter"}
                                    onChange={handleChange}
                                />
                                {invoiceError && <span className="text-red-500 text-xs">{invoiceError}</span>}
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-400 ">
                                    Payment Type
                                </label>
                                <Select
                                    options={paymentRecivedData.map(cData => ({
                                        value: cData.id,
                                        label: cData.paymentReceived
                                    }))}
                                    onChange={handlePaymentRecChange}
                                    className="text-black text-xs select-bar"
                                    id="departmentId"
                                    isSearchable
                                    isClearable
                                    value={{ value: paymentRecivedId, label: paymentRecivedName }}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                                {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                            </div>
                        </div>
                        <div className="">
                            <CustomInputNumber
                                label={"Payment Received"}
                                placeholder={"ENTER"}
                                value={amt}
                                onChange={handlePaymentReceivedChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}
