// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Button } from "@mui/material";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";
import Department from "../components/section-components/ManageProfile/Department";
import RoleProfile from "../components/section-components/ManageProfile/RoleProfile";
import RoleHierarchy from "../components/section-components/ManageProfile/RoleHierarchy";
import EmployeeProfile from "../components/section-components/ManageProfile/EmployeeProfile";
import EmpHistory from "../components/section-components/ManageProfile/EmpHistory";

// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function ManageProfile() {
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const savedTab = localStorage.getItem('activeTabDiyan');
        if (savedTab) {
            setActiveTab(parseInt(savedTab, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeTabDiyan', activeTab);
    }, [activeTab]);

    const tablabel = {
        0: "Department",
        1: "Role",
        2: "Role Hierarchy",
        3: "Employee",
        4: "Employee History"
    };

    const excelDownload = async () => {
        const token = localStorage.getItem('DIYAN_IE_authToken');

        if (!token) {
            console.error("Authentication required. Please log in.");
            return;
        }

        setIsLoading(true);

        let apiUrl = `${process.env.REACT_APP_BASE_URL}/Profile/ExportDepartmentData`;

        switch (activeTab) {
            case 1:
                apiUrl = `${process.env.REACT_APP_BASE_URL}/Profile/ExportRoleData`;
                break;
            case 2:
                apiUrl = `${process.env.REACT_APP_BASE_URL}/Profile/ExportRoleHierarchyData`;
                break;
            case 3:
                apiUrl = `${process.env.REACT_APP_BASE_URL}/User/ExportUserHistoryData?IsActive=true`;
                break;
            case 4:
                apiUrl = `${process.env.REACT_APP_BASE_URL}/User/ExportUserHistoryData?IsActive=false`;
                break;
            default:
                break;
        }

        try {
            const response = await axios.post(
                apiUrl,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess) {
                const base64Data = response.data.data;
                const binaryString = atob(base64Data);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${tablabel[activeTab].toLowerCase()}.xlsx`); // Set the file name dynamically
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.success(response.data.message);
            } else {
                console.error(`Failed to export ${tablabel[activeTab].toLowerCase()} data.`);
            }
        } catch (error) {
            console.error("Error during Excel export:", error);
            console.error(`Error fetching ${tablabel[activeTab].toLowerCase()} data details`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col h-full uppercase">
                <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Profile "} title2={"ADMINISTRATOR"} />
                    <div className="flex items-center flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap pb-5">
                        <Button
                            variant="contained"
                            size="small"
                            className="flex gap-2 justify-center items-center relative uppercase"
                            type="button"
                            style={{ backgroundColor: "#643c94" }}
                            onClick={excelDownload}
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading..." : "Excel"}
                        </Button>
                    </div>

                    <div className="px-2 lg:px-4 minbox">
                        <TabContainer>
                            <Tab label="Department" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="Role" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="Role Hierarchy" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="Employee" index={3} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <Tab label="Employee History" index={4} activeTab={activeTab} setActiveTab={setActiveTab} />
                        </TabContainer>
                    </div>

                    <TabContent index={0} activeTab={activeTab} key="tab-0">
                        <Department />
                    </TabContent>

                    <TabContent index={1} activeTab={activeTab} key="tab-1">
                        <RoleProfile />
                    </TabContent>

                    <TabContent index={2} activeTab={activeTab} key="tab-2">
                        <RoleHierarchy />
                    </TabContent>

                    <TabContent index={3} activeTab={activeTab} key="tab-3">
                        <EmployeeProfile />
                    </TabContent>

                    <TabContent index={4} activeTab={activeTab} key="tab-4">
                        <EmpHistory />
                    </TabContent>
                </div>
            </div>

            {/* <ToastContainer/> */}
        </>
    );
}

export default ManageProfile;

