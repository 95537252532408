import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from "react-select";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect2 from "../../general-components/CustomSelect2.component";

export default function RoleHierarchyEdit({ onCancel, roleHierarchy, selectedRoleHierarchy }) {
    const [checkboxRoleHierarchyEdit, setCheckboxRoleHierarchyEdit] = useState(true);
    const [editRolesHierarchy, setEditRolesHierarchy] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [Roles, setRoles] = useState([]);
    const [selectedRolesId, setSelectedRolesId] = useState('');
    const [editRole, setEditRole] = useState("");
    const [selectedRolesHierarchyId, setSelectedRolesHierarchyId] = useState('');

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (selectedRoleHierarchy) {
            setSelectedRolesId(selectedRoleHierarchy.roleId);
            setSelectedRolesHierarchyId(selectedRoleHierarchy.reportingTo); // Set department ID here
            setCheckboxRoleHierarchyEdit(selectedRoleHierarchy.isActive);
            setEditRole(selectedRoleHierarchy.roleName)
            setEditRolesHierarchy(selectedRoleHierarchy.reportingToName)
        }
    }, [selectedRoleHierarchy]);

    const data = { searchText: "" };
    const dataActive = { searchText: "", isActive: true, departmentId: 0 };
    const token = localStorage.getItem('DIYAN_IE_authToken');

    const fetchRoles = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Profile/GetRoleList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setRoles(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Roles");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRolesChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedRolesId(selectedOption.value);
            setEditRole(selectedOption.label); // Update the department name label
        } else {
            setSelectedRolesId(null); // Reset to null when cleared
            setEditRole(''); // Clear the department name label
        }
    };

    const handleRolesHierarchyChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedRolesHierarchyId(selectedOption.value);
            setEditRolesHierarchy(selectedOption.label); // Update the department name label
        } else {
            setSelectedRolesHierarchyId(null); // Reset to null when cleared
            setEditRolesHierarchy(''); // Clear the department name label
        }
    };

    const handleRoleHierarchySubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                roleId: selectedRolesId,
                reportingTo: selectedRolesHierarchyId,
                isActive: checkboxRoleHierarchyEdit,
            };

            if (selectedRoleHierarchy) {
                requestBody.id = selectedRoleHierarchy.id;
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Profile/SaveRoleHierarchy`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                console.log("Record Updated successfully:", responseData.message);
                toast.success("Record Updated successfully");

                setTimeout(() => {
                    onCancel();
                    // window.location.reload(); // Reload the page or fetch updated list
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Role Hierarchy operation:", error);
            toast.error("Error performing Role Hierarchy operation");
        } finally {
            setIsLoading(false);
        }
    };

    const usedRoleIds = new Set(roleHierarchy.map(item => item.roleId));
    const availableRolesForRoleName = Roles.filter(role => !usedRoleIds.has(role.id));

    const filterRoleOptions = (selectedRoleId) => {
        return Roles.filter(role => role.id !== selectedRoleId);
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex justify-end "></div>

                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Role Hierarchy</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleRoleHierarchySubmit}>
                    <div className="p-4 mx-auto w-[700px]">
                        <table className="w-[100%] mx-auto popup-table">
                            <tbody>
                                <tr>
                                    <td className="w-[41%]">
                                        {/* <CustomSelect1 label={"Role Name"}></CustomSelect1> */}
                                        <div className="">
                                            <CustomSelect2
                                                label={"Role Name"}
                                                placeholder={"Select..."}
                                                options={availableRolesForRoleName.map(role => ({
                                                    value: role.id,
                                                    label: role.roleName
                                                }))}
                                                value={{ value: selectedRolesId, label: editRole }} // Set initial value here
                                                onChange={handleRolesChange}
                                                isClearable // Pass isClearable prop for conditional rendering
                                            />
                                        </div>
                                    </td>
                                    <td className="w-[41%]">
                                        {/* <CustomSelect1 label={"Role Hierarchy"} /> */}
                                        <div className="">
                                            <CustomSelect2
                                                label={"Role Hierarchy"}
                                                placeholder={"Select..."}
                                                options={filterRoleOptions(selectedRolesId).map(role => ({
                                                    value: role.id,
                                                    label: role.roleName
                                                }))}
                                                value={{ value: selectedRolesHierarchyId, label: editRolesHierarchy }} // Set initial value here
                                                onChange={handleRolesHierarchyChange}
                                                isClearable // Pass isClearable prop for conditional rendering
                                            />
                                        </div>
                                    </td>
                                    <td className="align-bottom w-[18%]">
                                        <CustomCheckBox2
                                            label={"Is Active"}
                                            state={checkboxRoleHierarchyEdit}
                                            setState={setCheckboxRoleHierarchyEdit}
                                        ></CustomCheckBox2>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-center gap-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading..." : "Update"} className="text-white bg-first" type="submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
