// CORE
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";

import RolePermissionWeb from "../components/section-components/RolePermission/RolePermissionWeb";
import RolePermissionMobile from "../components/section-components/RolePermission/RolePermissionMobile";

function RolePermission() {
    const [activeTab, setActiveTab] = useState(0);

    // const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const role_Id = searchParams.get("roleId");  

    return (
        <div className="flex flex-col h-full bg-[#F9FBFF] uppercase">
            <Title title1={"Admin Profile"} title2={"ADMINITSTRATOR"} />

            <div className="p-4 pt-8 pb-8  rounded grow" style={{ border: "1px solid rgba(61, 61, 61, 0.30)" }}>
                <h1> Role Permission</h1>
                <div className="px-2  lg:px-4 ">
                    {/* 3 TABS */}
                    <TabContainer>
                        <Tab label="Website Application" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Mobile App Application" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                {/* TAB 1 CONTENT : DEPARTMENT */}
                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <RolePermissionWeb role_Id={role_Id} />
                    </div>
                </TabContent>

                {/* TAB 2 CONTENT : ROLES */}
                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <RolePermissionMobile role_Id={role_Id} />
                    </div>
                </TabContent>
            </div>
        </div>
    );
}

export default RolePermission;
