import React, { useMemo, useState, useEffect } from "react";
import axios from 'axios';
import { Checkbox } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { deepPurple } from '@mui/material/colors';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

const RoleEmployeePermissionWeb = ({ emp_Id, role_Id }) => {
    console.log("🚀 ~ file: RoleEmployeePermissionWeb.jsx:12 ~ RoleEmployeePermissionWeb ~ emp_Id:", emp_Id)
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [inputData, setInputData] = useState([]);
    console.log("🚀 ~ file: RoleEmployeePermissionWeb.jsx:17 ~ RoleEmployeePermissionWeb ~ inputData:", inputData)
    const [selectAll, setSelectAll] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [addAll, setAddAll] = useState(false);
    const [editAll, setEditAll] = useState(false);
    const appType = "W"
    const moduleList = useMemo(() =>
        inputData.filter(val => appType === val.appType),
        [inputData]
    );
    const token = localStorage.getItem('DIYAN_IE_authToken');

    const fetchApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/RolePermission/GetRoleMasterEmployeePermissionList`, {
                "pageNo": 0,
                "pageSize": 0,
                "searchText": "",
                "employeeId": Number(emp_Id) || 0,
                "isActive": true
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const fetchedData = response.data.data.map(item => ({
                    ...item,
                    view: item.view,
                    add: item.add,
                    edit: item.edit
                }));
                setInputData(fetchedData);
                setSelectAll(fetchedData.every(item => item.view && item.add && item.edit));
                setViewAll(fetchedData.every(item => item.view));
                setAddAll(fetchedData.every(item => item.add));
                setEditAll(fetchedData.every(item => item.edit));
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Module Master List");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchApiData();
    }, []);

    const handleSelectAll = () => {
        const newValue = !selectAll;
        setSelectAll(newValue);
        setInputData(moduleList.map(item => ({
            ...item,
            view: newValue,
            add: newValue,
            edit: newValue
        })));
        setViewAll(newValue);
        setAddAll(newValue);
        setEditAll(newValue);
    };

    const handleColumnSelect = (column) => {
        const newValue = column === 'view' ? !viewAll : column === 'add' ? !addAll : !editAll;
        setInputData(moduleList.map(item => ({
            ...item,
            [column]: newValue
        })));
        if (column === 'view') setViewAll(newValue);
        if (column === 'add') setAddAll(newValue);
        if (column === 'edit') setEditAll(newValue);
    };

    const handleCheckboxChange = (index, column) => {
        const newData = [...moduleList];
        newData[index][column] = !newData[index][column];
        setInputData(newData);

        setSelectAll(newData.every(item => item.view && item.add && item.edit));
        setViewAll(newData.every(item => item.view));
        setAddAll(newData.every(item => item.add));
        setEditAll(newData.every(item => item.edit));
    };
    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const payload = {
                employeeId: Number(emp_Id),
                roleId: Number(role_Id),
                appType: "W",
                isActive: true,
                moduleList: moduleList.map(item => ({
                    moduleId: item.moduleId,
                    view: item.view, // Convert to boolean
                    add: item.add, // Convert to boolean
                    edit: item.edit, // Convert to boolean
                }))
            };

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/RolePermission/SaveRoleMasterEmployeePermission`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess) {
                toast.success("Employee Role website permissions updated successfully");
                // navigate(`/admin/admin-profile`);
                    localStorage.setItem('activeTabDiyan', 3); // Ensure the Role tab is active
                    navigate(`/admin/admin-profile`);
            } else {
                toast.error("Failed to update Employee role website permissions");
            }
        } catch (error) {
            toast.error("Error updating Employee role website permissions");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <div>
                Select All
                <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                />
                <div className="mt-2 w-[76.5vw] hide-scrollbar overflow-auto table-container">
                    <table className="w-full custom-table" border={1}>
                        <thead>
                            <tr className="table-heading bg-[#F9FBFF]">
                                <td className="">S.No</td>
                                <td>Permission Name</td>
                                <td>
                                    View
                                    <Checkbox
                                        checked={viewAll}
                                        onChange={() => handleColumnSelect('view')}
                                        sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                    />
                                </td>
                                <td>
                                    Add
                                    <Checkbox
                                        checked={addAll}
                                        onChange={() => handleColumnSelect('add')}
                                        sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                    />
                                </td>
                                <td>
                                    Edit
                                    <Checkbox
                                        checked={editAll}
                                        onChange={() => handleColumnSelect('edit')}
                                        sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {moduleList?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.moduleName}</td>
                                    <td>
                                        <Checkbox
                                            checked={item.view}
                                            onChange={() => handleCheckboxChange(index, 'view')}
                                            sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={item.add}
                                            onChange={() => handleCheckboxChange(index, 'add')}
                                            sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={item.edit}
                                            onChange={() => handleCheckboxChange(index, 'edit')}
                                            sx={{ '&.Mui-checked': { color: deepPurple[600] } }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 bg-[#F9FBFF] p-8">
                    <div onClick={handleUpdate}>
                        <CustomButton1
                            label={isLoading ? "Loading" : "Update"}
                            className="text-white bg-prp-color"
                            type="submit"
                        />
                    </div>
                    <div>
                        <CustomButton2
                            label={"Back"}
                            className="text-white bg-prp-color"
                            // onClick={() => navigate(`/admin/admin-profile`)}
                            onClick={() => {
                                localStorage.setItem('activeTabDiyan', 3); // Ensure the Role tab is active
                                navigate(`/admin/admin-profile`);
                            }}
                        />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
};

export default RoleEmployeePermissionWeb;
