// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DeliveryTermsAdd({ onCancel }) {
    const [addValue, setAddValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let [checkboxAdd, setCheckboxAdd] = useState(true);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                deliveryTerms: addValue,
                isActive: checkboxAdd
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/AdminMaster/SaveDeliveryTerms`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                } else {
                    toast.success("Record Inserted successfully");
                }
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    onCancel(); // Reload the page or fetch updated list
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Delivery Terms operation:", error);
            toast.error("Error performing Delivery Terms operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[300]">Add Delivery Terms</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField2
                                        label={"Delivery Terms"}
                                        placeholder={"ENTER"}
                                        value={addValue}
                                        onChange={setAddValue}
                                    ></CustomTextField2>
                                </td>
                                <td>
                                    <CustomCheckBox2
                                        label={"Is Active"}
                                        state={checkboxAdd}
                                        setState={setCheckboxAdd}
                                    ></CustomCheckBox2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={isLoading ? "Loading" : "Submit"} className="bg-prp-color w-full" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}

