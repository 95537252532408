// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomSelect2 from "../../general-components/CustomSelect2.component";

// ICONS
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import CustomSelect1 from "../../general-components/CustomSelect1.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from "../../general-components/CustomButton2.component";
import { getSinglePermission } from "../../../../Helpers/Constants";
import { useAuth } from "../../../context";
import { Tooltip } from "@mui/material";
import Pagination from "../../general-components/Pagination.component";

export default function TabBrand() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "ADMIN PAPER TYPE"))
        }
    }, [user])
    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);
    let [checkboxAdd, setCheckboxAdd] = useState(true);
    let [checkboxEdit, setCheckboxEdit] = useState(true);

    const [addValue, setAddValue] = useState("");
    const [editValue, setEditValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const [editPaperType, setEditPaperType] = useState([]);
    const [paperTypeData, setPaperTypeData] = useState([]);
    const [selectedPaperType, setSelectedPaperType] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "" };
    const dataActive = { searchText: "", isActive: true };

    const fetchPaperTypeApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaperTypeList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data
                setPaperTypeData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Paper Type Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPaperTypeApiData();
    }, [])

    const fetchApiData = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBrandList`,
                // data, 
                { searchText: searchQuery }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });

                setInputData(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Brand Data");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePaperTypeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedPaperType(selectedOption.value);
            setEditPaperType(selectedOption.label); // Update the department name label
        } else {
            setSelectedPaperType(null); // Reset to null when cleared
            setEditPaperType(''); // Clear the department name label
        }
    };

    const handleEditButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupEdit(true);
    };

    const fetchApiDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBrandById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                setSelectedValue(response.data.data);
                setEditValue(response.data.data.brand);
                setSelectedPaperType(response.data.data.paperTypeId);
                setEditPaperType(response.data.data.paperType);
                setCheckboxEdit(response.data.data.isActive);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage Brand details");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                paperTypeId: selectedPaperType,
                brand: selectedValue ? editValue : addValue,
                isActive: selectedValue ? checkboxEdit : checkboxAdd
            };

            // Include department ID if editing an existing department
            if (selectedValue) {
                requestBody.id = selectedValue.id;
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/AdminMaster/SaveBrand`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (selectedValue) {
                    console.log("Record Updated successfully:", responseData.message);
                    toast.success("Record Updated successfully");
                } else {
                    // console.log("Record Inserted successfully:", responseData.message);
                    if (responseData.message === "Record is already exists") {
                        toast.error("Duplicate entry is not permissable");
                    } else {
                        toast.success("Record Inserted successfully");
                        setAddValue("");
                    }
                }
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    setPopupAdd(false);
                    setPopupEdit(false);
                    fetchApiData();
                    // window.location.reload(); // Reload the page or fetch updated list
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Brand operation:", error);
            toast.error("Error performing Brand operation");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchApiData(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div>
                {/* 4 BUTTONS */}
                <div className="flex flex-row flex-wrap justify-start gap-2 md:flex-nowrap minbox">
                    {Permission?.add && <CustomButton1
                        label={"Add "}
                        icon={<IoMdAdd />}
                        className="bg-prp-color text-white shrink grow md:grow-0 max-w-[50%]"
                        onClick={() => setPopupAdd(true)}
                    />}

                    <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                        <input
                            type="text"
                            className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
                            placeholder="SEARCH"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText ? (
                            <IoMdCloseCircle
                                className="min-w-[20px] cursor-pointer txt-prp-color"
                                onClick={clearSearch}
                            />
                        ) : (
                            <AiOutlineSearch className="min-w-[20px]" />
                        )}
                    </div>
                </div>

                {Permission?.view &&
                    <>
                        {/* TABLE */}
                        <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                            <table className="w-full custom-table" border={1}>
                                <thead>
                                    <tr className="table-heading">
                                        <td className="">S.No</td>
                                        {(Permission?.edit || Permission?.view) && <td>Action</td>}
                                        <td>Status</td>
                                        <td>Paper Type</td>
                                        <td>Brand</td>
                                        <td>Created Date </td>
                                        <td>Created By </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="7" className="text-center">Loading...</td>
                                        </tr>
                                    ) : (paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((cData, index) => (
                                            <tr key={cData.id}>
                                                <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                {Permission?.edit && <td>
                                                    <div className="gap-2">
                                                        <Tooltip title="EDIT" arrow placement="right">
                                                            <span>
                                                                <CustomButton1
                                                                    className="bg-sixt text-white grow max-w-[50px]"
                                                                    icon={<BiEdit />}
                                                                    onClick={() => handleEditButtonClick(cData.id)}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </td>}
                                                <td>
                                                    <div>
                                                        <h2 style={{ color: cData.isActive ? 'green' : 'red' }}>{cData.isActive ? 'Active' : 'Inactive'}</h2>
                                                    </div>
                                                </td>
                                                <td>{cData.paperType}</td>
                                                <td>{cData.brand}</td>
                                                <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                                <td>{cData.creatorName}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        searchText?.trim() ? (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No results found for "{searchText}".
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No data available.
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <Pagination
                            currentPage={currentPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            data={inputData}
                        />
                    </>
                }
            </div>

            {/* POPUP 1 : ADD */}
            {popupAdd && (
                <PopUp>
                    <div className="pb-10 bg-white rounded-lg">
                        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                            <h1 className="text-xl font-weight-[400]">Add Paper Type</h1>
                            <div className="flex  item-center text-sm"></div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="p-4 mx-auto w-[700px]">
                                <table className="w-[100%] mx-auto popup-table">
                                    <tbody>
                                        <tr>
                                            <td className="w-[41%]">
                                                <CustomSelect1
                                                    label={"Paper Type"}
                                                    placeholder={"ENTER"}
                                                    options={paperTypeData.map(pData => ({
                                                        value: pData.id,
                                                        label: pData.paperType
                                                    }))}
                                                    onChange={handlePaperTypeChange}
                                                ></CustomSelect1>
                                            </td>
                                            <td className="w-[41%]">
                                                <CustomTextField2
                                                    label={"Brand"}
                                                    placeholder={"ENTER"}
                                                    value={addValue}
                                                    onChange={setAddValue}
                                                ></CustomTextField2>
                                            </td>
                                            <td className="align-bottom w-[18%]">
                                                <CustomCheckBox2
                                                    label={"Is Active"}
                                                    state={checkboxAdd}
                                                    setState={setCheckboxAdd}
                                                ></CustomCheckBox2>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-center gap-5">
                                <div>
                                    <CustomButton1 label={isLoading ? "Loading..." : "Submit"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setPopupAdd(false)}>
                                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </form>
                    </div>
                </PopUp>
            )}

            {/* POPUP 2 : EDIT */}
            {popupEdit && (
                <PopUp>
                    <div className="pb-10 bg-white rounded-lg">
                        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                            <h1 className="text-xl font-weight-[300]">Edit Paper Type</h1>
                            <div className="flex  item-center text-sm"></div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="p-4 mx-auto w-[700px]">
                                <table className="w-[100%] mx-auto popup-table">
                                    <tbody>
                                        <tr>
                                            <td className="w-[41%]">
                                                <CustomSelect2
                                                    label={"Paper Type"}
                                                    placeholder={"Select..."}
                                                    options={paperTypeData.map(pData => ({
                                                        value: pData.id,
                                                        label: pData.paperType
                                                    }))}
                                                    value={{ value: selectedPaperType, label: editPaperType }} // Set initial value here
                                                    onChange={handlePaperTypeChange}
                                                    isClearable // Pass isClearable prop for conditional rendering
                                                />
                                            </td>
                                            <td className="w-[41%]">
                                                <CustomTextField2
                                                    label={"Brand"}
                                                    placeholder={"ENTER"}
                                                    value={editValue}
                                                    onChange={setEditValue}
                                                ></CustomTextField2>
                                            </td>
                                            <td className="align-bottom w-[18%]">
                                                <CustomCheckBox2
                                                    label={"Is Active"}
                                                    state={checkboxEdit}
                                                    setState={setCheckboxEdit}
                                                ></CustomCheckBox2>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-center gap-5">
                                <div>
                                    <CustomButton1 label={isLoading ? "Loading..." : "Update"} className="text-white bg-prp-color" type="submit" />

                                </div>
                                <div onClick={() => setPopupEdit(false)}>
                                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </form>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
