import * as React from "react";
import { useState } from "react";

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditContactDetailsAddPopup({ onCancel, cName, currentCustomerId }) {
    const [conPerson, setConPerson] = useState("");
    const [phoneNo, setphoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleMobileChange = (e) => {
        const value = e.target.value;

        // Only allow digits and limit input to 10 characters
        if (/^\d{0,10}$/.test(value)) {
            setphoneNo(value);
        }
    };

    const validateMobile = (value) => {
        if (!value) {
            setMobileError('Mobile number is required');
            return false;
        } else if (value.length !== 10) {
            setMobileError('Mobile number must be exactly 10 digits');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const validateEmail = (value) => {
        if (!value) {
            setEmailError('Email is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            setEmailError('Email is invalid');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isMobileValid = validateMobile(phoneNo);
        const isEmailValid = validateEmail(email);

        if (!isMobileValid || !isEmailValid) {
            // Stop form submission if validation fails
            toast.error("Please fix the errors before submitting.");
            return;
        }

        setIsLoading(true);
        try {
            const requestBody = {
                customerId: currentCustomerId,
                contactPerson: conPerson || "",
                mobileNo: phoneNo,
                emailId: email,
                isActive: true
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveContactDetails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissible");
                } else {
                    toast.success("Record Inserted successfully");
                }
                setTimeout(() => {
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing customer operation:", error);
            toast.error("Error performing customer operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Contact Details</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField2
                                        label={"Customer Name"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={cName}
                                        readOnly={true}
                                    />
                                </td>
                                <td>
                                    <CustomTextField2
                                        label={"Contact Person"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={conPerson}
                                        onChange={setConPerson}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Mobile Number
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            required
                                            value={phoneNo}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={handleMobileChange}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="email" className="text-xs font-[400]">
                                            Email Id
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            required
                                            value={email}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${emailError ? 'border-red-500' : ''}`}
                                            id="email"
                                            placeholder="Enter"
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {emailError && <span className="text-red-500 text-xs">{emailError}</span>}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Submit"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
