import React from 'react';
import Select from 'react-select';
import '../../assets/css/selectbar.css';

function CustomSelect3(props) {
    const { label, isRequired, options, onChange, readOnly, value } = props;
    return (
        <div>
            <Select
                options={options}
                className="text-black text-xs select-bar uppercase"
                id="id"
                isSearchable
                readOnly={readOnly}
                isDisabled={readOnly}
                isClearable
                onChange={onChange}
                value={options.find(option => option.value === value) || null} // Set the value correctly
                placeholder={props.placeholder}
                closeMenuOnSelect={true}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                    },
                })}
            />
        </div>
    );
}


CustomSelect3.defaultProps = {

};

export default CustomSelect3;
