// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';

import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomImgUpload from "../../general-components/CustomImgUpload.component";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../../context";
import CustomSelect2 from "../../general-components/CustomSelect2.component";

// Function to add months to a date
const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result.toISOString().split('T')[0]; // Format as YYYY-MM-DD
};

export default function ManageCompanyDetailEdit({ selectedCompDetail, onCancel }) {
    const { user } = useAuth()
    let [checkboxCompanyNameEdit, setCheckboxCompanyNameEdit] = useState(true);

    const [compName, setCompName] = useState("");
    const [regNo, setRegNo] = useState("");
    const [conNo, setConNo] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [gstNo, setGSTNo] = useState("");
    const [panNo, setPanNo] = useState("");
    const [texNo, setTaxNo] = useState("");
    const [address, setAddress] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [userNo, setUserNo] = useState("");
    const [branchNo, setBranchNo] = useState("");

    const [amcMonth, setAmcMonth] = useState("");
    const [amcStartDate, setAmcStartDate] = useState("");
    const [amcEndDate, setAmcEndDate] = useState("");

    const [compTypeData, setCompTypeData] = useState([]);
    const [selectedCompType, setSelectedCompType] = useState("");
    const [selectedCompTypeName, setSelectedCompTypeName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };

    const [fileName, setFileName] = useState("");
    const [fileOriginalName, setFileOriginalName] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [profileImage, setProfileImage] = useState("");

    const [allTerData, setAllTerData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [selectedCountryName, setSelectedCountryName] = useState(null);
    const [selectedStateName, setSelectedStateName] = useState(null);
    const [selectedDistrictName, setSelectedDistrictName] = useState(null);

    // Update AMC End Date whenever AMC Month or AMC Start Date changes
    useEffect(() => {
        if (amcStartDate && amcMonth && !isNaN(amcMonth)) {
            const endDate = addMonths(new Date(amcStartDate), parseInt(amcMonth, 10));
            setAmcEndDate(endDate);
        }
    }, [amcStartDate, amcMonth]);

    // new weokss
    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/CompanyType/GetCompanyTypeList`, setCompTypeData, "Error fetching Company Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");
    }, []);

    const handleCompTypeChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCompType(selectedOption.value);
            setSelectedCompTypeName(selectedOption.label); // Update the department name label
        } else {
            setSelectedCompType(null); // Reset to null when cleared
            setSelectedCompTypeName(''); // Clear the department name label
        }
    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
            setSelectedCountryName(selectedOption.label);
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        } else {
            setSelectedCountry(null); // Reset to null when cleared
            setSelectedCountryName(''); // Clear the department name label
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedState(selectedOption.value);
            setSelectedStateName(selectedOption.label); // Update the department name label
            setSelectedDistrict(null); // Reset district when state changes
        } else {
            setSelectedState(null); // Reset to null when cleared
            setSelectedStateName(''); // Clear the department name label
            setSelectedDistrict(null); // Reset district when state changes
        }
    }

    const handleDistrictChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDistrict(selectedOption.value);
            setSelectedDistrictName(selectedOption.label); // Update the department name label
        } else {
            setSelectedDistrict(null); // Reset to null when cleared
            setSelectedDistrictName(''); // Clear the department name label
        }
    }

    const handleRoleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                id: selectedCompDetail.id,
                companyName: compName,
                companyTypeId: selectedCompType || 0,
                registrationNumber: regNo || "",
                contactNumber: conNo || "",
                email: email || "",
                website: website || "",
                // taxNumber: texNo,
                addressLine1: address || "",
                countryId: selectedCountry || 0,
                stateId: selectedState || 0,
                districtId: selectedDistrict || 0,
                pincode: pinCode || "",
                gstNumber: gstNo || "",
                panNumber: panNo || "",
                noofUserAdd: userNo || 0,
                noofBranchAdd: branchNo || 0,
                isActive: checkboxCompanyNameEdit,
                // ...(user?.roleId === 1 ? {
                amcMonth: amcMonth || 0,
                amcStartDate: amcStartDate,
                amcEndDate: amcEndDate
                // } : {})
            };

            if (profileImage && profileImage.base64) {
                requestBody.companyLogo_Base64 = profileImage.base64 || "";
                requestBody.companyLogoFileName = profileImage.name || "";

            } else {
                requestBody.companyLogoFileName = '';
                requestBody.companyLogo_Base64 = '';
            }

            console.log("request-body-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Company/SaveCompany`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");

                setTimeout(() => {
                    onCancel();
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Manage Company  operation:", error);
            toast.error("Error performing Manage Company  operation");
        } finally {
            setIsLoading(false);
        }
    };

    const getUniqueOptions = (data, key, valueKey, labelKey) => {
        const unique = new Set();
        return data
            .filter(item => {
                if (!unique.has(item[key])) {
                    unique.add(item[key]);
                    return true;
                }
                return false;
            })
            .map(item => ({
                value: item[valueKey],
                label: item[labelKey]
            }));
    };

    const getStatesForCountry = () => {
        return getUniqueOptions(allTerData.filter(data => data.countryId === selectedCountry), 'stateId', 'stateId', 'stateName');
    };

    const getDistrictsForState = () => {
        return getUniqueOptions(allTerData.filter(data => data.stateId === selectedState), 'districtId', 'districtId', 'districtName');
    };

    useEffect(() => {
        if (selectedCompDetail) {
            setCompName(selectedCompDetail.companyName);
            setRegNo(selectedCompDetail.registrationNumber);
            setConNo(selectedCompDetail.contactNumber);
            setEmail(selectedCompDetail.email);
            setWebsite(selectedCompDetail.website);
            setGSTNo(selectedCompDetail.gstNumber);
            setPanNo(selectedCompDetail.panNumber);
            setTaxNo(selectedCompDetail.taxNumber);
            setAddress(selectedCompDetail.addressLine1);
            setPinCode(selectedCompDetail.pincode);
            setUserNo(selectedCompDetail.noofUserAdd);
            setBranchNo(selectedCompDetail.noofBranchAdd);
            setSelectedCompType(selectedCompDetail.companyTypeId);
            setSelectedCompTypeName(selectedCompDetail.companyType)
            setSelectedState(selectedCompDetail.stateId);
            setSelectedStateName(selectedCompDetail.stateName);
            setSelectedDistrict(selectedCompDetail.districtId);
            setSelectedDistrictName(selectedCompDetail.districtName);
            setCheckboxCompanyNameEdit(selectedCompDetail.isActive);

            setFileName(selectedCompDetail.profileImage);
            setFileOriginalName(selectedCompDetail.companyLogo);
            setFileUrl(selectedCompDetail.companyLogoURL);

            setSelectedCountry(selectedCompDetail.countryId);
            setSelectedState(selectedCompDetail.stateId);
            setSelectedDistrict(selectedCompDetail.districtId);
            setSelectedCountryName(selectedCompDetail.countryName);
            setSelectedStateName(selectedCompDetail.stateName);
            setSelectedDistrictName(selectedCompDetail.districtName);

            if (selectedCompDetail.amcMonth) setAmcMonth(selectedCompDetail.amcMonth);
            if (selectedCompDetail.amcStartDate) setAmcStartDate(selectedCompDetail.amcStartDate.split("T")[0]);
            if (selectedCompDetail.amcEndDate) setAmcEndDate(selectedCompDetail.amcEndDate.split("T")[0]);
        }
    }, [selectedCompDetail])

    return (
        <>
            <div className="pb-5 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Edit Company</h1>
                    <div className="flex item-center text-sm"></div>
                </div>

                <form onSubmit={handleRoleSubmit}>
                    <div className="p-4 mx-auto overflow-scroll overflow-x-auto" style={{ maxHeight: "500px" }}>
                        <div className="max-w-full popup-table w-[1050px]">
                            <div className="grid grid-cols-1  gap-4 md:grid-cols-3 ">

                                <div>
                                    <CustomTextField2 label={"Company Name"} placeholder={"ENTER"}
                                        value={compName}
                                        onChange={setCompName}
                                    />
                                </div>
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <CustomSelect2
                                            label={"Company type"}
                                            placeholder={"Select..."}
                                            options={compTypeData.map(data => ({
                                                value: data.id,
                                                label: data.companyType
                                            }))}
                                            value={{ value: selectedCompType, label: selectedCompTypeName }} // Set initial value here
                                            onChange={handleCompTypeChange}
                                            isClearable // Pass isClearable prop for conditional rendering
                                        />
                                    </div>
                                </div>
                                <div>
                                    <CustomAlphatext label={"Registration number"} placeholder={"ENTER"}
                                        value={regNo}
                                        onChange={setRegNo}
                                    />
                                </div>
                                <div>
                                    <CustomNumber label={"Contact Number"} placeholder={"ENTER"}
                                        value={conNo}
                                        onChange={setConNo}
                                    />
                                </div>
                                <div>
                                    <CustomEmail label="Email" placeholder="Enter"
                                        value={email}
                                        onChange={setEmail}
                                    />
                                </div>
                                <div>
                                    <CustomTextField2 label={"Website"} placeholder={"ENTER"}
                                        value={website}
                                        onChange={setWebsite}
                                    />
                                </div>
                                {user?.roleId === 1 && (
                                    <>
                                        <div>
                                            <CustomInputNumber
                                                label={"AMC Month"}
                                                placeholder={"ENTER"}
                                                value={amcMonth}
                                                onChange={(value) => setAmcMonth(value)}
                                                isRequired={false}
                                            />
                                        </div>
                                        <div>
                                            <CustomDate
                                                label="AMC Start Date"
                                                placeholder="Enter"
                                                value={amcStartDate}
                                                onChange={(value) => setAmcStartDate(value)}
                                                isRequired={false}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <CustomDate
                                                label="AMC End Date"
                                                placeholder="Enter"
                                                value={amcEndDate}
                                                isRequired={false}
                                                readOnly // End date should not be editable
                                            />
                                        </div>
                                    </>
                                )}
                                <div>
                                    <CustomAlphatext label={"GST Number"} placeholder={"ENTER"}
                                        value={gstNo}
                                        onChange={setGSTNo}
                                    />
                                </div>
                                <div>
                                    <CustomAlphatext label={"Pan Number"} placeholder={"ENTER"}
                                        value={panNo}
                                        onChange={setPanNo}
                                    />
                                </div>
                                <div>
                                    <CustomAlphatext label={"Tax Number / CIN"} placeholder={"ENTER"}
                                        // value={texNo}
                                        // onChange={setTaxNo}
                                        readOnly={true}
                                    />
                                </div>
                                <div>
                                    <CustomTextArea label={"Address"} placeholder={"ENTER"}
                                        value={address}
                                        onChange={setAddress}
                                    />
                                </div>
                                <div>
                                    <CustomSelect2
                                        label={"Country"}
                                        placeholder={"Select..."}
                                        options={getUniqueOptions(allTerData, 'countryId', 'countryId', 'countryName')}
                                        value={{ value: selectedCountry, label: selectedCountryName }} // Set initial value here
                                        onChange={handleCountryChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div>
                                    <CustomSelect2
                                        label={"State"}
                                        placeholder={"Select..."}
                                        options={selectedCountry ? getStatesForCountry() : []}
                                        value={{ value: selectedState, label: selectedStateName }} // Set initial value here
                                        onChange={handleStateChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>
                                <div>
                                <CustomSelect2
                                        label={"District / Province"}
                                        placeholder={"Select..."}
                                        options={selectedState ? getDistrictsForState() : []}
                                        value={{ value: selectedDistrict, label: selectedDistrictName }} // Set initial value here
                                        onChange={handleDistrictChange}
                                        isClearable // Pass isClearable prop for conditional rendering
                                    />
                                </div>

                                <div>
                                    <CustomInputNumber label={"Pin code"} placeholder={"ENTER"}
                                        value={pinCode}
                                        onChange={setPinCode}
                                    />
                                </div>
                                {user?.roleId === 1 && (
                                    <div>
                                        <CustomInputNumber label={"Total Number of User"} placeholder={"ENTER"}
                                            value={userNo}
                                            onChange={setUserNo}
                                        />
                                    </div>
                                )}
                                {user?.roleId === 1 && (
                                    <div>
                                        <CustomInputNumber label={"Total branches"} placeholder={"ENTER"}
                                            value={branchNo}
                                            onChange={setBranchNo}
                                        />
                                    </div>
                                )}
                                <div>
                                    <div>
                                        <CustomImgUpload
                                            id="image-upload-mnc-adddfgdfg"
                                            label="Image Upload"
                                            value={profileImage}
                                            onChange={setProfileImage}
                                            initialFileName={fileOriginalName}
                                            initialFileUrl={fileUrl}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <CustomCheckBox2
                                        label={"Is Active"}
                                        state={checkboxCompanyNameEdit}
                                        setState={setCheckboxCompanyNameEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center pt-3 border-t gap-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading" : "Update"} className="text-white bg-first" type="Submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
