import * as React from "react";
import Button from "@mui/material/Button";

function CustomButton1(props) {
    return (
        <Button
            variant={props.variant ?? "contained"}
            size={props.size ?? "small"}
            className={` ${props.className} flex gap-2 justify-center items-center relative uppercase`}
            type={props.type ?? "button"}
            style={{
                backgroundColor: props.disabled ? "#b4b4b4" : "#643c94",
            }}
            disabled={props.disabled ?? false}
            onClick={props.disabled ? undefined : props.onClick}
        >
            {props.icon && <span className="text-sm">{props.icon}</span>}
            {props.label && (
                <span className="whitespace-nowrap font-[400] font-Mitr text-white">
                    {props.label.toUpperCase()}
                </span>
            )}
        </Button>
    );
}

export default CustomButton1;
