// CORE
import * as React from "react";
import { useState , useEffect} from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";

// ICONS
import { AiOutlineImport, AiOutlineDownload } from "react-icons/ai";
import AllPayment from "../components/section-components/ManagePaymentReceived_LCReceived/AllPayment";
import PaymentReceived from "../components/section-components/ManagePaymentReceived_LCReceived/PaymentReceived";
import LCReceived from "../components/section-components/ManagePaymentReceived_LCReceived/LCReceived";
import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";
export default function ManagePaymentReceived_LCReceived() {
    const [activeTab, setActiveTab] = useState(0);
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE TRACKING > PAYMENT RECEIVED  LC RECEIVED"))
        }
    }, [user])
    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage Payment Received / Lc Received"} title2={"Manage Tracking"} />

                <div className="px-2 lg:px-4 minbox mt-5">
                    {/* 18 TABS */}
                    <TabContainer showArrow={true}>
                        <Tab label="All" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Payment Received" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="LC Received" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <AllPayment Permission={Permission}/>
                    </div>
                </TabContent>

                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <PaymentReceived Permission={Permission} />
                    </div>
                </TabContent>

                <TabContent index={2} activeTab={activeTab}>
                    <div>
                        <LCReceived Permission={Permission} />
                    </div>
                </TabContent>
            </div>
        </div>
    );
}
