// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";
import Select from 'react-select';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomButton4 from "../../general-components/CustomButton4.component";

import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

import PaymentReceivedEditInView from "./PaymentReceivedEditInView";
import PaymentReceivedEditInEdit from "./PaymentReceivedEditInEdit";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTextField2 from "../../general-components/CustomTextField2.component";

export default function PaymentReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [payCheck, setPayCheck] = useState(true);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(3);
  const [iNo, setINo] = useState("");
  const [amt, setAmt] = useState("");
  const [date, setDate] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentTermsData, setPaymentsTermsData] = useState([]);
  const [currencyTypeData, setCurrencyTypeData] = useState([]);
  const [paymentRecivedData, setPaymentRecivedData] = useState([]);
  const [paymentTermsId, setPaymentsTermsId] = useState("");
  const [currencyTypeId, setCurrencyTypeId] = useState("");
  const [paymentRecivedId, setPaymentRecivedId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [updatePopup1, setUpdatePopup1] = useState(false);

  const [recordView, setRecordView] = useState("");
  const [recordEdit, setRecordEdit] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [prlClose, setPrlClose] = useState(false);
  const [poAmt, setPoAmt] = useState("")
  const [remainAmt, setRemainAmt] = useState(0)
  const [disableAddRow, setDisableAddRow] = useState(false);
  const [error1, setError1] = useState("");
  const [paymentTermsId1, setPaymentsTermsId1] = useState("");
  const [paymentTermsName1, setPaymentsTermsName1] = useState("");
  const [currencyTypeId1, setCurrencyTypeId1] = useState("");
  const [currencyTypeName1, setCurrencyTypeName1] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const token = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive = { searchText: "", isActive: true };

  const [openAccordionIndex1, setOpenAccordionIndex1] = useState(0);
  const [iNo1, setINo1] = useState("");
  const [amt1, setAmt1] = useState("");
  const [date1, setDate1] = useState("");
  const [rows1, setRows1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [paymentTermsData1, setPaymentsTermsData1] = useState([]);
  const [currencyTypeData1, setCurrencyTypeData1] = useState([]);
  const [paymentRecivedData1, setPaymentRecivedData1] = useState([]);
  const [paymentRecivedId1, setPaymentRecivedId1] = useState("");

  const [poAmt1, setPoAmt1] = useState("");
  const [payType1, setPayType1] = useState("");
  const [recordView1, setRecordView1] = useState("");
  const [recordEdit1, setRecordEdit1] = useState("");
  const [selectedRecord1, setSelectedRecord1] = useState(null);

  const [currentPage1, setCurrentPage1] = useState(1);
  const recordsPerPage1 = 5;
  const [totalAmt1, setTotalAmt1] = useState("");
  const [existingTotalAmt, setExistingTotalAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate1(currentDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const initialPoAmt = selectedValue.pO_Amount;
      const existingPayments = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail || [];

      // Calculate total of existing received payments
      const totalReceivedFromRecords = existingPayments.reduce(
        (sum, record) => sum + (parseFloat(record.amount) || 0),
        0
      );

      setPoAmt1(initialPoAmt);
      setExistingTotalAmt(totalReceivedFromRecords);
      setRemainAmt(initialPoAmt - totalReceivedFromRecords);

      // Check if the remaining amount is 0 based on existing records
      if (initialPoAmt - totalReceivedFromRecords === 0) {
        setIsFieldsDisabled(true);
        // toast.info("All payments are received; fields are disabled.", { autoClose: 1000 });
      } else {
        setIsFieldsDisabled(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      setPrlClose(selectedValue.plR_IsPaymentOrLCClosed)
    }
  }, [selectedValue]);

  const fetchData = async (url, setData, errorMsg) => {
    setIsLoading(true);
    try {
      const response = await axios.post(url, dataActive, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const dataActive1 = { searchText: "", isActive: true };

  const fetchData1 = async (url, setData, errorMsg) => {
    setIsLoading1(true);
    try {
      const response = await axios.post(url, dataActive1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData, "Error fetching Payment Terms Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData, "Error fetching Currency Type Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData, "Error fetching Payment Received Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData1, "Error fetching Payment Terms Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData1, "Error fetching Currency Type Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData1, "Error fetching Payment Received Data");
  }, []);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };


  useEffect(() => {
    if (selectedValue) {
      setPoAmt(selectedValue.pO_Amount);
      // setRemainAmt(selectedValue.pO_Amount);
    }
  }, [selectedValue])

  const isAddDisabled = totalAmt >= poAmt1 && remainAmt <= 0;

  useEffect(() => {
    if (selectedValue) {
      setCurrencyTypeId1(selectedValue.pO_CurrencyTypeId);
      setCurrencyTypeName1(selectedValue.pO_CurrencyType);
      setPaymentsTermsId1(selectedValue.pO_PaymentTermsId);
      setPaymentsTermsName1(selectedValue.pO_PaymentTerms);
    }
  }, [selectedValue])

  const formatDateTest1 = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options1 = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate1 = date.toLocaleString('en-US', options1);
    return formattedDate1;
  };

  const handleViewButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordView1(true);
  };

  const handleEditButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordEdit1(true);
  };

  const totalRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages1 = Math.ceil(totalRecords1 / recordsPerPage1);

  const startRecordIndex1 = (currentPage1 - 1) * recordsPerPage1;
  const endRecordIndex1 = startRecordIndex1 + recordsPerPage1;
  const currentRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex1, endRecordIndex1) || [];

  const handleNextPage1 = () => {
    setCurrentPage1((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage1 = () => {
    setCurrentPage1((prev) => Math.max(prev - 1, 1));
  };

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleViewButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordView(true);
  };

  const handleEditButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordEdit(true);
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const currentRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex, endRecordIndex) || [];
  const [prlCloseAll, setPlrCloseAll] = useState(false);

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteRecordPayment = async (id) => {
    if (isDeleting) return; // Prevent multiple calls
    setIsDeleting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePaymentReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            paymentReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));

        // toast.success('Record deleted successfully', { autoClose: 5000 });
      } else {
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      toast.error('Error deleting the record');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAmt1Change = (value) => {
    const newReceivedAmt = parseFloat(value) || 0;
    let errorMessage = "";

    if (newReceivedAmt < 0) {
      errorMessage = "Payment received cannot be negative.";
    } else if (newReceivedAmt + existingTotalAmt > poAmt1) {
      errorMessage = "Total payment received cannot exceed the PO amount.";
    }

    if (errorMessage) {
      setError1(errorMessage);
      return;
    }

    setError1("");
    setAmt1(newReceivedAmt);
    setTotalAmt(existingTotalAmt + newReceivedAmt);
    setRemainAmt(poAmt1 - (existingTotalAmt + newReceivedAmt));
  };

  const [invoiceError, setInvoiceError] = useState("");
  const [paymentTypeError, setPaymentTypeError] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();

    // Check if the value contains only alphanumeric characters
    if (/^[a-zA-Z0-9]*$/.test(newValue)) {
      setInvoiceError('');
      setINo1(newValue);
    } else {
      setInvoiceError('Only alphanumeric characters are allowed');
    }
  };

  const token1 = localStorage.getItem('DIYAN_IE_authToken');

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // Reset errors
    setInvoiceError("");
    setPaymentTypeError("");

    // Determine if all fields are empty
    const isAllEmpty = !iNo1 && !amt1 && !paymentRecivedId1;

    // Validate fields only if not all fields are empty
    let hasError = false;
    if (!isAllEmpty && !iNo1) {
      setInvoiceError("Invoice Number is required");
      hasError = true;
    }
    if (!isAllEmpty && !paymentRecivedId1) {
      setPaymentTypeError("Payment Type is required");
      hasError = true;
    }

    if (hasError) {
      setUpdatePopup1(false);
      return; // Prevent submission if there are errors
    }

    setIsLoading1(true);

    try {
      // Construct paymentReceivedDetail only if there's at least one field with a non-default value
      const paymentReceivedDetail = [];
      if (!isAllEmpty) {
        paymentReceivedDetail.push({
          id: 0,
          invoiceNumber: iNo1 || "",
          paymentTermsId: paymentTermsId1 || 0,
          paymentReceivedDate: date1 || "",
          currencyTypeId: currencyTypeId1 || 0,
          amount: amt1 || 0,
          paymentReceivedId: paymentRecivedId1 || 0,
          poAmount: poAmt1 || 0,
          remainingAmount: remainAmt || 0,
          totalReceivedAmount: totalAmt || 0,
        });
      }

      const requestBody1 = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: [],
        },
      };

      console.log("Request Body: ", requestBody1);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token1}`,
        },
        body: JSON.stringify(requestBody1),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup1(false);
        setTimeout(() => {
          onCancel(); // Reload or navigate away
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading1(false);
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Payment Received / LC Received</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex === 0}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex === 1}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex === 2}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Payment Received / LC Received"}
            isOpen={openAccordionIndex === 3}
            onClick={() => handleAccordionClick(3)}
          >
            <div className="mt-2 flex gap-4 items-center justify-between">
              <CustomCheckBox3
                label={"Payment Received"}
                state={payCheck}
                setState={setPayCheck}
                disabled
              ></CustomCheckBox3>

              {/* // Message Display */}
              {isFieldsDisabled && (
                <div className=" text-center text-green-500 font-bold text-md uppercase">
                  All payments are received, further edits are disabled.
                </div>
              )}
            </div>
            {/* <EditPayment /> */}
            <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
              <div className="">
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Currency Type
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: currencyTypeId1, label: currencyTypeName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <CustomTextField2 label="PO Amount" value={poAmt1} readOnly isRequired={false} />
              </div>
              <div>
                <CustomInputNumber label="Total Received Amount" value={totalAmt} readOnly isRequired={false} />
              </div>
              <div>
                <CustomInputNumber label="Remaining Amount" value={remainAmt} readOnly isRequired={false} />
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label className="text-xs font-400">
                    Payment Terms
                  </label>
                  <Select
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    value={{ value: paymentTermsId1, label: paymentTermsName1 }} // Set initial value here
                    menuPosition="fixed"
                    isDisabled={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                      },
                    })}
                  />
                </div>
              </div>
              <div className="">
                <CustomDate label={"Payment received Date"} placeholder={"Enter"}
                  value={date1}
                  onChange={setDate1}
                  isDisabled={isFieldsDisabled} // Disable based on the condition
                />
              </div>
              <div>
                {/* <CustomAlphatext
                  label={"Invoice Number"}
                  placeholder={"Enter"}
                  value={iNo1}
                  onChange={setINo1}
                /> */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-[400]">
                    Invoice Number<span className="text-red-500 gap-3">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={iNo1}
                    className={`p-2 border rounded grow min-w-[14rem] text-xs placeholder:text-xs uppercase ${isFieldsDisabled ? ' bg-gray-100 text-gray-500' : 'text-black bg-white'}`}
                    id="id"
                    placeholder={"Enter"}
                    onChange={handleChange}
                    disabled={isFieldsDisabled} // Disable based on the condition
                  />
                  {invoiceError && <span className="text-red-500 text-xs">{invoiceError}</span>}
                </div>
              </div>
              <div className="">
                {/* <CustomSelect1 label={"Payment Type"}
                  options={paymentRecivedData1.map(cData => ({
                    value: cData.id,
                    label: cData.paymentReceived
                  }))}
                  // onChange={(option) => setPaymentRecivedId1(option.value)}
                  onChange={(option) => setPaymentRecivedId1(option ? option.value : "")}
                /> */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="id" className="text-xs font-400">
                    Payment Type<span className="text-red-500 gap-3">*</span>
                  </label>
                  <Select
                    options={paymentRecivedData1.map((cData) => ({
                      value: cData.id,
                      label: cData.paymentReceived,
                    }))}
                    onChange={(option) => setPaymentRecivedId1(option ? option.value : "")}
                    className="text-black text-xs select-bar uppercase"
                    id="id"
                    isSearchable
                    isClearable
                    menuPosition={"fixed"}
                    closeMenuOnSelect={true}
                    isDisabled={isFieldsDisabled} // Disable based on the condition
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: "#e5e7eb",
                      },
                    })}
                  />
                  {paymentTypeError && <span className="text-red-500 text-xs">{paymentTypeError}</span>}
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="amt1" className="text-xs font-[400]">Payment Received</label>
                  <input
                    type="tel"
                    className={`p-2 border rounded grow min-w-[14rem] text-xs ${isFieldsDisabled ? ' bg-gray-100 text-gray-500' : 'text-black bg-white'} ${error1 ? 'border-red-500' : ''}`}
                    id="amt1"
                    value={amt1}
                    disabled={isFieldsDisabled} // Disable based on the condition
                    placeholder="Enter"
                    onChange={(e) => handleAmt1Change(e.target.value)}
                  />
                  {error1 && <span className="text-red-500 text-xs">{error1}</span>}
                </div>
              </div>
            </div>

            <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
              <table className="w-full custom-table" border={1}>
                <thead>
                  <tr className="table-heading">
                    <td className="">S.No.</td>
                    <td>Action</td>
                    <td>Currency Type</td>
                    <td>PO Amount</td>
                    <td>Total Received Amount</td>
                    <td>Remaining Amount</td>
                    <td>Payment Terms</td>
                    <td>Payment received Date</td>
                    <td>Invoice Number</td>
                    <td>Payment Type</td>
                    <td>Payment Received</td>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentRecords1.length > 0 ? (
                    currentRecords1.map((item, index) => (
                      <tr key={index}>
                        <td className="text-xs">{startRecordIndex1 + index + 1}</td>
                        <td className="text-xs">
                          <div className="gap-2">
                            <Tooltip title="EDIT" arrow placement="bottom">
                              <span>
                                <CustomButton1
                                  className="bg-sixt text-white grow max-w-[50px]"
                                  icon={<BiEdit />}
                                  onClick={() => handleEditButtonClick1(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="VIEW" arrow placement="bottom">
                              <span>
                                <CustomButton4
                                  className="bg-eye text-white grow max-w-[50px]"
                                  icon={<BsEyeFill />}
                                  onClick={() => handleViewButtonClick1(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="DELETE" arrow placement="bottom">
                              <span>
                                <Button
                                  variant={"contained"}
                                  size={"small"}
                                  className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                  type={"button"}
                                  onClick={() => handleDeleteRecordPayment(item.id)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </span>
                            </Tooltip>

                          </div>
                        </td>
                        <td className="text-xs">{item.currencyType}</td>
                        <td className="text-xs">{item.poAmount}</td>
                        <td className="text-xs">{item.totalReceivedAmount}</td>
                        <td className="text-xs">{item.remainingAmount}</td>
                        <td className="text-xs">{item.paymentTerms}</td>
                        <td className="text-xs">{formatDateTest1(item.paymentReceivedDate)}</td>
                        <td className="text-xs">{item.invoiceNumber}</td>
                        <td className="text-xs">{item.paymentReceived}</td>
                        <td className="text-xs">{item.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-xs text-center p-2 ">No Payment received details available.</div>
                  )}
                </tbody>
              </table>

            </div>

            <div className="flex items-center justify-between mt-5">
              <div className="text-sm text-black font-normal">
                Displaying {startRecordIndex1 + 1} to {Math.min(endRecordIndex1, totalRecords1)} of {totalRecords1} records
              </div>

              <div className="flex gap-2">
                <div className="flex items-center">
                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                    onClick={handlePrevPage1}
                    disabled={currentPage1 === 1}
                  >
                    Previous
                  </button>

                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                    onClick={handleNextPage1}
                    disabled={currentPage1 === totalPages1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <CustomCheckBox3
                label={"Payment / LC Closed"}
                state={prlCloseAll}
                setState={setPlrCloseAll}
              ></CustomCheckBox3>
            </div>
          </Accordion>
        </div>

        <div className="flex justify-center gap-5 pt-3">
          <div onClick={() => setUpdatePopup1(true)}>
            <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
          </div>
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>

      {recordEdit1 && (
        <PopUp>
          <PaymentReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord1}
            onCancel={() => {
              setRecordEdit1(false)
            }}
          />
        </PopUp>
      )}

      {recordView1 && (
        <PopUp>
          <PaymentReceivedEditInView
            record={selectedRecord1}
            onCancel={() => setRecordView1(false)}
          />
        </PopUp>
      )}

      {updatePopup1 && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit1}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup1(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />

    </>
  )
}
