// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LeadAccept({ onCancel, customerId }) {
    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSuccess = async (e) => {
        // e.preventDefault();
        // toast.success("Successfully Accepted", {
        //     onClose: () => onCancel() // Close the popup after toast is closed
        // });
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                customerId: customerId,
                leadStatusId: 2
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/CustomerApproveNReject`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Successfully Accepted");
                setTimeout(() => {
                    window.location.reload(); // Reload the page or fetch updated list
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Accepted Lead operation:", error);
            toast.error("Error performing Accepted Lead operation");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    <div className="p-6 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h3 className="mb-5 text-lg font-semibol">Are you sure you want to accept this customer?</h3>
                        <div className="flex justify-center gap-4 pt-4">
                            <div onClick={handleSuccess}>
                                <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                            </div>
                            <div onClick={onCancel}>
                                <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}
