// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomSelect2 from "../../general-components/CustomSelect2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PendingLeadsEdit({ selectedValue, onCancel }) {
  const [cName, setCName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState([]);
  const [contactName, setContactName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [pId, setPId] = useState("");
  const [landlineNumber, setLandLineNumber] = useState("");
  const [cTypeId, setCTypeId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive = { searchText: "", isActive: true };

  const fetchDeptApiData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, dataActive, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        const sortedData = response.data.data;
        setCountryData(sortedData);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error("Error fetching Country Data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDeptApiData();
  }, [])

  const handlePaperTypeChange = (selectedOption) => {
    if (selectedOption) {
      setCountryId(selectedOption.value);
      setCountryName(selectedOption.label); // Update the department name label
    } else {
      setCountryId(null); // Reset to null when cleared
      setCountryName(''); // Clear the department name label
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        id: selectedValue.id,
        moduleType: "Lead",
        customerName: cName,
        mobileNo: mobile,
        parentCustomerId: pId,
        landlineNumber: landlineNumber,
        customerTypeId: cTypeId,
        emailId: email,
        countryId: countryId,
        contactName: contactName,
        leadStatusId: leadId,
        isActive: true,
        contactDetailsList: [],
        billingDetailsList: [],
        shippingDetailsList: []
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {

        console.log("Record Updated successfully:", responseData.message);
        toast.success("Record Updated successfully");

        // console.log("Record Inserted successfully:", responseData.message);
        setTimeout(() => {
          onCancel()
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing All Lead operation:", error);
      toast.error("Error performing All Lead operation");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedValue) {
      setCName(selectedValue.customerName);
      setCountryId(selectedValue.countryId);
      setCountryName(selectedValue.countryName);
      setContactName(selectedValue.contactName);
      setMobile(selectedValue.mobileNo);
      setEmail(selectedValue.emailId);
      setPId(selectedValue.parentCustomerId);
      setLandLineNumber(selectedValue.landlineNumber);
      setCTypeId(selectedValue.customerTypeId);
      setLeadId(selectedValue.leadStatusId);
    }
  }, [selectedValue])

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[300]">Edit Pending Leads</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-2 mx-auto">
            <table className="max-w-full popup-table w-[900px]">
              <tbody>
                <tr>
                  <td>
                    <CustomTextField2
                      label={"Customer Name"}
                      placeholder={"ENTER"}
                      value={cName}
                      onChange={setCName}
                    ></CustomTextField2>
                  </td>
                  <td>
                    <CustomSelect2
                      label={"Country Name"}
                      placeholder={"Select..."}
                      options={countryData.map(depData => ({
                        value: depData.id,
                        label: depData.countryName
                      }))}
                      value={{ value: countryId, label: countryName }} // Set initial value here
                      onChange={handlePaperTypeChange}
                      isClearable // Pass isClearable prop for conditional rendering
                    />
                  </td>
                  <td>
                    <CustomTextField2
                      label={"Contact Name"}
                      placeholder={"ENTER"}
                      value={contactName}
                      onChange={setContactName}
                    ></CustomTextField2>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomNumber
                      label={"Mobile #"}
                      placeholder={"ENTER"}
                      value={mobile}
                      onChange={setMobile}
                    ></CustomNumber>
                  </td>
                  <td>
                    <CustomEmail
                      label={"Email Id"}
                      placeholder={"ENTER"}
                      value={email}
                      onChange={setEmail}
                    ></CustomEmail>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-center gap-5 pt-3">
            <div>
              <CustomButton1 label={isLoading ? "Loading..." : "Update"} className="text-white bg-prp-color" type="submit" />
            </div>
            <div onClick={onCancel}>
              <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
            </div>
          </div>
        </form>

      </div>
      <ToastContainer autoClose={2000} />
    </>
  )
}
