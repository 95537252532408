// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import PopUp from "../../general-components/PopUp.componenet";
import { Tooltip } from "@mui/material";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";

export default function ManageInvoiceGeneratedEdit({ onCancel, selectedValue }) {
    const [checkboxEdit, setCheckboxEdit] = useState(true);
    const [checkboxPi, setCheckboxPi] = useState(true);
    const [openAccordionIndex, setOpenAccordionIndex] = useState(8);
    const [cuLoadClose, setCuLoadClose] = useState(false)
    const [orderDate, setOrderDate] = useState("");
    const [updatePopup, setUpdatePopup] = useState(false);
    const [errorMessages, setErrorMessages] = useState({ main: '', rows: [] });

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    // const [checkboxPi, setCheckboxPi] = useState(false);
    const [showRows, setShowRows] = useState(false);
    const [rows, setRows] = useState([]);
    const [text, setText] = useState("");
    const [conName, setConName] = useState("");
    // const [conAttach, setConAttach] = useState("");
    const [date, setDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [conAttach, setConAttach] = useState("");
    const [mainConAttach, setMainConAttach] = useState("");

    // Modify the handleConAttachChange function
    const handleMainConAttachChange = (files) => {
        setMainConAttach(files);
    };

    useEffect(() => {
        if (selectedValue && selectedValue.invoiceList) {
            const initialRows = selectedValue.invoiceList.map(invoice => ({
                id: invoice.id,
                conName: invoice.invoiceNumber,
                conAttach: {
                    name: invoice.invoiceOriginalFileName,
                    base64: "", // Add logic to convert the image URL to base64 if needed
                    imageURL: invoice.invoiceImageURL,
                }
            }));
            setRows(initialRows);
        }

        if (selectedValue) {
            setCuLoadClose(selectedValue?.iN_IsInvoiceGenerateClose);
            setOrderDate(selectedValue?.iN_InvoiceGenerateClosedDate)
        }
    }, [selectedValue]);


    const handleAddRow = () => {
        const newRow = { id: 0, conName: '', conAttach: '' }
        setRows([...rows, newRow]);
        setErrorMessages(prev => ({ ...prev, rows: [...prev.rows, ''] }));
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
    }

    const handleRowChange = (index, value) => {
        const updatedRows = rows.map((row, rowIndex) =>
            rowIndex === index ? { ...row, conName: value || "" } : row
        );
        setRows(updatedRows);

        // Clear error message for this row if input is valid
        const updatedErrors = errorMessages.rows.map((msg, rowIndex) =>
            rowIndex === index && value?.trim() ? '' : msg
        );
        setErrorMessages(prev => ({ ...prev, rows: updatedErrors }));
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setOrderDate(currentDate);
    }, []);

    const handleChange = (event) => {
        const value = event.target.value.toUpperCase();

        if (/^[a-zA-Z0-9/-]*$/.test(value)) {
            setErrorMessages(prev => ({ ...prev, main: '' })); // Clear error for main input
            setConName(value);
        } else {
            setErrorMessages(prev => ({ ...prev, main: 'Only text, digits, slashes, and hyphens are allowed' }));
        }
    };

    const handleConAttachChange = (index, files) => {
        console.log("Files received in handleConAttachChange:", files);
        setRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows[index].conAttach = files;
            console.log("Updated rows in handleConAttachChange:", updatedRows);
            return updatedRows;
        });
    };

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let hasError = false;

        // Check if both main invoice number and main attachment are empty
        const isMainEmpty = !conName.trim() && !mainConAttach;

        // Validate main invoice number only if not empty
        if (!isMainEmpty && !conName.trim()) {
            setErrorMessages((prev) => ({ ...prev, main: "Invoice Number is required" }));
            hasError = true;
        } else {
            setErrorMessages((prev) => ({ ...prev, main: "" })); // Clear error
        }

        // Validate main attachment only if not empty
        if (!isMainEmpty && !mainConAttach) {
            setErrorMessages((prev) => ({ ...prev, mainAttachment: "Attachment is required" }));
            hasError = true;
        } else {
            setErrorMessages((prev) => ({ ...prev, mainAttachment: "" })); // Clear error
        }

        // Validate each row's invoice number and attachment
        const rowErrors = rows.map((row) => {
            if (!row.conName?.trim() && !row.conAttach) {
                // Skip error validation for completely empty rows
                return { conName: "", conAttach: "" };
            }

            return {
                conName: !row.conName?.trim() ? "Invoice Number is required" : "",
                conAttach: !row.conAttach ? "Attachment is required" : "",
            };
        });

        setErrorMessages((prev) => ({ ...prev, rows: rowErrors }));

        // Check if any row has errors
        if (rowErrors.some((error) => error.conName || error.conAttach)) {
            hasError = true;
        }

        if (hasError) {
            setUpdatePopup(false);
            return;
        }

        try {
            const filteredRows = rows.filter(row => row.conName || row.conAttach);

            const rowsWithDynamicIds = filteredRows.map((row, index) => ({
                id: row.id || 0,
                invoiceNumber: String(row.conName || 0), // Ensure this is a string
                invoiceImage: "",
                invoiceOriginalFileName: row.conAttach.name || "",
                invoice_Base64: row.conAttach.base64 || ""
            }));

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftComment: "",
                invoiceList: [
                    // Main invoice data (ensure it's included only if valid)
                    ...(isMainEmpty
                        ? [] // Skip adding main invoice and attachment if both are empty
                        : [
                            {
                                id: 0,
                                invoiceNumber: conName || 0,
                                invoiceImage: "",
                                invoiceOriginalFileName: mainConAttach?.name || "",
                                invoice_Base64: mainConAttach?.base64 || "",
                            },
                        ]),
                    ...rowsWithDynamicIds,
                ],
                iN_IsInvoiceGenerateClose: cuLoadClose,
                iN_InvoiceGenerateClosedDate: orderDate
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteRecord = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteInvoice?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Record deleted successfully');
                // Remove the row from the UI
                const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
                setRows(updatedRows);
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Invoice Generated</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex === 7}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex === 8}
                        onClick={() => handleAccordionClick(8)}
                    >
                        {/* <EditInvoiceGenerated /> */}
                        <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">

                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-xs font-[400] uppercase">
                                        Invoice Number<span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        value={conName}
                                        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                        id="id"
                                        placeholder={""}
                                        onChange={handleChange}
                                    />
                                    {errorMessages.main && <span className="text-red-500 text-xs">{errorMessages.main}</span>}
                                </div>
                            </div>
                            <div className="">
                                <CustomFileUpload
                                    label="Invoice Generated Upload"
                                    id={`poissuede433345345dit-edit-0`}
                                    value={mainConAttach}
                                    onChange={handleMainConAttachChange}
                                />
                                {errorMessages.mainAttachment && (
                                    <span className="text-red-500 text-xs">{errorMessages.mainAttachment}</span>
                                )}
                            </div>
                            <div className="flex items-end">
                                <Tooltip title="ADD" arrow placement="bottom">
                                    <span>
                                        <button onClick={handleAddRow}>
                                            <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                                        </button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>

                        {rows.map((row, index) => (
                            <div key={index} className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px] mt-5">
                                <div>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor={`row-${index}`} className="text-xs font-[400] uppercase">
                                            Invoice Number<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={row.conName || ""}
                                            className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                            id={`row-${index}`}
                                            placeholder={""}
                                            onChange={(e) => handleRowChange(index, e.target.value)}
                                        />
                                        {errorMessages.rows[index]?.conName && (
                                            <span className="text-red-500 text-xs">{errorMessages.rows[index].conName}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="">
                                    {row.conAttach.imageURL ? (
                                        <CustomFileUpload
                                            id={`customimgupload-${index}`}
                                            label="Invoice Generated Upload"
                                            value={row.conAttach}
                                            onChange={(files) => handleConAttachChange(index, files)}
                                            initialFileName={row.conAttach.name}
                                            initialFileUrl={row.conAttach.imageURL}
                                        />
                                    ) : (
                                        <CustomFileUpload
                                            label="Invoice Generated Upload"
                                            id={`customimageupload-${index}`}
                                            value={row.conAttach}
                                            onChange={(files) => handleConAttachChange(index, files)}
                                        />
                                    )}
                                    {errorMessages.rows[index]?.conAttach && (
                                        <span className="text-red-500 text-xs">{errorMessages.rows[index].conAttach}</span>
                                    )}
                                </div>
                                {row.id === 0 ? (
                                    <div className="flex">
                                        <Tooltip title="REMOVE" arrow placement="bottom">
                                            <span>
                                                <button onClick={() => handleRemoveRow(index)} className="pt-4">
                                                    <CiSquareRemove className="h-10 w-9 txt-prp-color " />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div>
                                        <Tooltip title="DELETE" arrow placement="bottom">
                                            <span>
                                                <button onClick={() => handleDeleteRecord(index, row.id)} className="pt-4 ">
                                                    <RiDeleteBin6Line className="h-7 w-7 text-red-500" />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>

                                )}
                            </div>
                        ))}

                        <div className="mt-5 max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Invoice Generated Close Date
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="">
                                <CustomCheckBox2
                                    label={"Invoice Generated Close"}
                                    state={cuLoadClose}
                                    setState={setCuLoadClose}
                                ></CustomCheckBox2>
                            </div>

                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
            <ToastContainer autoClose={2000} />
        </>
    )
}
