// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

export default function AllLeadsView({ onCancel, selectedValue }) {
  // Use optional chaining and provide default values to avoid null/undefined access errors
  const customerName = selectedValue?.customerName ?? 'N/A';
  const countryName = selectedValue?.countryName ?? 'N/A';
  const mobileNo = selectedValue?.mobileNo ?? 'N/A';
  const emailId = selectedValue?.emailId ?? 'N/A';
  const isActive = selectedValue?.isActive ? 'Active' : 'Inactive';
  const createdDate = selectedValue?.createdDate ? new Date(selectedValue.createdDate).toLocaleDateString('en-GB') : 'N/A';
  const creatorName = selectedValue?.creatorName ?? 'N/A';
  const leadStatus = selectedValue?.leadStatus ?? 'N/A';

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
          <h1 className="text-xl font-weight-[400]">View All Leads </h1>
        </div>
        <div className=" mx-auto">
          <div className="grid grid-cols-2 gap-2 p-5 gap-x-4 gap-y-3 md:grid-cols-3 max-h-[70vh] overflow-y-auto hide-scrollbar">
            <CustomViewLabel label="Customer Name" value={customerName} />
            <CustomViewLabel label="Country Name" value={countryName} />
            <CustomViewLabel label="Mobile #" value={mobileNo} />
            <CustomViewLabel label="Email Id" value={emailId} />
            <CustomViewLabel label="Status" value={leadStatus} />
            <CustomViewLabel label="Created Date" value={createdDate} />
            <CustomViewLabel label="Created By" value={creatorName} />
          </div>
        </div>
        <div className="flex justify-center gap-5 pt-3">
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>
    </>
  );
}
