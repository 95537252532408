import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom';
import Admin from './Admin.page';

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const setData = () => {
        const authenticated = localStorage.getItem('DIYAN_IE_authToken');
        setIsAuthenticated(Boolean(authenticated));
        if (authenticated) {
            const authenticatedUser = JSON.parse(localStorage.getItem('user'));
            setUser(authenticatedUser);
        }
    }

    const loadToken = async () => {
        setData();
        setLoading(false)
    }

    useEffect(() => {
        loadToken()
    }, [])

    const login = (data) => {
        localStorage.setItem("user", JSON.stringify({ ...data, userRoleList: data.userRoleList.filter(ele => ele.appType === "W") }));
        setUser(data);
        setIsAuthenticated(true);
    }

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("user");
        localStorage.removeItem("DIYAN_IE_username");
        localStorage.removeItem("activeTabDiyan");
        localStorage.removeItem("diyanActiveTrackingStatusTab");
        localStorage.removeItem("diyanCustomerTracking");
    }

    const contextData = useMemo(() => ({
        loading,
        user,
        isAuthenticated,
        login,
        logout,
    }), [loading, user, isAuthenticated, login, logout])
    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}
export const useAuth = () => useContext(AuthContext)
export const ProtectRoute = ({
    redirectPath = '/login',
    children,
}) => {
    const { isAuthenticated, loading } = useAuth();
    if (loading) {
        return ""
    }
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />
    }
    return (<Admin>
        {children}
    </Admin>)
}









