import * as React from "react";
import { useState } from "react";

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomPassword from "../../general-components/CustomPassword.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginCredentialsAddPopup({ currentCustomerId, onCancel }) {

    const [mobile, setMobile] = useState('');
    const [uname, setUname] = useState('');
    const [password, setPassword] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [cNameError, setCNameError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const validateMobile = (value) => {
        if (!value) {
            setMobileError('Mobile number is required');
            return false;
        } else if (!/^[0-9\b]+$/.test(value)) {
            setMobileError('Mobile number only allows numbers');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const validateCName = (value) => {
        if (!value) {
            setCNameError('Contact Name is required');
            return false;
        } else {
            setCNameError('');
            return true;
        }
    };

    const handleChangeMobile = (value) => {
        setMobile(value);
        validateMobile(value);
        checkFormValidity(value, uname);
    };

    const handleChangeUname = (value) => {
        setUname(value);
        validateCName(value);
        checkFormValidity(mobile, value);
    };

    const checkFormValidity = (mobile, uname) => {
        setIsFormValid(validateMobile(mobile) && validateCName(uname));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const isMobileValid = validateMobile(mobile);
        const isNameValid = validateCName(uname);

        if (!isMobileValid || !isNameValid) {
            setIsLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem('DIYAN_IE_authToken');
            const requestBody = {
                customerId: currentCustomerId,
                contactName: uname,
                username: mobile,
                passwords: password,
                isActive: checkbox,
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveLoginCredentials`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === 'Record is already exists') {
                    toast.error('Duplicate entry is not permissible');
                } else {
                    toast.success('Record inserted successfully');
                }
                setTimeout(() => {
                    onCancel();
                }, 2000);
            } else {
                console.error('Record operation failed', responseData.message);
                toast.error(responseData.message || 'Record operation failed');
            }
        } catch (error) {
            console.error('Error performing Edit Shipping operation:', error);
            toast.error('Error performing Edit Shipping operation');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Add Login Credentials</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="uname" className="text-xs font-[400]">
                                            Contact Name<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={uname}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                                            id="uname"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeUname(e.target.value)}
                                            onBlur={(e) => validateCName(e.target.value)}
                                        />
                                        {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Mobile Number<span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            required
                                            value={mobile}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={(e) => handleChangeMobile(e.target.value)}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomPassword
                                        label={"Password"}
                                        placeholder={"ENTER"}
                                        value={password}
                                        onChange={setPassword}
                                    ></CustomPassword>
                                </td>
                                <td>
                                    <CustomCheckBox2
                                        label={"Is Active"}
                                        state={checkbox}
                                        setState={setCheckbox}
                                    ></CustomCheckBox2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Submit"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
