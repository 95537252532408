import React, { useEffect, useState } from 'react';
import { ImEnlarge } from "react-icons/im";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "../../assets/css/selectbar.css";
import CustomButton1 from './CustomButton1.component';
import CustomButton2 from './CustomButton2.component';

function CustomImageUploadEmpAdd(props) {
    const { id, label, isRequired, readOnly, value, onChange } = props;
    const [selectedImage, setSelectedImage] = useState(value?.base64);
    const [imageName, setImageName] = useState(value?.name);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // Confirmation modal state
    const [uploadError, setUploadError] = useState("");
    const [uploadMessage, setUploadMessage] = useState("");

    useEffect(() => {
        if (value) {
            setSelectedImage(value.base64);
            setImageName(value.name);
        } else {
            setSelectedImage(null);
            setImageName('');
        }
    }, [value]);

    useEffect(() => {
        if (uploadError) {
            const errorTimeout = setTimeout(() => {
                setUploadError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [uploadError]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleImageUpload = (event) => {
        const uploadedImage = event.target.files[0];
        const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg'];

        if (uploadedImage && allowedExtensions.includes(uploadedImage.type)) {
            if (uploadedImage.size <= 10 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    let base64String = reader.result;
                    base64String = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''); // Remove the prefix
                    setSelectedImage(base64String);
                    setImageName(uploadedImage.name);
                    onChange({ base64: base64String, name: uploadedImage.name });
                    setUploadError("");
                    setUploadMessage("Uploaded.");
                    setTimeout(() => {
                        setUploadMessage("");
                    }, 5000);
                };
                reader.readAsDataURL(uploadedImage);
            } else {
                setUploadError('File size is more than 10 MB.');
                setSelectedImage(null);
                setImageName('');
                onChange(null);
            }
        } else {
            setUploadError('jpg, jpeg & png images are allowed.');
            setSelectedImage(null);
            setImageName('');
            onChange(null);
        }
    };

    const handleDownloadImage = () => {
        setIsConfirmModalOpen(true); // Open confirmation modal before downloading
    };

    const confirmDownload = () => {
        if (selectedImage) {
            const link = document.createElement('a');
            link.href = `data:image/png;base64,${selectedImage}`;
            link.setAttribute('download', imageName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setIsConfirmModalOpen(false); // Close confirmation modal
    };

    const handleCancelUpload = () => {
        setSelectedImage(null);
        setImageName('');
        onChange(null);
        document.getElementById(`imageUpload-${id}`).value = "";
    };

    return (
        <div className='flex flex-col gap-1 relative uppercase'>
            <label htmlFor={id} className="text-xs font-[400]">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>
            <div className="relative flex flex-col gap-1">
                <div className="flex justify-between items-center gap-2">
                    <div>
                        <label
                            htmlFor={`imageUpload-${id}`}
                            className="cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"
                        >
                            Attachment
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            id={`imageUpload-${id}`}
                            className="hidden"
                            onChange={handleImageUpload}
                        />
                    </div>
                    <div>
                        {selectedImage && (
                            <div className="flex items-center space-x-1">
                                <button onClick={handleDownloadImage} type='button' className="bg-prp-color text-white p-1 rounded">
                                    <FaDownload />
                                </button>
                                <button onClick={handleCancelUpload} type='button' className="bg-white txt-prp-color border border-prp p-1 rounded">
                                    <MdCancel />
                                </button>
                                <button onClick={handleOpenModal} type='button' className="bg-prp-color text-white p-1 rounded">
                                    <ImEnlarge />
                                </button>
                            </div>
                        )}
                    </div>
                    <div>
                        {!selectedImage && (
                            <div className="flex w-10 h-10 rounded-full border border-[#3D3D3D66]">
                                <span className="text-[#3D3D3D66] flex items-center justify-center text-[10px] text-center">
                                    No Image
                                </span>
                            </div>
                        )}
                        {selectedImage && (
                            <img
                                src={`data:image/png;base64,${selectedImage}`}
                                alt={imageName}
                                className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Enlarged Image"
                className="modal-img"
                overlayClassName="modal-overlay-img"
            >
                <div className="coman-modal-img">
                    <button onClick={handleDownloadImage} className="modal-btn-comman modal-btn-dwl"><FaDownload /></button>
                    <button onClick={handleCloseModal} className="modal-btn-comman modal-btn-cnl"><MdCancel /></button>
                </div>
                {selectedImage && (
                    <img
                        src={`data:image/png;base64,${selectedImage}`}
                        alt={imageName}
                        className="w-full h-auto"
                    />
                )}
            </Modal>

            {/* Confirmation Modal */}
            <Modal
                isOpen={isConfirmModalOpen}
                onRequestClose={() => setIsConfirmModalOpen(false)}
                contentLabel="Download Confirmation"
                className="modal-img"
                overlayClassName="modal-overlay-img"
            >
                <div className="pb-2 bg-white border rounded-lg">
                    <div className="flex flex-col p-10" style={{ padding: "10px" }}>
                        <p className="pb-5 text-sm" style={{paddingBottom:"10px"}}>Are you sure, you want to download?</p>

                        <div className="coman-modal-img">
                            <div onClick={confirmDownload}>
                                <CustomButton1 label={"Yes"} className="text-white bg-prp-color modal-btn-comman modal-btn-dwl" type="submit" />
                            </div>
                            <div onClick={() => setIsConfirmModalOpen(false)}>
                                <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color modal-btn-comman modal-btn-dwl" />
                            </div>
                        </div>
                    </div>
                </div >
            </Modal>

            {uploadError && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{uploadError}</p>}
            {uploadMessage && (
                <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: "green" }}>
                    {uploadMessage}
                </p>
            )}
        </div>
    );
}

CustomImageUploadEmpAdd.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => { },
};

export default CustomImageUploadEmpAdd;
