// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomTextField2 from "../components/general-components/CustomTextField2.component";
import CustomCheckBox2 from "../components/general-components/CustomCheckBox2.component";

// ICONSbg-white
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineImport } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from "../components/general-components/CustomButton2.component";
import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";
import { Tooltip } from "@mui/material";
import Pagination from "../components/general-components/Pagination.component";

function ManageMaritalStatus() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "ADMIN MARITAL STATUS"))
        }
    }, [user])
    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);
    let [checkboxAdd, setCheckboxAdd] = useState(true);
    let [checkboxEdit, setCheckboxEdit] = useState(true);

    const [addValue, setAddValue] = useState("");
    const [editValue, setEditValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "" };

    const fetchApiData = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetMaritalStatusList`,
                // data,
                { searchText: searchQuery }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });

                setInputData(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Marital Status Data");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupEdit(true);
    };

    const fetchApiDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/AdminMaster/GetMaritalStatusById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                setSelectedValue(response.data.data);
                setEditValue(response.data.data.maritalStatus);
                setCheckboxEdit(response.data.data.isActive);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage Marital Status details");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                maritalStatus: selectedValue ? editValue : addValue,
                isActive: selectedValue ? checkboxEdit : checkboxAdd
            };

            // Include department ID if editing an existing department
            if (selectedValue) {
                requestBody.id = selectedValue.id;
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/AdminMaster/SaveMaritalStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (selectedValue) {
                    console.log("Record Updated successfully:", responseData.message);
                    toast.success("Record Updated successfully");
                } else {
                    // console.log("Record Inserted successfully:", responseData.message);
                    if (responseData.message === "Record is already exists") {
                        toast.error("Duplicate entry is not permissable");
                    } else {
                        toast.success("Record Inserted successfully");
                        setAddValue("");
                    }
                }
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    setPopupAdd(false);
                    setPopupEdit(false);
                    fetchApiData();
                    // window.location.reload(); // Reload the page or fetch updated list
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Marital Status operation:", error);
            toast.error("Error performing Marital Status operation");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchApiData(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Marital Status"} title2={"ADMINISTRATOR"} />

                    <div className=" bg-white rounded grow">
                        <div className="px-2 lg:px-4">
                            {/* 4 BUTTONS */}
                            <div className="flex flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap minbox">
                                {Permission?.add && <CustomButton1
                                    label={"Add "}
                                    icon={<IoMdAdd />}
                                    className="bg-prp-color text-white shrink grow md:grow-0 max-w-[50%]"
                                    onClick={() => setPopupAdd(true)}
                                />}

                                <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                                    <input
                                        type="text"
                                        className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
                                        placeholder="SEARCH"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {searchText ? (
                                        <IoMdCloseCircle
                                            className="min-w-[20px] cursor-pointer txt-prp-color"
                                            onClick={clearSearch}
                                        />
                                    ) : (
                                        <AiOutlineSearch className="min-w-[20px]" />
                                    )}
                                </div>
                            </div>

                            {Permission?.view &&
                                <>
                                    {/* TABLE */}
                                    <div div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                        <table className="w-full custom-table" border={1}>
                                            <thead>
                                                <tr className="table-heading">
                                                    <td className="">S.No</td>
                                                    {(Permission?.edit || Permission?.view) && <td>Action</td>}
                                                    <td>Status</td>
                                                    <td>Marital Status </td>
                                                    <td>Created Date </td>
                                                    <td>Created By </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan="6" className="text-center">Loading...</td>
                                                    </tr>
                                                ) : (paginatedData && paginatedData.length > 0 ? (
                                                    paginatedData.map((cData, index) => (
                                                        <tr key={cData.id}>
                                                            <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                            {Permission?.edit && <td>
                                                                <div className="gap-2">
                                                                    <Tooltip title="EDIT" arrow placement="right">
                                                                        <span>
                                                                            <CustomButton1
                                                                                className="bg-sixt text-white grow max-w-[50px]"
                                                                                icon={<BiEdit />}
                                                                                onClick={() => handleEditButtonClick(cData.id)}
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>}
                                                            <td>
                                                                <div>
                                                                    <h2 style={{ color: cData.isActive ? 'green' : 'red' }}>{cData.isActive ? 'Active' : 'Inactive'}</h2>
                                                                </div>
                                                            </td>
                                                            <td>{cData.maritalStatus}</td>
                                                            <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                                            <td>{cData.creatorName}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    searchText?.trim() ? (
                                                        <tr>
                                                            <td colSpan="6" className="text-center py-4">
                                                                No results found for "{searchText}".
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" className="text-center py-4">
                                                                No data available.
                                                            </td>
                                                        </tr>
                                                    )
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        data={inputData}
                                    />
                                </>
                            }
                        </div>

                        {/* POPUP 1 : ADD */}
                        {popupAdd && (
                            <PopUp>
                                <div className="pb-10 bg-white rounded-lg">
                                    <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                                        <h1 className="text-l font-weight-[400]">Add Marital Status</h1>
                                        <div className="flex items-center text-sm"></div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="p-2 mx-auto">
                                            <table className="max-w-full popup-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <CustomTextField2
                                                                label={"Marital Status"}
                                                                placeholder={"ENTER"}
                                                                value={addValue}
                                                                onChange={setAddValue}
                                                            ></CustomTextField2>
                                                        </td>
                                                        <td>
                                                            <CustomCheckBox2
                                                                label={"Is Active"}
                                                                state={checkboxAdd}
                                                                setState={setCheckboxAdd}
                                                            ></CustomCheckBox2>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="flex justify-center gap-5">
                                            <div>
                                                <CustomButton1 label={isLoading ? "Loading..." : "Submit"} className="text-white bg-prp-color" type="submit" />
                                            </div>
                                            <div onClick={() => setPopupAdd(false)}>
                                                <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </PopUp>
                        )}

                        {/* POPUP 2 : EDIT */}
                        {popupEdit && (
                            <PopUp>
                                <div className="pb-10 bg-white rounded-lg">
                                    <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                                        <h1 className=" font-weight-[400]">Edit Marital Status</h1>
                                        <div className="flex items-center text-sm"></div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="p-5 mx-auto">
                                            <table className="max-w-full popup-table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <CustomTextField2
                                                                label={"Marital Status"}
                                                                placeholder={"ENTER"}
                                                                value={editValue}
                                                                onChange={setEditValue}
                                                            ></CustomTextField2>
                                                        </td>
                                                        <td>
                                                            <CustomCheckBox2
                                                                label={"Is Active"}
                                                                state={checkboxEdit}
                                                                setState={setCheckboxEdit}
                                                            ></CustomCheckBox2>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex justify-center gap-5">
                                            <div>
                                                <CustomButton1 label={isLoading ? "Loading..." : "Update"} className="text-white bg-prp-color" type="submit" />

                                            </div>
                                            <div onClick={() => setPopupEdit(false)}>
                                                <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </PopUp>
                        )}
                    </div>
                </div>
            </div >

            <ToastContainer autoClose={2000} />
        </>
    );
}

export default ManageMaritalStatus;
