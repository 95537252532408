// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomSelect3 from "../components/general-components/CustomSelect3.component";
import CustomButton4 from "../components/general-components/CustomButton4.component";


// ICONSbg-white
import { IoMdCloseCircle, IoMdRefresh } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { IoSearchSharp } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";

import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";

import ManagePaymentReceivedEdit from "../components/forms-components/ManagePaymentReceived/ManagePaymentReceivedEdit";
import ManagePaymentReceivedView from "../components/forms-components/ManagePaymentReceived/ManagePaymentReceivedView";
import ManageDocumentSendEdit from "../components/forms-components/ManageDocumentSendDhlSubmitted/ManageDocumentSendEdit";
import ManageDocumentSendView from "../components/forms-components/ManageDocumentSendDhlSubmitted/ManageDocumentSendView";
import { Tooltip } from "@mui/material";
import Pagination from "../components/general-components/Pagination.component";

const options = [
    { value: 'a', label: 'Lorem Ipsum' },
    { value: 'b', label: 'Lorem Ipsum' },
    { value: 'c', label: 'Lorem Ipsum' },
    { value: 'd', label: 'Lorem Ipsum' }
];

function ManageDocumentSendDhlSubmitted() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE TRACKING > DOCUMENT SEND DHL SUBMITTED"))
        }
    }, [user])

    let [popupEdit, setPopupEdit] = useState(false);
    let [popupView, setPopupView] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState([]);
    const [listData, setListData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "", customerId: 0, countryId: 0, poNumber: "", statusId: 0 };
    const dataPOList = { pageNo: 0, pageSize: 0, searchText: "", isActive: null, customerId: 0, countryId: 0, statusId: 0, trakingStatusId: 0, trakingNumber: "", isPIConfirmation: 0, isPaymentOrLCReceived: 0 };

    const [countryData, setCountryData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [trackingData, setTrackingData] = useState([]);
    const [poIdData, setPoIdData] = useState([]);
    const [countryId, setCountryId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [trackingId, setTrackingId] = useState("");
    const [poId, setPoId] = useState("");

    const fetchApiData = async (updatedDataPOList = dataPOList) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/GetPurchaseOrderList`,
                updatedDataPOList,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                // Sort data in descending order by createdDate
                // const sortedByDate = response.data.data.slice().sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                // setInputData(sortedByDate);

                const sortedByActiveStatus = response.data.data.slice().sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate); // Secondary sorting by date if needed
                    }
                    return a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1;
                });

                setInputData(sortedByActiveStatus);

                // Sort data in ascending order by id
                const sortedById = response.data.data.slice().sort((a, b) => a.id - b.id);
                setPoIdData(sortedById);

                // Set total pages and current page
                setTotalPages(Math.ceil(sortedByActiveStatus.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Purchase Order Tracking List Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                url,
                { isActive: true },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                // Log sorted data to verify
                console.log("Sorted Data:", sortedData);
                setData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(
            `${process.env.REACT_APP_BASE_URL}/AdminMaster/GetTrackingStatusList`,
            setTrackingData,
            "Error fetching Tracking Status Data"
        );
    }, []);

    const createChangeHandler = (setter) => (selectedOption) => {
        if (selectedOption) {
            setter(selectedOption.value);
        } else {
            setter(null);
        }
    };

    const handleCountryChange = createChangeHandler(setCountryId);
    const handleCustomerChange = createChangeHandler(setCustomerId);
    const handleTrackingChange = createChangeHandler(setTrackingId);
    const handlePoIdChange = createChangeHandler(setPoId);

    const handleSearch = () => {
        const updatedDataPOList = {
            ...dataPOList,
            customerId: customerId || 0,
            countryId: countryId || 0,
            trakingStatusId: trackingId || 0,
            trakingNumber: poId || "",
        };

        fetchApiData(updatedDataPOList);
    };

    const handleReset = () => {
        setCountryId("");
        setCustomerId("");
        setTrackingId("");
        setPoId("");
        fetchApiData(dataPOList); // Passing default dataPOList to fetch all records
    };

    console.log("listData--->", listData);

    const handleEditButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupEdit(true);
    };

    const handleViewButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupView(true);
    };

    const fetchApiDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/GetPurchaseOrderById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.isSuccess && response.data.data) {
                const apiData = response.data.data;
                setSelectedValue(apiData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage Purchase Order Tracking details");
        } finally {
            setIsLoading(false);
        }
    };
    // console.log("lallalal-->", piDate);

    const filteredData = inputData.filter(item => {
        if (!searchText.trim()) return true; // Return all items if searchText is empty

        const searchLower = searchText.toLowerCase();
        return (
            (item.trackingNumber?.toLowerCase() || '').includes(searchLower) ||
            (item.customerName?.toLowerCase() || '').includes(searchLower) ||
            (item.parentCustomer?.toLowerCase() || '').includes(searchLower) ||
            (item.countryName?.toLowerCase() || '').includes(searchLower) ||
            (item.pO_PONumber?.toLowerCase() || '').includes(searchLower) ||
            (item.creatorName?.toLowerCase() || '').includes(searchLower)
        );
    });

    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchApiData(dataPOList);
    }, [rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const clearSearch = () => {
        setSearchText("");
    };

    const uniqueBy = (arr, key) => {
        return Array.from(new Map(arr.map(item => [item[key], item])).values());
    };

    const getCountries = () => {
        // Filter out records with empty countryId or countryName
        const filteredData = poIdData.filter(data => data.countryId && data.countryName);

        // Apply uniqueBy function and map the data
        return uniqueBy(filteredData, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    const getCustomers = () => {
        const filteredByCountry = poIdData.filter(item => item.countryId === countryId);
        return uniqueBy(filteredByCountry, 'customerId').map(data => ({
            value: data.customerId,
            label: data.customerName
        }));
    };

    const getTrackingIds = () => {
        const filteredByCustomer = poIdData.filter(item => item.customerId === customerId);
        return uniqueBy(filteredByCustomer, 'trackingNumber').map(data => ({
            value: data.trackingNumber,
            label: data.trackingNumber
        }));
    };

    return (
        <>
            <div className="flex flex-col h-full p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Manage Document Send Through DHL / Submitted"} title2={"Manage Tracking"} />

                    <div className=" bg-white rounded grow">
                        <div className="px-2 lg:px-4">
                            <div>
                                <div className="flex flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap minbox">
                                    <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                                        <input
                                            type="text"
                                            className="w-[250px] py-1  grow text-sm uppercase bg-transparent placeholder:text-sm"
                                            placeholder="SEARCH"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        {searchText ? (
                                            <IoMdCloseCircle
                                                className="min-w-[20px] cursor-pointer txt-prp-color"
                                                onClick={clearSearch}
                                            />
                                        ) : (
                                            <IoSearchSharp className="min-w-[20px]" />
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center gap-2 justify-between  px-2  ml-auto text-[rgba(0, 0, 0, 0.50)]  minbox">
                                    <div className="w-[22%] ">
                                        <CustomSelect3
                                            placeholder="Country"
                                            options={getCountries()}
                                            onChange={handleCountryChange}
                                            value={countryId}
                                        />
                                    </div>
                                    <div className="w-[22%] ">
                                        <CustomSelect3
                                            placeholder="Customer Name"
                                            options={getCustomers()}
                                            onChange={handleCustomerChange}
                                            value={customerId}
                                        />
                                    </div>
                                    <div className="w-[22%] ">
                                        <CustomSelect3
                                            placeholder="Tracking Status"
                                            options={trackingData.map((cData) => ({
                                                value: cData.id,
                                                label: cData.trackingStatus,
                                            }))}
                                            onChange={handleTrackingChange}
                                            value={trackingId}
                                        />
                                    </div>
                                    <div className="w-[22%] ">
                                        <CustomSelect3
                                            placeholder="Tracking Id"
                                            options={getTrackingIds()}
                                            onChange={handlePoIdChange}
                                            value={poId}
                                        />
                                    </div>
                                    <div className="flex justify-end gap-2 w-[12%]">
                                        <div onClick={handleSearch}>
                                            <Tooltip title="SEARCH" arrow placement="bottom">
                                                <span>
                                                    <CustomButton1 icon={<IoSearchSharp />}
                                                        className="text-white bg-prp-color py-2"
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div onClick={handleReset}>
                                            <Tooltip title="RESET" arrow placement="bottom">
                                                <span>
                                                    <CustomButton1 icon={<IoMdRefresh />} className="bg-white py-2 " />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {Permission?.view &&
                                <>
                                    <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                        <table className="w-full custom-table" border={1}>
                                            <thead>
                                                <tr className="table-heading">
                                                    <td className="">S.No</td>
                                                    {(Permission?.view || Permission?.edit) && <td>Action</td>
                                                    }
                                                    <td>Tracking Id</td>
                                                    <td>Customer Name</td>
                                                    <td>Parent Customer</td>
                                                    <td>Country</td>
                                                    <td>PO Number</td>
                                                    <td>Created Date </td>
                                                    <td>Created By </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center">Loading...</td>
                                                    </tr>
                                                ) : (
                                                    paginatedData.length === 0 ? (
                                                        <tr>
                                                            <td
                                                                className="py-3 text-center"
                                                                colSpan={9}
                                                            >
                                                                No Data Available
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        paginatedData.map((cData, index) => (
                                                            <tr key={cData.id}>
                                                                <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>

                                                                {(Permission?.view || Permission?.edit) && <td>
                                                                    <div className="gap-2">

                                                                        {!cData.ddS_IsDocumentSendDHL_Submitted && Permission?.edit &&
                                                                            <Tooltip title="EDIT" arrow placement="left">
                                                                                <span>
                                                                                    <CustomButton1
                                                                                        className="bg-sixt text-white grow max-w-[50px]"
                                                                                        icon={<BiEdit />}
                                                                                        onClick={() => handleEditButtonClick(cData.id)}
                                                                                        disabled={!cData.pR_IsPaymentReceived}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        }

                                                                        {Permission?.view &&
                                                                            <Tooltip title="VIEW" arrow placement="right">
                                                                                <span>
                                                                                    < CustomButton4
                                                                                        className="bg-eye text-white grow max-w-[50px]"
                                                                                        icon={<BsEyeFill />}
                                                                                        onClick={() => handleViewButtonClick(cData.id)}
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </td>}
                                                                <td>{cData.trackingNumber}</td>
                                                                <td>{cData.customerName}</td>
                                                                <td>{cData.parentCustomer}</td>
                                                                <td>{cData.countryName}</td>
                                                                <td>{cData.pO_PONumber}</td>
                                                                <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                                                <td>{cData.creatorName}</td>
                                                            </tr>
                                                        )))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <Pagination
                                        currentPage={currentPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        data={inputData}
                                    />
                                </>
                            }
                        </div>

                        {popupEdit && (
                            <PopUp>
                                <ManageDocumentSendEdit
                                    selectedValue={selectedValue}
                                    onCancel={() => {
                                        setPopupEdit(false);
                                        fetchApiData();
                                    }}
                                />
                            </PopUp>
                        )}

                        {popupView && (
                            <PopUp>
                                <ManageDocumentSendView
                                    selectedValue={selectedValue}
                                    onCancel={() => setPopupView(false)}
                                />
                            </PopUp>
                        )}
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}

export default ManageDocumentSendDhlSubmitted;
