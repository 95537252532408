// CORE
import * as React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import Select from 'react-select';

export default function ShippingDetails({
    streetNameShipping,
    setStreetNameShipping,
    allTerDataTemp,
    setSelectedCountryTemp,
    selectedCountryTemp,

    back,
    onCancel,
    handleCustomerDataSubmit
}) {

    const handleCountryChangeTemp = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountryTemp(selectedOption.value);
        } else {
            setSelectedCountryTemp(null); // Reset to null when cleared
        }
    }

    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px] ">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <CustomTextArea
                                        label={"Street"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        value={streetNameShipping}
                                        onChange={setStreetNameShipping}
                                    ></CustomTextArea>
                                </td>
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400 ">
                                            Country
                                        </label>

                                        <Select
                                            options={allTerDataTemp.map(data => ({
                                                value: data.id,
                                                label: data.countryName
                                            }))}
                                            onChange={handleCountryChangeTemp}
                                            className="text-black text-xs select-bar uppercase"
                                            id="id"
                                            isSearchable
                                            isClearable
                                            value={allTerDataTemp.find(option => option.id === selectedCountryTemp) ? {
                                                value: selectedCountryTemp,
                                                label: allTerDataTemp.find(option => option.id === selectedCountryTemp)?.countryName
                                            } : null} // Pre-select based on selectedCountryTemp
                                            closeMenuOnSelect={true}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={back}>
                        <CustomButton2 label={"Back"} variant="outlined" className="txt-prp-color" />
                    </div>
                    <div onClick={handleCustomerDataSubmit}>
                        <CustomButton1 label={"Submit"} className="text-white bg-prp-color" type="submit" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}
