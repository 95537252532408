import React from 'react';
import Select from 'react-select';
import '../../assets/css/selectbar.css';

function CustomSelect2({
    label,
    placeholder = "Select...",
    isRequired = false,
    options,
    value,
    onChange,
    readOnly = false,
    isMulti = false,
    isClearable = false, // Default to false; controlled by the parent
}) {
    return (
        <div className="flex flex-col gap-1">
            <label className="text-xs font-400">
                {label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>

            <Select
                options={options}
                className="text-black text-xs select-bar uppercase"
                id="id"
                value={value}
                placeholder={placeholder}
                isMulti={isMulti}
                isSearchable
                isClearable={isClearable && !!value} // Clear icon shown only when there's a selected value
                menuPosition="fixed"
                isDisabled={readOnly}
                onChange={onChange}
                closeMenuOnSelect={!isMulti}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                    },
                })}
            />
        </div>
    );
}

CustomSelect2.defaultProps = {
    isRequired: true,
    options: [],
    isClearable: false, // Default to false
};

export default CustomSelect2;
