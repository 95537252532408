import { AiOutlineSearch } from "react-icons/ai";
import { Checkbox, Collapse, List, ListItem } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onlyIcon } from "../../Admin.page";
import { TabArray } from "./TabArray"
import { useAuth } from "../../context";
// import { Collapse } from '@material-ui/core';

const RenderSubSubTabs = ({ list, pathWitoutAdmin }) => {
    const navigate = useNavigate()
    return (
        <ul className="ms-4">
            {list.map((subTab, index) => {
                if (!subTab.show) {
                    return ""
                }
                return (
                    <li key={index} onClick={(e) => { e.stopPropagation(); navigate(subTab.path) }}>
                        <div
                            className={`cursor-pointer main-tab flex items-center gap-3 px-2 transition-all duration-500  overflow-hidden`}
                            style={{ borderRadius: "10px", marginInline: "10px" }}
                        >
                            <Checkbox checked={subTab.path === pathWitoutAdmin || pathWitoutAdmin.includes(subTab.path)} style={{ color: "#643c94" }} />
                            <div to={subTab.path} className="text-sm">{subTab.title}</div>
                        </div>
                    </li>
                )
            })
            }
        </ul >
    );
};

const RenderSubTabs = ({ subTabs, selectedTab, lastPath, pathWitoutAdmin, path, shouldSubTabsBeOpen, tab }) => {
    const navigate = useNavigate()
    return (
        <div>
            <Collapse orientation="vertical" in={!onlyIcon && shouldSubTabsBeOpen(tab) && (Array.isArray(tab.subTabs))} timeout={500}>
                {!onlyIcon && shouldSubTabsBeOpen(tab) && (Array.isArray(tab.subTabs)) && <>
                    {subTabs && subTabs.length && subTabs?.map((subTab) => {
                        console.log("🚀 ~ file: NavTabs.components.jsx:468 ~ {!onlyIcon&&shouldSubTabsBeOpen ~ subTab:", subTab)
                        if (!subTab.show) {
                            return ""
                        }
                        const checked = selectedTab?.subTabs?.some(ele => (
                            ((ele.path === subTab.path) && (ele.path === lastPath)) ||
                            selectedTab?.subTabs?.some(ele => (ele.to === pathWitoutAdmin && pathWitoutAdmin?.includes(subTab.path)) ||
                                subTab?.subTabs?.some(i => pathWitoutAdmin?.includes(i.path))) || pathWitoutAdmin?.includes(subTab.path)
                        ))
                        return (
                            <li
                                key={subTab.to || subTab.path}
                                style={{ borderRadius: "10px", marginInline: "10px" }}
                                className={`cursor-pointer main-tab ms-4 py-0 transition-all duration-500`}
                                onClick={(e) => { e.stopPropagation(); navigate((path || "") + (path ? "/" : "") + subTab.path) }}
                            >
                                <div to={(path || "") + (path ? "/" : "") + subTab.path}>
                                    <Checkbox checked={checked} style={{ color: "#643c94" }} />
                                    <span className="" style={{ fontSize: "15px" }}>{subTab.title}</span>
                                    <Collapse in={subTab.subTabs?.length} timeout="auto" >
                                        {subTab.subTabs
                                            && subTab.subTabs?.length && <RenderSubSubTabs
                                                list={subTab.subTabs}
                                                pathWitoutAdmin={pathWitoutAdmin}
                                            />}
                                    </Collapse>
                                </div>
                            </li>
                        )
                    })}</>}
            </Collapse>

        </div>
    )
}
const RenderMainTabs = ({ list, selectedTab, handleTabClick, shouldSubTabsBeOpen, lastPath, pathWitoutAdmin, userRoleList }) => {
    const navigate = useNavigate(); // Assuming this is from React Router or similar
    const [openTabs, setOpenTabs] = useState({});

    const toggleTab = (tab) => {
        setOpenTabs(prevOpenTabs => ({
            ...prevOpenTabs,
            [tab.title]: !prevOpenTabs[tab.title] // Toggle the state of the clicked tab
        }));
        handleTabClick(tab);
        navigate(tab.to);
    };

    return (
        <Collapse timeout={1000} in>
            {list.map((tab) => {
                if (!tab.show) {
                    return null;
                }
                const isSelected = selectedTab && tab.title === selectedTab.title;
                const isOpen = openTabs[tab.title];

                return (
                    <li key={tab.title} onClick={() => toggleTab(tab)}>
                        <div>
                            <div
                                className={`${
                                    isSelected ? 'bg-[#643c94] text-white' : 'text-[#3D3D3D]'
                                } cursor-pointer main-tab flex items-center gap-3 py-1.5 my-1 px-2 transition-all duration-500 overflow-hidden`}
                                style={{ borderRadius: '10px', marginInline: '10px' }}
                            >
                                <div className={`flex pl-2 justify-center items-center ${onlyIcon ? 'w-full' : ''}`}>
                                    <span className={`min-w-[30px] text-xl`}>{isSelected ? tab.activeIcon : tab.inActiveIcon}</span>
                                    {!onlyIcon && <div className={`${tab.title === 'Admin UoM' && 'normal-case'} ml-2`}>{tab.title}</div>}
                                </div>
                            </div>
                            <Collapse timeout="auto" in={isOpen}>
                                <RenderSubTabs
                                    subTabs={tab.subTabs}
                                    path={tab.path}
                                    shouldSubTabsBeOpen={shouldSubTabsBeOpen}
                                    tab={tab}
                                    selectedTab={selectedTab}
                                    lastPath={lastPath}
                                    pathWitoutAdmin={pathWitoutAdmin}
                                />
                            </Collapse>
                        </div>
                    </li>
                );
            })}
        </Collapse>
    );
};
function NavTabs() {
    const [searchQuery, setSearchQuery] = useState(""); // State to store the search query
    const [selectedTab, setSelectedTab] = useState(null);
    const location = useLocation()
    const lastPath = useMemo(() => location?.pathname?.split("admin")[1].split("/").pop(), [location])
    const pathWitoutAdmin = useMemo(() => location?.pathname?.split("/admin/")[1], [location])

    useEffect(() => {
        const array = location?.pathname?.split("admin")
        if (array[1].includes("app")) {
            const last = array[1].split("/").pop()
            setSelectedTab(TabArray.find(ele => {
                return (array[1].includes(ele.to) ||
                    ele?.subTabs?.path?.includes(last) ||
                    (ele?.subTabs?.length && ele?.subTabs?.some(ele => ele.path === last)) ||
                    (ele?.subTabs?.length && ele?.subTabs?.some(ele => location?.pathname?.split("admin")[1].includes(ele.path)))
                )
            }))
        } else {
            setSelectedTab(TabArray.find(ele => ele?.admin))
        }
    }, [location])

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const shouldSubTabsBeOpen = (tab) => {
        return selectedTab?.title === tab?.title && tab?.subTabs && tab?.subTabs?.length && (Array.isArray(tab.subTabs) ? tab.subTabs.length > 0 : true);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const { user } = useAuth()
    const userRoleList = user?.userRoleList
    const checkAdminAllTab = (tabsList) => {
        const tl = tabsList.map(ele => ({ ...ele, permission: userRoleList.find(vl => vl.moduleId === ele?.id) }))
        return tl.some(ele => ele?.permission?.view || ele?.permission?.edit || ele?.permission?.add)
    }
    const getFilterList = (list) => {
        return list.filter(ele => {
            const f = userRoleList.find(v => v.moduleId === ele?.id || ele?.subId?.includes(v.moduleId))
            return Boolean(f?.view || f?.edit || f?.add)
        })
    }

    const isAdmin = () => { return [1, 2].includes(Number(user.roleId)) }
    const tabs = useMemo(() => TabArray.filter((tab) => {
        const searchQ = searchQuery.toLowerCase()
        const retunData = tab.title.toLowerCase().includes(searchQ) ||
            tab.subTabs?.title?.toLowerCase().includes(searchQ) ||
            (tab.subTabs?.length && tab.subTabs?.some(ele => ele.title?.toLowerCase().includes(searchQ)))
        if (tab.subTabs?.length && tab.subTabs?.some(ele => ele.title?.toLowerCase().includes(searchQ))) {
            tab.subTabs = tab.subTabs.map(st => ({ ...st, show: searchQ ? st.title.toLowerCase().includes(searchQ) : true }))
        }
        return retunData
    }
    ).map(ele => {
        const main = userRoleList?.find((v) => v.moduleId === ele?.id)
        if (ele?.admin || ele?.sub) {
            return {
                ...ele,
                show: checkAdminAllTab(ele?.subTabs) || isAdmin(),
                to: ele?.admin ? getFilterList(ele?.subTabs)?.[0]?.path : (getFilterList(ele?.subTabs)?.[0]?.to || ele?.to),
                toL: getFilterList(ele?.subTabs),
                subTabs: ele?.subTabs.map(valEle => {
                    if (valEle?.subsub) {
                        return {
                            ...valEle,
                            show: checkAdminAllTab(valEle?.subTabs) || valEle?.id === 0 || isAdmin(),
                            path: getFilterList(valEle?.subTabs)?.[0]?.path,
                            subTabs: valEle?.subTabs.map(sVal => {
                                const nT = userRoleList.find(n => n.moduleId === sVal.id)
                                return {
                                    ...sVal,
                                    show: (nT?.view || nT?.edit || nT?.add) || sVal.id === 0 || isAdmin()
                                }
                            })
                        }
                    }
                    const mainSub = userRoleList?.find((v) => v.moduleId === valEle?.id)
                    if (mainSub) {
                        if (mainSub.view || mainSub.edit || mainSub.add) {
                            return { ...valEle, show: true || isAdmin() }
                        }
                        else {
                            return { ...valEle, show: false || isAdmin() }
                        }
                    }
                    return { ...valEle, show: false || isAdmin() }
                })
            }
        }
        if (main) {
            if (main.view || main.edit || main.add) {
                return { ...ele, show: true || isAdmin() }
            }
            else {
                return { ...ele, show: false || isAdmin() }
            }
        }
        return { ...ele, show: false || isAdmin() }
    }), [searchQuery, TabArray, userRoleList])
    console.log("tabs", tabs)
    return (
        <div className="sidebar">
            <ul>
                {onlyIcon ? null :
                    <div>
                        <div className="flex items-center px-2 py-2 my-2 mx-2 gap-4 border rounded bg-[#BDC8DD] "
                            style={{ borderRadius: "10px" }}>
                            <AiOutlineSearch className="icon min-w-[30px]" />
                            {onlyIcon ? null : (
                                <input
                                    type="search"
                                    className="w-full bg-transparent shrink grow placeholder:text-fourth uppercase"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange} // Handle search input change
                                />

                            )}
                        </div>
                    </div>
                }
                <RenderMainTabs
                    list={tabs}
                    selectedTab={selectedTab}
                    handleTabClick={handleTabClick}
                    pathWitoutAdmin={pathWitoutAdmin}
                    shouldSubTabsBeOpen={shouldSubTabsBeOpen}
                    lastPath={lastPath}
                    userRoleList={userRoleList}
                />
            </ul>
        </div>
    );
}

export default NavTabs;