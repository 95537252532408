import * as React from "react";
import { useState } from "react";
import CustomButton1 from "./components/general-components/CustomButton1.component";
import proLogo from './assets/project-images/logo/4.png'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { useAuth } from "./context";

function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [mNoError, setMNoError] = useState('');
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let inputValue = event.target.value.trim();
        // Remove all non-digit characters
        inputValue = inputValue.replace(/\D/g, '');

        // Limit to 10 digits
        if (inputValue.length > 10) {
            inputValue = inputValue.slice(0, 10);
        }

        event.target.value = inputValue;

        if (inputValue.length === 10) {
            setMNoError('');
        } else {
            setMNoError('Please enter a 10-digit.');
        }
        setMobileNumber(inputValue);
    };
    const { login } = useAuth()
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let isValid = true;

        if (password.trim() === "") {
            setPasswordError("Password is required");
            isValid = false;
        } else {
            setPasswordError("");
        }

        if (!isValid) {
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Login/Login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    mobileNumber: mobileNumber,
                    password: password,
                    isWebOrMobileUser: "W",
                    remember: true
                }),
            });

            const responseData = await response.json();
            setResponseData(responseData);
            if (responseData.isSuccess) {
                toast.success("Login successfully");
                const token = responseData.data.token;
                const username = responseData.data.userName;
                await login(responseData.data)
                localStorage.setItem('DIYAN_IE_authToken', token);
                localStorage.setItem('DIYAN_IE_username', username);
                // setMobileNumber('');
                // setPassword('');
                setTimeout(() => {
                    window.location.href = 'admin/app/dashboard'; // Reload the page or fetch updated list
                }, 1000);
            } else {
                console.error("Login failed:", responseData.message);
                toast.error(responseData.message || "Login failed");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error submitting Login:", error);
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="flex items-center justify-end min-h-screen bg-cover bg-center bg-no-repeat bg-img">
                {/* MAIN CONTAINER */}
                <div className="rounded-lg flex flex-col items-center overflow-hidden bg-opacity-20 justify-center m-6  w-[80%] max-w-[25rem]  custom-shadow-1 "
                    style={{ backgroundColor: "#c4ab77" }}
                >
                    <div className=" flex items-center justify-center bg-white w-[100%] py-3">
                        <img src={proLogo} className="w-[10rem] " alt="" />
                    </div>

                    <div className="max-w-full w-[300px] ">
                        <h1 className="heading text-white mb-5 mt-5">Login</h1>

                        <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="uname" className="text-sm text-white font-[400]">
                                        Mobile Number <span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <div>
                                        <div className="relative uppercase flex">
                                            <input
                                                type="text"
                                                required
                                                className="p-2  brd-left rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase"
                                                id="mobileNumber"
                                                placeholder="ENTER MOBILE NUMBER"
                                                value={mobileNumber}
                                                onChange={handleChange}
                                            />
                                            <span
                                                className="absolute inset-y-0 right-0 flex  items-center rounded-r  px-3 py-1  text-base font-medium txt-prp-color bg-white"
                                            >
                                                <FaUser />
                                            </span>
                                        </div>
                                        {mNoError && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{mNoError}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-sm text-white font-[400]">
                                        Password <span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <div>
                                        <div className="relative uppercase flex">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                required
                                                className="p-2  rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs uppercase brd-left"
                                                id="mobileNumber"
                                                placeholder="ENTER YOUR PASSWORD"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 flex cursor-pointer items-center rounded-r  px-3 py-1  text-base font-medium txt-prp-color bg-white"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
                                            </button>
                                        </div>
                                        {passwordError && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{passwordError}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 mb-10">
                                <CustomButton1 label={loading ? "Loading" : "Login"} className="bg-white text-purple-900 w-full" type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={1000} />
        </>
    );
}

export default Login;