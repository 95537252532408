// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import Modal from 'react-modal';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import CustomImageMultiple from "../../general-components/CustomImageMultiple.component";

import { CiSquareRemove } from "react-icons/ci";
import { BsPlusSquareFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { ImEnlarge } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import PopUp from "../../general-components/PopUp.componenet";
import { Tooltip } from "@mui/material";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomImageMultipleZip from "../../general-components/CustomImageMultipleZip.component";

export default function ManageContainersUnderLoadingEdit({ onCancel, selectedValue }) {
    const [cuLoadClose, setCuLoadClose] = useState(false)

    const [openAccordionIndex, setOpenAccordionIndex] = useState(7);
    const [orderDate, setOrderDate] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [updatePopup, setUpdatePopup] = useState(false);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };
    const [rows, setRows] = useState([]);
    const [conAttach, setConAttach] = useState([]);
    const [conName, setConName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [conAttachNew, setConAttachNew] = useState("");

    // Helper function to limit images per record
    const getMaxUploadsForRecord = (existingImagesCount, newImagesCount) => {
        return Math.max(0, 10 - existingImagesCount - newImagesCount);
    };

    // Convert file to Base64 format
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/zip'];

    const handleFileChange = async (e, index) => {
        const files = Array.from(e.target.files);
        const newFiles = files.slice(0, getMaxUploadsForRecord(rows[index].conAttach.length, conAttachNew.length));
        const updatedRows = [...rows];

        for (const file of newFiles) {
            const isZipFile = file.name.endsWith('.zip');
            const isAllowedType = allowedTypes.includes(file.type) || isZipFile;

            if (!isAllowedType) {
                toast.error(`File type not allowed: ${file.name}`);
                continue;
            }

            const base64 = await fileToBase64(file);
            updatedRows[index].conAttach.push({
                name: file.name,
                url: URL.createObjectURL(file),
                base64: base64.split(',')[1], // Convert ZIP and images to Base64
                isNew: true, // Mark as new file
            });
        }

        setRows(updatedRows);
    };

    const handleConAttachChange = (files) => {
        setConAttach(files);
    };

    const handleAddRow = () => {
        const newRow = { id: 0, conName: '', conAttach: [], conAttachNew: [] }
        setRows([...rows, newRow]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = rows.map((row, rowIndex) =>
            rowIndex === index ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
    };

    useEffect(() => {
        if (selectedValue) {
            const containersData = selectedValue.containersUnderLoadingList || [];
            const initialRows = containersData.map(container => ({
                id: container.id,
                conName: container.containerCount,
                conAttach: container.containersUnderLoadingImagesList.map(img => ({
                    id: img.id, // Assign the image ID from the API response
                    name: img.containerOriginalFileName,
                    url: img.containerImageURL,
                    base64: img.containerOriginalImage_Base64,
                    isNew: false // Existing image
                })),
                conAttachNew: [] // Initialize empty array for new attachments
            }));
            setRows(initialRows);
        }
        if (selectedValue) {
            setCuLoadClose(selectedValue?.cuL_IsContainersUnderLoadingClose);
        }
    }, [selectedValue]);

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            const filteredRows = rows.filter((row) => row.conName || row.conAttach.length > 0);

            const rowsWithDynamicIds = filteredRows.map((row) => {
                const combinedConAttach = [...row.conAttach, ...(row.conAttachNew || [])]; // Ensure conAttachNew is an array

                // Filter out images with empty values
                const imagesList = combinedConAttach
                    .filter(file => file.base64 || file.name)
                    .map((file) => ({
                        id: file.isNew ? 0 : file.id, // Use existing ID if not a new file
                        containerImage: "",
                        imageName: file.name,
                        containerOriginalFileName: file.name,
                        containerOriginalImage_Base64: row.id === 0 || file.isNew ? file.base64 : "", // Include Base64 for new files or new rows
                    }));

                return {
                    id: row.id || 0,
                    containerCount: row.conName || 0,
                    containersUnderLoadingImagesList: imagesList.length > 0 ? imagesList : undefined, // Only include if there are images
                };
            });

            // Filter out rows without significant data
            const validRows = rowsWithDynamicIds.filter(row => row.containerCount > 0 || (row.containersUnderLoadingImagesList && row.containersUnderLoadingImagesList.length > 0));

            // Handle the first row separately if necessary
            const firstRow = {
                id: 0,
                containerCount: conName || 0,
                containersUnderLoadingImagesList: conAttach
                    .filter(file => file.base64 || file.name) // Include only if base64 or name is present
                    .map((file) => ({
                        id: 0,
                        containerImage: "",
                        imageName: file.name,
                        containerOriginalFileName: file.name,
                        containerOriginalImage_Base64: file.base64,
                    })),
            };

            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                containersUnderLoadingList: [
                    ...(firstRow.containerCount > 0 || (firstRow.containersUnderLoadingImagesList && firstRow.containersUnderLoadingImagesList.length > 0) ? [firstRow] : []),
                    ...validRows,
                ],
                cuL_IsContainersUnderLoadingClose: cuLoadClose || false,
                cuL_ContainersUnderLoadingClosedDate: orderDate || null,
            };

            console.log("Request Body:", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file.url; // URL of the image
        link.download = file.name || 'download'; // Set the filename
        link.target = '_blank'; // Ensure it opens in a new tab or window if necessary
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge = (file) => {
        setModalFile(file);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalFile(null);
    };

    const handleDelete = (index) => {
        const updatedFiles = conAttach.filter((_, i) => i !== index);
        setConAttach(updatedFiles);
    };

    const handleCancel = (rowIndex, imgIndex) => {
        console.log(`Canceling image at row: ${rowIndex}, index: ${imgIndex}`);
    
        const updatedRows = [...rows];
        updatedRows[rowIndex].conAttach = updatedRows[rowIndex].conAttach.filter((img) => img.id !== imgIndex);
        setRows(updatedRows);
    
        // Clear file input to allow re-uploading the same file
        document.getElementById(`file-upload-${rowIndex}`).value = null;
    };
    

    const handleCancel1 = (rowIndex, imgIndex) => {
        console.log(`Canceling image at row: ${rowIndex}, index: ${imgIndex}`);

        setRows(prevRows => {
            return prevRows.map((row, index) => {
                if (index === rowIndex) {
                    console.log(`Before canceling, row ${index}:`, row);

                    // Remove the image at imgIndex from conAttachNew
                    const updatedConAttachNew = row.conAttachNew.filter((_, i) => i !== imgIndex);

                    console.log(`After canceling, row ${index}:`, { ...row, conAttachNew: updatedConAttachNew });
                    return {
                        ...row,
                        conAttachNew: updatedConAttachNew,
                    };
                }
                return row;
            });
        });
    };

    // useEffect(() => {
    //     console.log('Rows updated:', rows);
    // }, [rows]);

    const handleDeleteRecordImage = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteContainersUnderLoadingImages?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                // Remove the image from the UI
                const updatedRows = [...rows];
                updatedRows[index].conAttach = updatedRows[index].conAttach.filter((img) => img.id !== id);
                setRows(updatedRows);

                toast.success('Record deleted successfully');
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    const handleDeleteRecord = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteContainersUnderLoading?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Record deleted successfully');
                // Remove the row from the UI
                const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
                setRows(updatedRows);
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Containers Under Loading</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex === 7}
                        onClick={() => handleAccordionClick(7)}
                    >
                        {/* <EditContainersUnderLoading /> */}
                        <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <CustomAlphatext
                                    label="Container Number"
                                    placeholder="Enter"
                                    value={conName}
                                    onChange={setConName}
                                    isRequired={false}
                                />
                            </div>
                            <div className="">
                                <CustomImageMultipleZip
                                    label="Containers Under Loading Upload"
                                    id="poissuede435345edit-edit-0"
                                    value={conAttach}
                                    isRequired={false}
                                    onChange={handleConAttachChange}
                                />
                            </div>

                            <div className="flex items-end">
                                <Tooltip title="ADD" arrow placement="bottom">
                                    <span>
                                        <button onClick={handleAddRow}>
                                            <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                                        </button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>

                        {rows.map((row, index) => (
                            <div key={index} className="max-w-full grid grid-cols-2 gap-2 md:grid-cols-3 w-[1000px] mt-5">
                                <div>
                                    <CustomAlphatext
                                        label="Container Number"
                                        isRequired={false}
                                        placeholder="Enter"
                                        value={row.conName}
                                        onChange={(value) => handleRowChange(index, 'conName', value)}
                                    />
                                </div>
                                <div>
                                    <div>
                                        {/* Always show label and attachment button for existing rows */}
                                        {row.id !== 0 && (
                                            <>
                                                <label htmlFor={`file-upload-${index}`} className="text-xs font-[400] uppercase">
                                                    Container Under Loading Upload
                                                    {/* <span className="text-red-500 gap-3">*</span> */}
                                                </label>
                                                <div>
                                                    <label
                                                        htmlFor={`file-upload-${index}`}
                                                        className="cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"
                                                    >
                                                        Attachment
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id={`file-upload-${index}`}
                                                        key={row.conAttach.length} // Updates key when images are removed
                                                        className="hidden"
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        multiple
                                                        // accept="image/*"
                                                        accept=".jpeg,.jpg,.png,.zip"
                                                        disabled={row.conAttach.length + row.conAttachNew.length >= 10}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {/* Display existing images if they are present */}
                                        {row.conAttach.length > 0 ? (
                                            <div>
                                                {row.conAttach.map((img, imgIndex) => (
                                                    <div key={imgIndex} className="relative flex items-center space-x-2 mt-2">
                                                        <div className="mt-2 flex justify-around gap-2">
                                                            <Tooltip title="DOWNLOAD" arrow placement="left">
                                                                <span>
                                                                    <button onClick={() => handleDownload(img)} className="bg-prp-color text-white p-2 rounded">
                                                                        <FaDownload />
                                                                    </button>
                                                                </span>
                                                            </Tooltip>
                                                            {/* Conditionally render the delete button */}
                                                            {img.isNew ? (
                                                                <div>
                                                                    <Tooltip title="REMOVE" arrow placement="bottom">
                                                                        <span>
                                                                            <button onClick={() => handleCancel(index, imgIndex)} className="bg-white txt-prp-color border border-prp p-2 rounded">
                                                                                <MdCancel />
                                                                            </button>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>

                                                            ) : (
                                                                <div>
                                                                    <Tooltip title="DELETE" arrow placement="bottom">
                                                                        <span>
                                                                            <button onClick={() => handleDeleteRecordImage(index, img.id)} className="px-2 bg-white border-red-500 border rounded">
                                                                                <RiDeleteBin6Line className="text-red-500" />
                                                                            </button>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            <Tooltip title="ENLARGE" arrow placement="right">
                                                                <span>
                                                                    <button onClick={() => handleEnlarge(img)} className="bg-prp-color text-white p-2 rounded">
                                                                        <ImEnlarge />
                                                                    </button>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                        <img
                                                            src={img.url}
                                                            alt={img.name}
                                                            className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            // Only show "No images available" for existing rows with no images
                                            // row.id !== 0 && <div className="text-gray-500">No images available</div>
                                            null
                                        )}

                                        {/* Section for new attachments */}
                                        {row.id === 0 && (
                                            <CustomImageMultipleZip
                                                label="Containers Under Loading Images"
                                                id={`poissudgfea345d34444edit-edit-${index}`}
                                                value={row.conAttachNew}
                                                onChange={(value) => handleRowChange(index, 'conAttachNew', value)}
                                            />
                                        )}
                                    </div>
                                </div>

                                {row.id === 0 ? (
                                    <div className="flex">
                                        <Tooltip title="REMOVE" arrow placement="bottom">
                                            <span>
                                                <button onClick={() => handleRemoveRow(index)} className="pt-4">
                                                    <CiSquareRemove className="h-10 w-9 txt-prp-color " />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div>
                                        <Tooltip title="DELETE" arrow placement="bottom">
                                            <span>
                                                <button onClick={() => handleDeleteRecord(index, row.id)} className="pt-4 ">
                                                    <RiDeleteBin6Line className="h-7 w-7 text-red-500" />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        ))}

                        <div className="mt-5 max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="date" className="text-xs font-[400]">
                                        Container Loading Close Date
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={orderDate}
                                        onChange={(e) => setOrderDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="">
                                <CustomCheckBox2
                                    label={"Container Loading Close"}
                                    state={cuLoadClose}
                                    setState={setCuLoadClose}
                                ></CustomCheckBox2>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Enlarge File"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="coman-modal-flex">
                    {modalFile && (
                        <div className="coman-modal-text">
                            <p>{modalFile.name}</p>
                        </div>
                    )}
                    <div className="coman-modal">
                        {modalFile && (
                            <>
                                <button onClick={() => handleDownload(modalFile)} className="modal-btn-comman modal-btn-dwl">
                                    <FaDownload />
                                </button>
                                <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl">
                                    <MdCancel />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {modalFile && (
                    <img
                        src={modalFile.url || `data:image/png;base64,${modalFile.base64}`}
                        alt="Enlarged"
                        className="w-full h-auto"
                    />
                )}
            </Modal>

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
        </>
    )
}
