import React from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomViewTextArea from '../../general-components/CustomViewTextArea';
import CustomImageUpload from "../../general-components/CustomImageUpload.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import { useAuth } from "../../../context";

const ManageCompanyDetailView = ({ compData, onCancel }) => {
    const { user } = useAuth()

    const getValueOrDash = (value) => value ?? '-';
    const createdDate = compData?.createdDate ? new Date(compData.createdDate).toLocaleDateString('en-GB') : 'N/A';

    const amcStartDate = compData?.amcStartDate ? new Date(compData.amcStartDate).toLocaleDateString('en-GB') : 'N/A';
    const amcEndDate = compData?.amcEndDate ? new Date(compData.amcEndDate).toLocaleDateString('en-GB') : 'N/A';

    return (
        <div className="pb-5 bg-white rounded-lg">
            <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                <h1 className="text-xl font-weight-[300]">View Company</h1>
                <div className="flex item-center text-sm"></div>
            </div>

            <div className="p-5 mx-auto overflow-scroll overflow-x-auto overflow-y-auto" style={{ maxHeight: "500px" }}>
                <table className="max-w-full popup-table w-[1050px] ">
                    <tbody>
                        <tr>
                            <td>
                                <CustomViewLabel label="Company Name" value={getValueOrDash(compData?.companyName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Company Type" value={getValueOrDash(compData?.companyType)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Registration Number" value={getValueOrDash(compData?.registrationNumber)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Contact Number" value={getValueOrDash(compData?.contactNumber)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Email" value={getValueOrDash(compData?.email)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Website" value={getValueOrDash(compData?.website)} />
                            </td>
                        </tr>
                        {user?.roleId === 1 && (
                            <tr>
                                <td>
                                    <CustomViewLabel label="AMC Start Date" value={getValueOrDash(compData?.amcMonth)} />
                                </td>
                                <td>
                                    <CustomViewLabel label="AMC Start Date" value={amcStartDate} />
                                </td>
                                <td>
                                    <CustomViewLabel label="AMC End Date" value={amcEndDate} />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <CustomViewTextArea label="Address" value={getValueOrDash(compData?.addressLine1)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Country" value={getValueOrDash(compData?.countryName)} />
                            </td>
                            <td>
                                <CustomViewLabel label="State" value={getValueOrDash(compData?.stateName)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="District / Province" value={getValueOrDash(compData?.districtName)} />
                            </td>

                            <td>
                                <CustomViewLabel label="Pin Code" value={getValueOrDash(compData?.pincode)} />
                            </td>
                            <td>
                                <CustomViewLabel label="GST Number" value={getValueOrDash(compData?.gstNumber)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Pan Number" value={getValueOrDash(compData?.panNumber)} />
                            </td>
                            <td>
                                <div>
                                    <label className='text-xs font-[400]'>Company Image Upload</label>
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <p className='text-gray-400 text-xs'>{compData?.companyLogo ?? '-'}</p>
                                        </div>
                                        {compData?.companyLogoURL && (
                                            <img
                                                src={compData.companyLogoURL}
                                                alt="Employee Profile"
                                                className="h-14 w-14 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center"
                                            />
                                        )}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CustomViewLabel label="Total Number of Users" value={getValueOrDash(compData?.noofUserAdd)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Total Branches" value={getValueOrDash(compData?.noofBranchAdd)} />
                            </td>
                            <td>
                                <CustomViewLabel label="Status" value={compData?.isActive ? 'Active' : 'Inactive'} />
                            </td>
                            <td>
                                <CustomViewLabel label="Created Date" value={getValueOrDash(createdDate)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CustomViewLabel label="Created By" value={getValueOrDash(compData?.creatorName)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="flex justify-center pt-3 border-t">
                <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                </div>
            </div>
        </div>
    );
};

export default ManageCompanyDetailView;
