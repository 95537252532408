// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";
import ManageTrackingStatusAll from "./ManageTrackingStatus/ManageTrackingStatusAll";
import ManageTrackingStatusClosed from "./ManageTrackingStatus/ManageTrackingStatusClosed";
import ManageTrackingStatusPending from "./ManageTrackingStatus/ManageTrackingStatusPending";
import { useEffect } from "react";

export default function ManageTrackingStatus() {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const savedTab = localStorage.getItem('diyanActiveTrackingStatusTab');
        if (savedTab) {
            setActiveTab(parseInt(savedTab, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('diyanActiveTrackingStatusTab', activeTab);
    }, [activeTab]);

    return (
        <div className="flex flex-col h-full uppercase">
            <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                <Title title1={"Manage Tracking Status"} title2={"Manage Tracking Status"} />

                <div className="px-2 lg:px-4 minbox mt-5">
                    {/* 18 TABS */}
                    <TabContainer showArrow={true}>
                        <Tab label="Pending" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Closed" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="All" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>

                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <ManageTrackingStatusPending />
                    </div>
                </TabContent>

                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <ManageTrackingStatusClosed />
                    </div>
                </TabContent>

                <TabContent index={2} activeTab={activeTab}>
                    <div>
                        <ManageTrackingStatusAll />
                    </div>
                </TabContent>
            </div>
        </div>
    );
}
