import React, { useEffect, useState } from 'react';
import { ImEnlarge } from "react-icons/im";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "../../assets/css/selectbar.css";

function CustomImgPdfSingle(props) {
    const { id, label, isRequired, readOnly, value, onChange, disabled } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);

    useEffect(() => {
        if (error) {
            const errorTimeout = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [error]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        const maxFileSizeMB = 10;

        if (!file) return;

        if (!allowedTypes.includes(file.type)) {
            setError(`The file type is not allowed: ${file.name}.`);
            return;
        } else if (file.size / (1024 * 1024) > maxFileSizeMB) {
            setError(`File size exceeds the limit of ${maxFileSizeMB} MB: ${file.name}.`);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const fileData = {
                name: file.name,
                type: file.type,
                base64: reader.result.replace(/^data:(image\/(png|jpeg|jpg)|application\/pdf);base64,/, ''),
            };
            setSelectedFile(fileData);
            onChange(fileData);
        };
        reader.onerror = () => {
            setError('Failed to read the file');
        };
        reader.readAsDataURL(file);
    };

    const handleCancel = () => {
        setSelectedFile(null);
        onChange(null);

        // Clear file input to allow re-uploading the same file
        document.getElementById(`file-upload-${id}`).value = null;
    };

    const handleDownload = (file) => {
        const url = `data:${file.type};base64,${file.base64}`;
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge1 = (file) => {
        setModalFile(file);
        setIsModalOpen(true);
    };

    const handleEnlarge2 = (file) => {
        const url = `data:${file.type};base64,${file.base64}`;
        window.open(url, '_blank');
    };

    const handleEnlarge = (file) => {
        const byteCharacters = atob(file.base64);
        const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.type });
    
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
    
        // Clean up the URL after a short delay to avoid memory leaks
        setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
    };
    

    const closeModal = () => {
        setIsModalOpen(false);
        setModalFile(null);
    };

    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor={id} className="text-xs font-[400] mb-2">
                {label.toUpperCase()}
                {/* {isRequired && <span className="text-red-500 gap-3">*</span>} */}
            </label>
            <div className="flex justify-between items-center mt-1">
                <div>
                    <label
                        htmlFor={`file-upload-${id}`}
                        className={disabled ? "cursor-pointer text-xs bg-gray-600 text-white p-2 rounded-md" : "cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"}
                    >
                        Attachment
                    </label>
                    <input
                        type="file"
                        id={`file-upload-${id}`}
                        key={selectedFile ? selectedFile.name : "no-file"} // Update key to force re-render
                        className="hidden"
                        onChange={handleFileChange}
                        disabled={disabled}
                    />
                </div>
            </div>

            {selectedFile && (
                <div className="flex items-center space-x-2 mt-2">
                    <div className="flex items-center space-x-2">
                        <button onClick={() => handleDownload(selectedFile)} className="bg-prp-color text-white p-2 rounded">
                            <FaDownload />
                        </button>
                        <button onClick={handleCancel} className="bg-white txt-prp-color border border-prp p-2 rounded">
                            <MdCancel />
                        </button>
                        <button onClick={() => handleEnlarge(selectedFile)} className="bg-prp-color text-white p-2 rounded">
                            <ImEnlarge />
                        </button>
                    </div>

                    <div>
                        {selectedFile.type.startsWith('image') && (
                            <img
                                src={`data:image/png;base64,${selectedFile.base64}`}
                                alt="Preview"
                                className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                            />
                        )}
                        {selectedFile.type === 'application/pdf' && (
                            <embed
                                src={`data:application/pdf;base64,${selectedFile.base64}`}
                                type="application/pdf"
                                className="w-10 h-10 border border-[#3D3D3D66]"
                            />
                        )}
                    </div>
                </div>
            )}

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Enlarge File"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="coman-modal-flex">
                    {modalFile && (
                        <div className="coman-modal-text">
                            <p>{modalFile.name}</p>
                        </div>
                    )}
                    <div className="coman-modal">
                        {modalFile && (
                            <>
                                <button onClick={() => handleDownload(modalFile)} className="modal-btn-comman modal-btn-dwl">
                                    <FaDownload />
                                </button>
                                <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl">
                                    <MdCancel />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {modalFile && modalFile.type.startsWith('image') && (
                    <img
                        src={`data:image/png;base64,${modalFile.base64}`}
                        alt="Enlarged"
                        className="w-full h-auto"
                    />
                )}
                {modalFile && modalFile.type === 'application/pdf' && (
                    <iframe
                        src={`data:application/pdf;base64,${modalFile.base64}`}
                        className="w-full h-auto"
                        title="Enlarged PDF"
                    ></iframe>
                )}
            </Modal>
            {error && (
                <p
                    className="absolute -bottom-6 text-[10px] font-normal"
                    style={{
                        color: 'red',
                        marginTop: '8px',
                        maxWidth: '100%',
                        wordWrap: 'break-word'
                    }}
                >
                    {error}
                </p>
            )}
            {/* {error && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{error}</p>} */}
        </div>
    );
}

CustomImgPdfSingle.defaultProps = {
    isRequired: true,
};

export default CustomImgPdfSingle;
