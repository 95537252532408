import * as React from "react";
import { useRef, useEffect } from "react";
import Button from "@mui/material/Button";

import { FiMenu } from "react-icons/fi";
import { FaUserAlt } from "react-icons/fa";
import { BiChevronDown, BiLogOutCircle } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";

import NavTabs from "./NavTabs.components";
import ToolTipY from "./ToolTipY.component";
import { onlyIcon } from "../../Admin.page";
import { setOnlyIcon } from "../../Admin.page";
import LogoImage from '../../assets/project-images/logo/fvs-logo.png'

import { useAuth } from "../../context";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Navbar() {
    let asideRef = useRef(null);
    let overlayRef = useRef(null);
    const { logout } = useAuth()

    function open() {
        asideRef.current.style.width = "60%";
        overlayRef.current.style.width = "100%";
    }
    function close() {
        asideRef.current.style.width = "0px";
        overlayRef.current.style.width = "0px";
    }

    useEffect(function () {
        setOnlyIcon(false);
    }, []);

    function Aside() {
        return (
            <div className="lg:hidden uppercase">
                {/* ASIDE */}
                <div
                    className="fixed top-0 left-0 z-50 w-0 h-screen transition-all bg-white custom-shadow overflow-clip"
                    ref={asideRef}
                >
                    {/* CROSS BUTTON */}
                    <div className="p-5 md:p-6">
                        <RxCross2 className="icon press-3" onClick={close} />
                    </div>

                    {/* NAVTABS */}
                    <div
                        className="m-5 max-h-[80vh] overflow-x-hidden overflow-y-auto hide-scrollbar"
                        style={{ border: "1px solid #3D3D3D66" }}
                    >
                        <NavTabs />
                    </div>
                </div>

                {/* OVERLAY */}
                <div
                    className="fixed left-0 top-0 w-0 h-screen bg-[rgba(0,0,0,0.1)] custom-shadow z-40"
                    ref={overlayRef}
                    onClick={close}
                ></div>
            </div>
        );
    }

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const username = localStorage.getItem('DIYAN_IE_username');
    const logOuts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Login/Logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                localStorage.removeItem("DIYAN_IE_authToken");
                localStorage.removeItem("DIYAN_IE_username");
                toast.success("Logout successfully");
                logout()
            } else {
                console.error("Error during logout", responseData.message);
                toast.error(responseData.message || "Error during logout");
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <>
            <section>
                {/* ASIDE */}
                <Aside />

                {/* TOP NAVBAR */}
                <div className="flex uppercase ">
                    <div className="">
                        <div onClick={open} className="lg:hidden">
                            <FiMenu className="inline-block icon press-5" />
                        </div>
                        <div
                            className="justify-center items-center  bg-white hidden rounded-xl lg:flex lg:w-[320px] border-r relative"
                            style={{ border: "1px solid #3D3D3D66" }}
                        >
                            <img src={LogoImage} className="m-2 w-[3rem] h-[3rem]" alt="" />
                            <div
                                className="w-[2.5rem] flex items-center bg-prp-color justify-center h-[2.5rem] border rounded-md text-white press-2 absolute right-[10px]"
                                onClick={() => setOnlyIcon(!onlyIcon)}
                            >
                                <AiOutlineMenu className={`transition ${onlyIcon ? " rotate-180 " : "  "} `} />
                            </div>
                        </div>
                    </div>

                    <div className="flex ml-4 px-10 bg-prp-color rounded-xl  justify-end  border lg:px-0 lg:pr-2 grow uppercase">
                        <div className="flex items-center  gap-8 my-2 p-1 rounded-2xl bg-white">
                            {/* USER */}
                            <div>
                                <ToolTipY
                                    title={
                                        <div className="flex items-center gap-2 border-0 border-b rounded-l-full press-1">
                                            <div className="flex items-center justify-center p-2 rounded-full bg-prp-color">
                                                <FaUserAlt className="icon text-white text-[15px] " />
                                            </div>
                                            <div className="text-xs">{username}</div>
                                            <div>
                                                <BiChevronDown className="" />
                                            </div>
                                        </div>
                                    }
                                    content={
                                        <>
                                            <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap">
                                                <div className="flex items-center gap-2 capitalize" onClick={logOuts}>
                                                    <Button size="small" className="justify-start text-left text-red-400">
                                                        <BiLogOutCircle className="inline-block text-2xl " />
                                                        <span className="mt-[2px] uppercase">Logout</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    height="50px"
                                    direction="down"
                                    position="right"
                                ></ToolTipY>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer autoClose={2000} />
        </>
    );
}

export default Navbar;
