import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomSelect2 from "../../general-components/CustomSelect2.component";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";

export default function EditClientDetails({
  cName, setCName,
  mobile, setMobile,
  parentCId, setParentCId,
  landLineNumber, setLandLineNumber,
  customerId, setCustomerId,
  parentCName, setParentCName,
  customerName, setCustomerName,
  mainCountryData, mainCountryId, setMainCountryId,
  mainCountryName, setMainCountryName,
  next, onCancel
}) {
  const [custType, setCustType] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileError, setMobileError] = useState('');
  const [cNameError, setCNameError] = useState('');
  const token = localStorage.getItem('DIYAN_IE_authToken');

  const dataActive = { searchText: "", isActive: true };
  const data = { searchText: "", customerId: 0, leadStatusId: 0, parentCustomerId: 0, isActive: true };

  // Generic validation function
  const validateInput = (value, setError, regex = null, errorMessage = 'This field is required') => {
    if (!value) {
      setError(errorMessage);
      return false;
    } else if (regex && !regex.test(value)) {
      setError('Invalid format');
      return false;
    } else {
      setError('');
      return true;
    }
  };

  const handleNextClick = () => {
    const isNameValid = validateInput(cName, setCNameError);
    const isMobileValid = validateInput(mobile, setMobileError, /^[0-9\b]+$/, 'Mobile number only allows numbers');

    if (isNameValid && isMobileValid) next();
  };

  const fetchApiData = async () => {
    setIsLoading(true);
    try {
      const [custTypeResponse, customerListResponse] = await Promise.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCustomerTypeList`, dataActive, {
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        }),
        axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`, data, {
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        }),
      ]);

      if (custTypeResponse.data.isSuccess && Array.isArray(custTypeResponse.data.data)) {
        setCustType(custTypeResponse.data.data);
      } else {
        console.log("Invalid customer type response format");
      }

      if (customerListResponse.data.isSuccess && Array.isArray(customerListResponse.data.data)) {
        setCustomerData(customerListResponse.data.data);
      } else {
        console.log("Invalid customer list response format");
      }
    } catch (error) {
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  const handleSelectChange = (setter, labelSetter) => (selectedOption) => {
    if (selectedOption) {
      setter(selectedOption.value);
      labelSetter(selectedOption.label);
    } else {
      setter(null);
      labelSetter('');
    }
  };

  return (
    <>
      <div className="p-2 mx-auto">
        <table className="max-w-full popup-table w-[900px]">
          <tbody>
            <tr>
              <td>
                <div className="flex flex-col gap-1">
                  <label htmlFor="cName" className="text-xs font-[400]">
                    Customer Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={cName}
                    className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                    id="cName"
                    placeholder="Enter"
                    onChange={(e) => setCName(e.target.value)}
                    onBlur={() => validateInput(cName, setCNameError)}
                  />
                  {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                </div>
              </td>
              <td>
                <div className="flex flex-col gap-1">
                  <label htmlFor="mobile" className="text-xs font-[400]">
                    Phone Number<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={mobile}
                    className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                    id="mobile"
                    placeholder="Enter"
                    onChange={(e) => setMobile(e.target.value)}
                    onBlur={() => validateInput(mobile, setMobileError, /^[0-9\b]+$/, 'Mobile number only allows numbers')}
                  />
                  {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <CustomTextField2
                  label={"Parent Customer"}
                  placeholder={"ENTER"}
                  value={parentCName}
                  readOnly={true}
                ></CustomTextField2>
              </td>
              <td>
                <div>
                  <CustomNumber
                    label={"Landline Number"}
                    placeholder={"ENTER"}
                    value={landLineNumber}
                    onChange={setLandLineNumber}
                    isRequired={false}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <CustomSelect2
                  label={"Customer Type"}
                  placeholder={"Select..."}
                  options={custType.map(c => ({ value: c.id, label: c.customerType }))}
                  value={{ value: customerId, label: customerName }}
                  onChange={handleSelectChange(setCustomerId, setCustomerName)}
                  isClearable
                  isRequired={false}
                />
              </td>
              <td>
                <CustomSelect2
                  label={"Country"}
                  placeholder={"Select..."}
                  options={mainCountryData.map(c => ({ value: c.id, label: c.countryName }))}
                  value={{ value: mainCountryId, label: mainCountryName }}
                  onChange={handleSelectChange(setMainCountryId, setMainCountryName)}
                  isClearable
                  isRequired={false}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center gap-5 mt-5">
        <div onClick={handleNextClick}>
          <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
        </div>
        <div onClick={onCancel}>
          <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
}
