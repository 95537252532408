// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomImageUpload from "../../general-components/CustomImageUpload.component";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomDate from "../../general-components/CustomDate.component";

import { useAuth } from "../../../context";

// Function to add months to a date
const addMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result.toISOString().split('T')[0]; // Format as YYYY-MM-DD
};

const ManageCompanyDetailAdd = ({ onCancel }) => {
  const { user } = useAuth()

  let [checkboxCompanyNameAdd, setCheckboxCompanyNameAdd] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [compName, setCompName] = useState("");
  const [regNo, setRegNo] = useState("");
  const [conNo, setConNo] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [gstNo, setGSTNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [texNo, setTaxNo] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [userNo, setUserNo] = useState("");
  const [branchNo, setBranchNo] = useState("");
  const [img, setImg] = useState("");

  const [amcMonth, setAmcMonth] = useState("");
  const [amcStartDate, setAmcStartDate] = useState("");
  const [amcEndDate, setAmcEndDate] = useState("");

  const [allTerData, setAllTerData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const [compTypeData, setCompTypeData] = useState([]);
  const [selectedCompType, setSelectedCompType] = useState("");

  const token = localStorage.getItem('DIYAN_IE_authToken');
  const data = { searchText: "" };

  const fetchData = async (url, setData, errorMsg) => {
    setIsLoading(true);
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/CompanyType/GetCompanyTypeList`, setCompTypeData, "Error fetching Company Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");
  }, []);

  // Update AMC End Date whenever AMC Month or AMC Start Date changes
  useEffect(() => {
    if (amcStartDate && amcMonth && !isNaN(amcMonth)) {
      const endDate = addMonths(new Date(amcStartDate), parseInt(amcMonth, 10));
      setAmcEndDate(endDate);
    }
  }, [amcStartDate, amcMonth]);

  const handleCompTypeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCompType(selectedOption.value);
    } else {
      setSelectedCompType(null); // Reset to null when cleared
    }
  }

  const handleRegionChange = (selectedOption) => {

    if (selectedOption) {
      setSelectedCountry(selectedOption.value);
      setSelectedState(null); // Reset state when country changes
      setSelectedDistrict(null); // Reset district when country changes
    } else {
      setSelectedCountry(null); // Reset to null when cleared
      setSelectedState(null); // Reset state when country changes
      setSelectedDistrict(null); // Reset district when country changes
    }
  }

  const handleStateChange = (selectedOption) => {

    if (selectedOption) {
      setSelectedState(selectedOption.value);
      setSelectedDistrict(null); // Reset district when state changes
    } else {
      setSelectedState(null); // Reset to null when cleared
      setSelectedDistrict(null); // Reset district when state changes
    }
  }

  const handleDistrictChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedDistrict(selectedOption.value);
    } else {
      setSelectedDistrict(null); // Reset to null when cleared
    }
  }

  useEffect(() => {
    // Set default start date to current date
    const currentDate = new Date();
    const formattedStartDate = currentDate.toISOString().split("T")[0];

    // Calculate end date by adding 1 day to the start date
    const nextDayDate = new Date(currentDate);
    nextDayDate.setDate(currentDate.getDate() + 1);
    const formattedEndDate = nextDayDate.toISOString().split("T")[0];

    setAmcStartDate(formattedStartDate);
    // setAmcEndDate(formattedEndDate);
  }, []);

  const handleRoleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        companyName: compName,
        companyTypeId: selectedCompType || 0,
        registrationNumber: regNo || "",
        contactNumber: conNo || "",
        email: email || "",
        website: website || "",
        taxNumber: texNo || "",
        addressLine1: address || "",
        countryId: selectedCountry || 0,
        stateId: selectedState || 0,
        districtId: selectedDistrict || 0,
        pincode: pinCode || "",
        gstNumber: gstNo || "",
        panNumber: panNo || "",
        companyLogoFileName: img.name || "",
        companyLogo_Base64: img.base64 || "",
        noofUserAdd: userNo || 0,
        noofBranchAdd: branchNo || 0,
        isActive: checkboxCompanyNameAdd,
        // Conditionally add AMC fields if user roleId is 1
        ...(user?.roleId === 1 ? {
          amcMonth: amcMonth || 0,
          amcStartDate: amcStartDate,
          amcEndDate: amcEndDate
        } : {})
      };

      console.log("request-body-->", requestBody);


      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Company/SaveCompany`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        if (responseData.message === "Record is already exists") {
          toast.error("Duplicate entry is not permissable");
        } else {
          toast.success("Record Inserted successfully");
          setCompName("");
          setRegNo("");
          setConNo("");
          setEmail("");
          setWebsite("");
          setGSTNo("");
          setPanNo("");
          setTaxNo("");
          setAddress("");
          setPinCode("");
          setUserNo("");
          setBranchNo("");
          setImg("");

          setAmcMonth("");
          setAmcStartDate("");
          setAmcEndDate("");
          setSelectedCountry(null);
          setSelectedState(null);
          setSelectedDistrict(null);
          setSelectedCompType("");
        }

        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          // window.location.href = "/admin/admin-company-details";
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing Manage Company  operation:", error);
      toast.error("Error performing Manage Company  operation");
    } finally {
      setIsLoading(false);
    }
  };

  const uniqueBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  };

  const getCountries = () => {
    return uniqueBy(allTerData, 'countryId').map(data => ({
      value: data.countryId,
      label: data.countryName
    }));
  };

  const getStatesForCountry = () => {
    return uniqueBy(allTerData.filter(data => data.countryId === selectedCountry), 'stateId').map(data => ({
      value: data.stateId,
      label: data.stateName
    }));
  };

  const getDistrictsForState = () => {
    return uniqueBy(allTerData.filter(data => data.stateId === selectedState), 'districtId').map(data => ({
      value: data.districtId,
      label: data.districtName
    }));
  };

  return (
    <>
      <div className="pb-5 bg-white rounded-lg">
        <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
          <h1 className="text-xl font-weight-[400]">Edit Company</h1>
          <div className="flex item-center text-sm"></div>
        </div>

        <form onSubmit={handleRoleSubmit}>
          <div className="p-2 mx-auto overflow-scroll overflow-x-auto" style={{ maxHeight: "500px" }}>
            <table className="max-w-full popup-table w-[1050px]">
              <tbody>
                <tr>
                  <td>
                    <CustomTextField2 label={"Company Name"} placeholder={"ENTER"}
                      value={compName}
                      onChange={setCompName}
                    />
                  </td>
                  <td>
                    <CustomSelect1 label={"Company type"} placeholder={"ENTER"}
                      options={compTypeData.map(data => ({
                        value: data.id,
                        label: data.companyType
                      }))}
                      onChange={handleCompTypeChange}
                    />
                  </td>
                  <td>
                    <CustomAlphatext label={"Registration number"} placeholder={"ENTER"}
                      value={regNo}
                      onChange={setRegNo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomNumber label={"Contact Number"} placeholder={"ENTER"}
                      value={conNo}
                      onChange={setConNo}
                    />
                  </td>
                  <td>
                    <CustomEmail label="Email" placeholder="Enter"
                      value={email}
                      onChange={setEmail}
                    />
                  </td>
                  <td>
                    <CustomTextField2 label={"Website"} placeholder={"ENTER"}
                      value={website}
                      onChange={setWebsite}
                    />
                  </td>
                </tr>
                {user?.roleId === 1 && (
                  <tr>
                    <td>
                      <CustomInputNumber
                        label={"AMC Month"}
                        placeholder={"ENTER"}
                        value={amcMonth}
                        onChange={(value) => setAmcMonth(value)}
                        isRequired={false}
                      />
                    </td>
                    <td>
                      <CustomDate
                        label="AMC Start Date"
                        placeholder="Enter"
                        value={amcStartDate}
                        onChange={(value) => setAmcStartDate(value)}
                        isRequired={false}
                      />
                    </td>
                    <td>
                      <CustomDate
                        label="AMC End Date"
                        placeholder="Enter"
                        value={amcEndDate}
                        isRequired={false}
                        readOnly // End date should not be editable
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <CustomAlphatext label={"GST Number"} placeholder={"ENTER"}
                      value={gstNo}
                      onChange={setGSTNo}
                    />
                  </td>
                  <td>
                    <CustomAlphatext label={"Pan Number"} placeholder={"ENTER"}
                      value={panNo}
                      onChange={setPanNo}
                    />
                  </td>
                  <td>
                    <CustomAlphatext label={"Tax Number / CIN"} placeholder={"ENTER"}
                      value={texNo}
                      onChange={setTaxNo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomTextArea label={"Address"} placeholder={"ENTER"}
                      value={address}
                      onChange={setAddress}
                    />
                  </td>
                  <td>
                    <CustomSelect1 label={"Country"} placeholder={"ENTER"}
                      isRequired={false}
                      options={getCountries()}
                      onChange={handleRegionChange}
                    />
                  </td>
                  <td>
                    <CustomSelect1 label={"State"} placeholder={"ENTER"}
                      isRequired={false}
                      options={selectedCountry ? getStatesForCountry() : []}
                      onChange={handleStateChange}
                      isDisabled={!selectedCountry}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomSelect1 label={"District / Province"} placeholder={"ENTER"}
                      isRequired={false}
                      options={selectedState ? getDistrictsForState() : []}
                      onChange={handleDistrictChange}
                      isDisabled={!selectedState}
                    />
                  </td>
                  <td>
                    <CustomInputNumber label={"Pin code"} placeholder={"ENTER"}
                      value={pinCode}
                      onChange={setPinCode}
                    />
                  </td>
                  <td>
                    <CustomInputNumber label={"Total Number of User"} placeholder={"ENTER"}
                      value={userNo}
                      onChange={setUserNo}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomInputNumber label={"Total branches"} placeholder={"ENTER"}
                      value={branchNo}
                      onChange={setBranchNo}
                    />
                  </td>
                  <td>
                    <CustomImageUpload id="image-upload-mnc-add" label="Image Upload"
                      value={img}
                      onChange={setImg}
                    />
                  </td>
                  <td>
                    <CustomCheckBox2
                      label={"Is Active"}
                      state={checkboxCompanyNameAdd}
                      setState={setCheckboxCompanyNameAdd}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-center gap-5 pt-3  border-t">
            <div>
              <CustomButton1 label={isLoading ? "Loading" : "Submit"} className="text-white bg-prp-color" type="Submit" />
            </div>
            <div onClick={onCancel}>
              <CustomButton2 label={"Cancel"} variant="outlined" className="text-prp-color" />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default ManageCompanyDetailAdd;

