import * as React from "react";
import { useState } from "react";
import Select from 'react-select';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";

export default function EditBillingDetailsEditPopup({
    currentCustomerId,
    billingIdEdit,
    streetName,
    setStreetName,
    countryId,
    setCountryId,
    countryName,
    setCountryName,
    onCancel,
    allTerData,
}) {

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const [isLoading, setIsLoading] = useState(false);

    const handleCountryChangeNew = (selectedOption) => {
        if (selectedOption) {
            setCountryId(selectedOption.value);
            setCountryName(selectedOption.label);
        } else {
            setCountryId(null);
            setCountryName('');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                id: billingIdEdit,
                customerId: currentCustomerId,
                streetName: streetName || "",
                countryId: countryId || 0,
                // stateId: stateId || 0,
                // postalZipCodde: postalZipCodde || "",
                isActive: true
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Customer/SaveBillingDetails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                // console.log("Record Inserted successfully:", responseData.message);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing customer operation:", error);
            toast.error("Error performing customer operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Billing Details</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[700px]">
                        <tbody>
                            <tr className="w-full">
                                <td className="w-1/2">
                                    <CustomTextArea
                                        isRequired={false}
                                        label={"Street"}
                                        placeholder={"ENTER"}
                                        value={streetName}
                                        onChange={setStreetName}
                                    ></CustomTextArea>
                                </td>
                                <td className="w-1/2">
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="id" className="text-xs font-400 ">
                                            Country
                                        </label>
                                        <Select
                                            options={allTerData.map(data => ({
                                                value: data.id,
                                                label: data.countryName
                                            }))}
                                            value={{ value: countryId, label: countryName }} // Set initial value here
                                            className="text-black text-xs select-bar"
                                            id="departmentId"
                                            isClearable
                                            isSearchable
                                            onChange={handleCountryChangeNew}
                                            menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                menu: base => ({
                                                    ...base,
                                                    fontSize: '12px', // Adjust font size for the entire menu
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    fontSize: '12px', // Adjust font size for each option
                                                    backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                    color: state.isSelected ? 'black' : base.color,
                                                }),
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#e5e7eb',
                                                },
                                            })}
                                        />
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />

        </>
    )
}
