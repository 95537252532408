// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";

export default function ViewOrderAccepted({ selectedValue }) {
    const oA_OrderAcceptedDate = selectedValue?.oA_OrderAcceptedDate ? new Date(selectedValue.oA_OrderAcceptedDate).toLocaleDateString('en-GB') : 'N/A';
    const oA_IsOrderAccepted = selectedValue?.oA_IsOrderAccepted ? 'True' : 'False';

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';
    
        const date = new Date(dateString);
        const options = { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="Tentative date of production " value={oA_OrderAcceptedDate} />
                    <CustomViewLabel label="Order Accepted Close" value={selectedValue?.oA_IsOrderAccepted} />
                    <CustomViewLabel label="Order Accepted Close Date" value={formatDateTest1(selectedValue?.oA_OrderAcceptedClosedDate)} />
                </div>
            </div>
        </>
    )
}
