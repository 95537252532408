import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomInputNumber from "../../general-components/CustomInputNumber.component"
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomEmail from "../../general-components/CustomEmail.component";
import CustomTextArea from "../../general-components/CustomTextArea.component";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomNumber from '../../general-components/CustomNumber.component';
import CustomCheckBox3 from '../../general-components/CustomCheckBox3.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from "../../general-components/CustomButton2.component";
import { useAuth } from "../../../context";

export default function ManageBranchDetailsAdd({ onCancel }) {
    const { user } = useAuth()

    const [checkboxBranchNameAdd, setCheckboxBranchNameAdd] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [bName, setBname] = useState("");
    const [moNo, setMoNo] = useState("");
    const [email, setEmail] = useState("");
    const [depHead, setDepHead] = useState("");
    const [adrs, setAdrs] = useState("");
    const [pincode, setPincode] = useState("");
    const [bUser, setBUser] = useState("");

    const [compData, setCompData] = useState([]);
    const [selectedComp, setSelectedComp] = useState("");

    const [allTerData, setAllTerData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const datacomp = { searchText: "", companyId: 0 };
    const userInfo = JSON.parse(localStorage.getItem('user'));

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, datacomp, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // fetchData(`${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`, setCompData, "Error fetching Company Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`, setAllTerData, "Error fetching Territories Data");

        if (userInfo.userId === 1) {
            fetchData(`${process.env.REACT_APP_BASE_URL}/Company/GetCompanyList`, setCompData, "Error fetching Company Data");
        } else {
            setSelectedComp(userInfo.companyId);
        }
    }, []);

    const handleCompChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedComp(selectedOption.value);
        } else {
            setSelectedComp(null); // Reset to null when cleared
        }
    }

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        } else {
            setSelectedCountry(null);
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedState(selectedOption.value);
            setSelectedDistrict(null); // Reset district when state changes
        } else {
            setSelectedState(null); // Reset state when country changes
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleDistrictChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedDistrict(selectedOption.value);
        } else {
            setSelectedDistrict(null); // Reset district when country changes
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                branchName: bName,
                companyId: selectedComp || userInfo.companyId,
                emailId: email,
                mobileNo: moNo,
                departmentHead: depHead,
                addressLine1: adrs,
                countryId: selectedCountry || 0,
                stateId: selectedState || 0,
                districtId: selectedDistrict || 0,
                pincode: pincode || "",
                isActive: checkboxBranchNameAdd,
                ...(user?.roleId === 1 ? {
                    noofUserAdd: bUser,
                } : {})
            };

            console.log("api-response-->", requestBody);


            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Branch/SaveBranch`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                } else {
                    toast.success("Record Inserted successfully");
                    setBname("");
                    setMoNo("");
                    setEmail("");
                    setDepHead("");
                    setAdrs("");
                    setPincode("");
                    setBUser("");
                    setSelectedComp("");
                    setSelectedCountry(null);
                    setSelectedState(null);
                    setSelectedDistrict(null);
                    setCheckboxBranchNameAdd(true);
                }

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Manage Branch operation:", error);
            toast.error("Error performing Manage Branch operation");
        } finally {
            setIsLoading(false);
        }
    };

    const uniqueBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    };

    const getCountries = () => {
        return uniqueBy(allTerData, 'countryId').map(data => ({
            value: data.countryId,
            label: data.countryName
        }));
    };

    const getStatesForCountry = () => {
        return uniqueBy(allTerData.filter(data => data.countryId === selectedCountry), 'stateId').map(data => ({
            value: data.stateId,
            label: data.stateName
        }));
    };

    const getDistrictsForState = () => {
        return uniqueBy(allTerData.filter(data => data.stateId === selectedState), 'districtId').map(data => ({
            value: data.districtId,
            label: data.districtName
        }));
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-xl font-weight-[400]">Add Branch Details</h1>
                    <div className="flex  item-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-2 mx-auto">
                        <table className="max-w-full popup-table w-[1050px]">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField2
                                            label={"Branch Name"}
                                            placeholder={"ENTER"}
                                            value={bName}
                                            onChange={setBname}
                                        ></CustomTextField2>
                                    </td>
                                    <td>
                                        {/* <CustomSelect1 label={"Company"} placeholder={"ENTER"}
                                            options={compData.map(data => ({
                                                value: data.id,
                                                label: data.companyName
                                            }))}
                                            onChange={handleCompChange}
                                        /> */}
                                        {userInfo.userId === 1 ? (
                                            <CustomSelect1
                                                label={"Company"}
                                                placeholder={"Enter"}
                                                options={compData.map(data => ({
                                                    value: data.id,
                                                    label: data.companyName
                                                }))}
                                                onChange={handleCompChange}
                                            />
                                        ) : (

                                            <CustomTextField2
                                                label={"Company"}
                                                placeholder={"ENTER"}
                                                value={userInfo.companyName}
                                                // onChange={setDepHead}
                                                readOnly={true}
                                            ></CustomTextField2>
                                        )}
                                    </td>
                                    <td>
                                        <CustomNumber label="Mobile Number" placeholder="Enter"
                                            value={moNo}
                                            onChange={setMoNo}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomEmail label="Email" placeholder="Enter"
                                            value={email}
                                            onChange={setEmail}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField2
                                            label={" Branch Head  "}
                                            placeholder={"ENTER"}
                                            value={depHead}
                                            onChange={setDepHead}
                                        ></CustomTextField2>
                                    </td>
                                    <td>
                                        <CustomTextArea
                                            label={" Address "}
                                            placeholder={"ENTER"}
                                            value={adrs}
                                            onChange={setAdrs}
                                        ></CustomTextArea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomSelect1 label={"Country"} placeholder={"ENTER"}
                                            isRequired={false}
                                            options={getCountries()}
                                            onChange={handleCountryChange}
                                        />
                                    </td>
                                    <td>
                                        <CustomSelect1 label={"State"} placeholder={"ENTER"}
                                            isRequired={false}
                                            options={selectedCountry ? getStatesForCountry() : []}
                                            onChange={handleStateChange}
                                            isDisabled={!selectedCountry}
                                        />
                                    </td>
                                    <td>
                                        <CustomSelect1 label={"District / Province"} placeholder={"ENTER"}
                                            isRequired={false}
                                            options={selectedState ? getDistrictsForState() : []}
                                            onChange={handleDistrictChange}
                                            isDisabled={!selectedState}
                                        />
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        <CustomInputNumber
                                            label={" PinCode "}
                                            placeholder={"Enter"}
                                            value={pincode}
                                            onChange={setPincode}
                                        ></CustomInputNumber>
                                    </td>
                                    {user?.roleId === 1 && (
                                        <td>
                                            <CustomInputNumber
                                                label={"Branch Total User Allow"}
                                                placeholder={"Enter"}
                                                value={bUser}
                                                onChange={setBUser}
                                            ></CustomInputNumber>
                                        </td>
                                    )}
                                    <td >
                                        <div className="mt-5">
                                            <CustomCheckBox3
                                                label={"Is Active"}
                                                state={checkboxBranchNameAdd}
                                                setState={setCheckboxBranchNameAdd}
                                            ></CustomCheckBox3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-center gap-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading" : "Submit"} className="text-white bg-first" type="Submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
