// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomButton4 from "../components/general-components/CustomButton4.component";

// ICONS
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch, AiOutlineImport, AiOutlineDownload } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

import ManageTerritoryAdd from "../components/forms-components/ManageTerritory/ManageTerritoryAdd";
import ManageTerritoryEdit from "../components/forms-components/ManageTerritory/ManageTerritoryEdit";
import ManageTerritoryView from "../components/forms-components/ManageTerritory/ManageTerritoryView";

import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";
import { Button, Tooltip } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Pagination from "../components/general-components/Pagination.component";

function ManageTerritory() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE ADMIN TERRITORY"))
        }
    }, [user])
    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);
    let [popupAreaView, setPopupAreaView] = useState(false);
    let [checkboxEdit, setCheckboxEdit] = useState(true);
    const [Territories, setRolesTerritories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedTerritory, setSelectedTerritory] = useState([])
    const [countryName, setCountryName] = useState("")
    const [stateName, setStateName] = useState("")
    const [districtName, setDistrictName] = useState("")
    const [countryId, setCountryId] = useState("")
    const [stateId, setStateId] = useState("")
    const [districtId, setDistrictId] = useState("")

    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = localStorage.getItem('DIYAN_IE_authToken');

    const fetchTerritories = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesList`,
                // data,
                { searchText: searchQuery }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });

                setRolesTerritories(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Territories");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditChange = (trId) => {
        fetchTerritoriesById(trId);
        setPopupEdit(true);
    }

    const handleViewChange = (trId) => {
        fetchTerritoriesById(trId);
        setPopupAreaView(true);
    }

    const fetchTerritoriesById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Territory/GetTerritoriesById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                const trrData = response.data.data;
                setSelectedTerritory(trrData);
                setCountryId(trrData.countryId);
                setStateId(trrData.stateId);
                setDistrictId(trrData.districtId);

                setCountryName(trrData.countryName);
                setStateName(trrData.stateName);
                setDistrictName(trrData.districtName);
                setCheckboxEdit(trrData.isActive)
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Teritorries Data details");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedTerritories = Territories.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchTerritories(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(Territories.length / rowsPerPage));
    }, [Territories, rowsPerPage]);


    const excelDownload = async () => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Territory/ExportTerritoriesData`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess) {
                const base64Data = response.data.data;
                const binaryString = atob(base64Data);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'territories.xlsx'); // Set the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                toast.success(response.data.message);
            } else {
                toast.error("Failed to export territories data.");
            }
        } catch (error) {
            toast.error("Error fetching Territories Data details");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            // Example API call - replace with your actual API call
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Territory/DownloadTerritoriesTemplate`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const result = response.data; // Axios already parses JSON response

            if (result.isSuccess && result.data) {
                // Convert base64 to blob
                const byteCharacters = atob(result.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a link and trigger download
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'template.xlsx'; // Adjust the filename as necessary
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Failed to download the file');
            }
        } catch (error) {
            console.error('Error while downloading the template:', error);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            await handleFileUpload(file);
        }
    };

    const handleFileUpload = async (file) => {
        const token = localStorage.getItem('DIYAN_IE_authToken');
        const formData = new FormData();
        formData.append("FileUpload", file); // Change "file" to "FileUpload"

        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Territory/ImportTerritories`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.isSuccess) {
                toast.success("File uploaded successfully.");
                fetchTerritories();
            } else {
                toast.error("File upload failed.");
            }
        } catch (error) {
            toast.error("An error occurred during the file upload.");
            console.error("File upload error:", error);
        } finally {
            setIsLoading(false);
            setSelectedFile(null);
        }
    };

    const handleClick = () => {
        document.getElementById("upload-file-input").click();
    };

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div className="flex flex-col h-full uppercase">
                <div className="p-6 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Territories"} title2={"ADMINISTRATOR"} />

                    <div className="  flex md:justify-between items-center flex-row flex-wrap justify-start gap-1 mt-5 md:flex-nowrap pb-5">
                        <Button
                            variant={"contained"}
                            size={"small"}
                            className={"flex gap-2 justify-center items-center relative uppercase"}
                            type={"button"}
                            style={{ backgroundColor: "#643c94" }}
                            onClick={excelDownload}
                        >
                            Excel
                        </Button>

                        <div className="flex flex-wrap items-center justify-between gap-3  ml-auto">
                            <div className="flex flex-col h-full uppercase">
                                <div className="">
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="upload-file-input"
                                    />
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className="flex gap-2 justify-center items-center relative uppercase"
                                        type="button"
                                        style={{ backgroundColor: "#643c94" }}
                                        onClick={handleClick}
                                    >
                                        <span className="text-sm"><AiOutlineImport /></span>
                                        <span className="whitespace-nowrap font-[400] font-Mitr text-white">
                                            Import Territories
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div onClick={handleDownload}>
                                <CustomButton1
                                    label={"Sample Download "}
                                    icon={<AiOutlineDownload />}
                                    className=" bg-first text-white grow "
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">
                        {/* 4 BUTTONS */}
                        <div className="flex flex-row flex-wrap justify-start gap-2  md:flex-nowrap minbox">
                            {Permission?.add &&
                                <CustomButton1
                                    label={"Add"}
                                    icon={<IoMdAdd />}
                                    className="bg-first text-white shrink grow md:grow-0 max-w-[50%]"
                                    onClick={() => setPopupAdd(true)}
                                />}

                            <div className="flex items-center justify-between gap-1 px-2 ml-auto bg-white rounded  shrink grow md:grow-0">
                                <input
                                    type="text"
                                    className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
                                    placeholder="SEARCH"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText ? (
                                    <IoMdCloseCircle
                                        className="min-w-[20px] cursor-pointer txt-prp-color"
                                        onClick={clearSearch}
                                    />
                                ) : (
                                    <AiOutlineSearch className="min-w-[20px]" />
                                )}
                            </div>
                        </div>

                        {Permission?.view &&
                            <>
                                {/* TABLE */}
                                <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                    <table className="w-full custom-table" border={1}>
                                        <thead>
                                            <tr className="table-heading">
                                                <td className="">S.No.</td>
                                                {(Permission?.view === true || Permission?.edit === true) &&
                                                    <td>Action</td>}
                                                <td>Status</td>
                                                <td>Country</td>
                                                <td>State</td>
                                                <td>District / Province</td>
                                                <td>Created Date </td>
                                                <td>Created By </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center">Loading...</td>
                                                </tr>
                                            ) : (paginatedTerritories.length > 0 ? (
                                                paginatedTerritories.map((trData, index) => (
                                                    <tr key={trData.id}>
                                                        <td>{index + 1}</td>
                                                        {(Permission?.view === true || Permission?.edit === true) &&
                                                            <td>
                                                                <div className="gap-2">
                                                                    {Permission?.edit &&
                                                                        <Tooltip title="EDIT" arrow placement="left">
                                                                            <span>
                                                                                <CustomButton1
                                                                                    icon={<BiEdit />}
                                                                                    className="bg-sixt text-white grow max-w-[40px]"
                                                                                    onClick={() => handleEditChange(trData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                    {Permission?.view &&
                                                                        <Tooltip title="VIEW" arrow placement="right">
                                                                            <span>
                                                                                <CustomButton4
                                                                                    icon={<BiEdit />}
                                                                                    className="bg-eye text-white grow max-w-[40px]"
                                                                                    onClick={() => handleViewChange(trData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>}
                                                                </div>
                                                            </td>}
                                                        <td>
                                                            <div>
                                                                <h2 style={{ color: trData.isActive ? 'green' : 'red' }}>{trData.isActive ? 'Active' : 'Inactive'}</h2>
                                                            </div>
                                                        </td>
                                                        <td>{trData.countryName}</td>
                                                        <td>{trData.stateName}</td>
                                                        <td>{trData.districtName}</td>
                                                        <td>{new Date(trData.createdDate).toLocaleDateString('en-GB')}</td>
                                                        <td>{trData.creatorName}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                searchText?.trim() ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center py-4">
                                                            No results found for "{searchText}".
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9" className="text-center py-4">
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    currentPage={currentPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    totalPages={totalPages}
                                    setCurrentPage={setCurrentPage}
                                    data={Territories}
                                />
                            </>
                        }
                    </div>

                    {popupAdd && (
                        <PopUp>
                            <ManageTerritoryAdd
                                onCancel={() => {
                                    fetchTerritories();
                                    setPopupAdd(false)
                                }}
                            />
                        </PopUp>
                    )}

                    {popupEdit && (
                        <PopUp>
                            <ManageTerritoryEdit
                                selectedTerritory={selectedTerritory}
                                countryId={countryId}
                                stateId={stateId}
                                districtId={districtId}

                                setCountryId={setCountryId}
                                setStateId={setStateId}
                                setDistrictId={setDistrictId}

                                countryName={countryName}
                                stateName={stateName}
                                districtName={districtName}

                                setCountryName={setCountryName}
                                setStateName={setStateName}
                                setDistrictName={setDistrictName}

                                checkboxEdit={checkboxEdit}
                                setCheckboxEdit={setCheckboxEdit}
                                onCancel={() => {
                                    fetchTerritories();
                                    setPopupEdit(false)
                                }}
                            />
                        </PopUp>
                    )}

                    {popupAreaView && (
                        <PopUp>
                            <ManageTerritoryView
                                trData={selectedTerritory}
                                onCancel={() => setPopupAreaView(false)}
                            />
                        </PopUp>
                    )}
                </div>
            </div >
            <ToastContainer autoClose={2000} />
        </>

    );
}

export default ManageTerritory;