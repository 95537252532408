// CORE
import * as React from "react";

// COMPONENTS
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewContainersUnderLoading({ selectedValue }) {
    const cuL_IsContainersUnderLoadingClose = selectedValue?.cuL_IsContainersUnderLoadingClose ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const cuL_ContainersUnderLoadingClosedDate = formatDateTest(selectedValue?.cuL_ContainersUnderLoadingClosedDate);

    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">

                    {/* Mapping through containersUnderLoadingList array to display each item */}
                    {selectedValue?.containersUnderLoadingList?.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label={`Container Number ${index + 1}`} value={item.containerCount} />

                            <div>
                                {item.containersUnderLoadingImagesList.map((image, imgIndex) => (
                                    <React.Fragment key={imgIndex}>
                                        <CustomFileViewer fileURL={image.containerImageURL} fileName={image.containerOriginalFileName ?? ''} label={`Container Under Loading Images ${imgIndex + 1}`} />
                                    </React.Fragment>
                                ))}
                            </div>

                        </React.Fragment>
                    ))}
                </div>

                <div className="max-w-full grid grid-cols-2 mt-4 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Container Under Loading Closed Date" value={cuL_ContainersUnderLoadingClosedDate} />
                    <CustomViewLabel label="Container Under Loading Closed" value={cuL_IsContainersUnderLoadingClose} />
                    <CustomViewLabel label="Container Under Loading Closed Date & Time" value={formatDateTest1(selectedValue?.cuL_ContainersUnderLoadingClosedDateTime)} />
                </div>
            </div>
        </>
    )
}
