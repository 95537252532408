// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Tooltip } from "@mui/material";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import PopUp from "../components/general-components/PopUp.componenet";
import CustomButton4 from "../components/general-components/CustomButton4.component";
import Pagination from "../components/general-components/Pagination.component";

// ICONS
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSinglePermission } from "../../Helpers/Constants";
import { useAuth } from "../context";

import ManageBranchDetailsAdd from "../components/forms-components/ManageBranchDetails/ManageBranchDetailsAdd";
import ManageBranchDetailsEdit from "../components/forms-components/ManageBranchDetails/ManageBranchDetailsEdit";
import ManageBranchDetailsView from "../components/forms-components/ManageBranchDetails/ManageBranchDetailsView";

function ManageBranchDetails() {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "ADMIN BRANCH"))
        }
    }, [user])
    let [popupBranchDetailsAdd, setPopupBranchDetailsAdd] = useState(false);
    let [popupBranchNameEdit, setPopupBranchNameEdit] = useState(false);
    let [popupBranchNameView, setPopupBranchNameView] = useState(false);

    const [branchDetails, setBranchDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranchDetail, setSelectedBranchDetail] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState("");

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const fetchBranchData = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Branch/GetBranchList`,
                // data, 
                { searchText: searchQuery, companyId: 0 }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });
                setBranchDetails(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Company Details Group");
        } finally {
            setIsLoading(false);
        }
    };

    const setPopupBranchView = (id) => {
        fetchBranchById(id);
        setPopupBranchNameView(true);
    }

    const setPopupBranchEdit = (id) => {
        fetchBranchById(id);
        setPopupBranchNameEdit(true)
    }

    const fetchBranchById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Branch/GetBranchById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                const cData = response.data.data
                setSelectedBranchDetail(cData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Branch details");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedBranchDetails = branchDetails.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useEffect(() => {
        fetchBranchData(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(branchDetails.length / rowsPerPage));
    }, [branchDetails, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div className="flex flex-col h-full  p-2 uppercase">
                <div className="p-4 mt-2 bg-white rounded grow" style={{ border: "1px solid #3D3D3D66" }}>
                    <Title title1={"Admin Branch"} title2={"ADMINISTRATOR"} />

                    {/* 4 BUTTONS + TABLE */}
                    <div className="px-2 lg:px-4 mt-5">
                        {/* 4 BUTTONS */}
                        <div className="flex flex-row flex-wrap justify-start gap-2  md:flex-nowrap  minbox">
                            {Permission?.add && <CustomButton1
                                label={"Add"}
                                icon={<IoMdAdd />}
                                className="custmbtn text-fourth shrink grow md:grow-0 max-w-[50%]"
                                onClick={() => setPopupBranchDetailsAdd(true)}
                            />}

                            <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                                <input
                                    type="text"
                                    className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
                                    placeholder="SEARCH"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText ? (
                                    <IoMdCloseCircle
                                        className="min-w-[20px] cursor-pointer txt-prp-color"
                                        onClick={clearSearch}
                                    />
                                ) : (
                                    <AiOutlineSearch className="min-w-[20px]" />
                                )}
                            </div>
                        </div>

                        {Permission?.view &&
                            <>
                                {/* TABLE */}
                                <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                                    <table className="w-full custom-table" border={1}>
                                        <thead>
                                            <tr className="table-heading">
                                                <td className="">S.No</td>
                                                {(Permission?.edit || Permission?.view) && <td>Action</td>}
                                                <td>Status  </td>
                                                <td>Branch Name</td>
                                                <td>Company</td>
                                                <td>Mobile Number</td>
                                                <td>Email</td>
                                                <td>Department Head</td>
                                                <td>Address</td>
                                                <td>Country</td>
                                                <td>State</td>
                                                <td>District / Province</td>
                                                <td>PinCode</td>
                                                <td>Branch Total User Allow</td>
                                                <td>Created Date </td>
                                                <td>Created By </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan="16" className="text-start">Loading...</td>
                                                </tr>
                                            ) : (paginatedBranchDetails.length > 0 ? (
                                                paginatedBranchDetails.map((branchData, index) => (
                                                    <tr key={branchData.id}>
                                                        <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                        {(Permission?.edit || Permission?.view) &&
                                                            <td  >
                                                                <div className="gap-2">
                                                                    {Permission?.edit &&
                                                                        <Tooltip title="EDIT" arrow placement="left">
                                                                            <span>
                                                                                <CustomButton1
                                                                                    className="bg-sixt text-white grow max-w-[50px]"
                                                                                    icon={<BiEdit />}
                                                                                    onClick={() => setPopupBranchEdit(branchData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                    {Permission?.view &&
                                                                        <Tooltip title="VIEW" arrow placement="right">
                                                                            <span>
                                                                                <CustomButton4
                                                                                    className="bg-eye text-white grow max-w-[50px]"
                                                                                    icon={<BsEyeFill />}
                                                                                    onClick={() => setPopupBranchView(branchData.id)}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                </div>
                                                            </td>}
                                                        <td>
                                                            <div>
                                                                <h2 style={{ color: branchData.isActive ? 'green' : 'red' }}>{branchData.isActive ? 'Active' : 'Inactive'}</h2>
                                                            </div>
                                                        </td>
                                                        <td>{branchData.branchName}</td>
                                                        <td>{branchData.companyName}</td>
                                                        <td>{branchData.mobileNo}</td>
                                                        <td>{branchData.emailId}</td>
                                                        <td>{branchData.departmentHead}</td>
                                                        <td>{branchData.addressLine1}</td>
                                                        <td>{branchData.countryName}</td>
                                                        <td>{branchData.stateName}</td>
                                                        <td>{branchData.districtName}</td>
                                                        <td>{branchData.pincode}</td>
                                                        <td>{branchData.noofUserAdd}</td>
                                                        <td>{new Date(branchData.createdDate).toLocaleDateString('en-GB')}</td>
                                                        <td>{branchData.creatorName}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                searchText?.trim() ? (
                                                    <tr>
                                                        <td colSpan="16" className="text-start py-4">
                                                            No results found for "{searchText}".
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="16" className="text-start py-4">
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    currentPage={currentPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    totalPages={totalPages}
                                    setCurrentPage={setCurrentPage}
                                    data={branchDetails}
                                />
                            </>
                        }
                    </div>

                    {/* POPUP 1 : ADD */}
                    {popupBranchDetailsAdd && (
                        <PopUp>
                            <ManageBranchDetailsAdd
                                onCancel={() => {
                                    setPopupBranchDetailsAdd(false);
                                    fetchBranchData();
                                }}
                            />
                        </PopUp>
                    )}

                    {/* POPUP 2 : EDIT */}
                    {popupBranchNameEdit && (
                        <PopUp>
                            <ManageBranchDetailsEdit
                                selectedBranchDetail={selectedBranchDetail}
                                onCancel={() => {
                                    setPopupBranchNameEdit(false);
                                    fetchBranchData();
                                }}
                            />
                        </PopUp>
                    )}

                    {/* POPUP 3 : VIEW */}
                    {popupBranchNameView && (
                        <PopUp>
                            <ManageBranchDetailsView
                                selectedBranchDetail={selectedBranchDetail}
                                onCancel={() => setPopupBranchNameView(false)}
                            />
                        </PopUp>
                    )}
                </div>
            </div >

            <ToastContainer autoClose={2000} />
        </>
    );
}

export default ManageBranchDetails;
