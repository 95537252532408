// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from 'react-select';
import Button from "@mui/material/Button";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomizeSelectBar from "../../general-components/CustomizeSelectBar.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddRole from "../ManageTerritoryAddOptions/AddRole"
import AddState from "../ManageTerritoryAddOptions/AddState";
import AddDistrict from "../ManageTerritoryAddOptions/AddDistrict";
import CustomButton2 from "../../general-components/CustomButton2.component";


export default function ManageTerritoryEdit({ selectedTerritory, countryName, stateName, districtName, countryId, stateId, districtId, setCountryId, setStateId, setDistrictId, setCountryName, setStateName, setDistrictName, checkboxEdit, setCheckboxEdit, onCancel }) {

    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [distData, setDistData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [popupRole, setPopupRole] = useState(false);
    let [popupState, setPopupState] = useState(false);
    let [popupDistrict, setPopupDistrict] = useState(false);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "", isActive: true };

    const fetchCountry = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetCountryList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setCountryData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Country Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchState = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetStateList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setStateData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching State Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDist = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetDistrictList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setDistData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching District / Province Data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCountry();
        fetchState();
        fetchDist();
    }, []);

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setCountryName(selectedOption.label);
            setCountryId(selectedOption.value);
        } else {
            setCountryName(null);
            setCountryId("");
        }
    };
    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setStateName(selectedOption.label);
            setStateId(selectedOption.value);
        } else {
            setStateName(null);
            setStateId('');
        }
    };
    const handleDistrictChange = (selectedOption) => {
        if (selectedOption) {
            setDistrictName(selectedOption.label);
            setDistrictId(selectedOption.value);

        } else {
            setDistrictName(null); // Reset to null when cleared
            setDistrictId(''); // Clear the department name label
        }
    };

    const handleRoleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                id: selectedTerritory.id,
                countryId: countryId,
                stateId: stateId,
                districtId: districtId,
                isActive: checkboxEdit
            };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Territory/SaveTerritories`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Inserted successfully");

                setTimeout(() => {
                    // window.location.reload();
                    onCancel();
                }, 1500);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Role operation:", error);
            toast.error("Error performing Role operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-4 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[300]">Edit Territories</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleRoleSubmit}>
                    <div className="p-4 mx-auto">
                        <div className="w-[700px] grid grid-cols-2 gap-4 md:grid-cols-2">
                            <div className="py-1 ">
                                <div className="flex justify-between mb-1">
                                    <label htmlFor="id" className="text-xs font-400 ">
                                        Country Name<span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <button type="button" onClick={() => setPopupRole(true)}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                            <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <Select
                                    options={countryData.map(cData => ({
                                        value: cData.id,
                                        label: cData.countryName
                                    }))}
                                    className="text-black text-xs select-bar"
                                    id="countryId"
                                    isSearchable
                                    isClearable
                                    value={{ value: countryId, label: countryName }} // Set initial value here
                                    onChange={handleCountryChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                            <div className="py-1">
                                <div className="flex justify-between mb-1">
                                    <label htmlFor="id" className="text-xs font-400 ">
                                        State Name<span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <button type="button" onClick={() => setPopupState(true)}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                            <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <Select
                                    options={stateData.map(sData => ({
                                        value: sData.id,
                                        label: sData.stateName
                                    }))}
                                    className="text-black text-xs select-bar"
                                    id="stateId"
                                    isSearchable
                                    isClearable
                                    value={{ value: stateId, label: stateName }} // Set initial value here
                                    onChange={handleStateChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                            <div className="py-1">
                                <div className="flex justify-between mb-1">
                                    <label htmlFor="id" className="text-xs font-400 ">
                                        District / Province Name<span className="text-red-500 gap-3">*</span>
                                    </label>
                                    <button type="button" onClick={() => setPopupDistrict(true)}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                            <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <Select
                                    options={distData.map(disData => ({
                                        value: disData.id,
                                        label: disData.districtName
                                    }))}
                                    className="text-black text-xs select-bar"
                                    id="districtId"
                                    isSearchable
                                    isClearable
                                    value={{ value: districtId, label: districtName }} // Set initial value here
                                    onChange={handleDistrictChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#e5e7eb',
                                        },
                                    })}
                                />
                            </div>
                            <div className="py-1 flex items-end">
                                <CustomCheckBox3
                                    label={"Is Active"}
                                    state={checkboxEdit}
                                    setState={setCheckboxEdit}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center gap-5">
                        <div>
                            <CustomButton1 label={isLoading ? "Loading..." : "Update"} className="text-white bg-first" type="submit" />

                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="text-first" />
                        </div>
                    </div>
                </form>
            </div>

            {popupRole && (
                <PopUp>
                    <AddRole
                        onCancel={() => {
                            setPopupRole(false);
                            fetchCountry(); // Fetch countries when role popup is closed
                        }}
                    />
                </PopUp>
            )}

            {popupState && (
                <PopUp>
                    <AddState
                        onCancel={() => {
                            setPopupState(false);
                            fetchState();
                        }}
                    />
                </PopUp>
            )}

            {popupDistrict && (
                <PopUp>
                    <AddDistrict
                        onCancel={() => {
                            setPopupDistrict(false)
                            fetchDist();
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}
