import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewPayment({ selectedValue }) {
    const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    const formatDateTest1 = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // for 24-hour format
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <div>
            <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                    selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label={`${index + 1} - Currency Type`} value={item.currencyType} />
                            <CustomViewLabel label={`${index + 1} - PO Amount`} value={item.poAmount} />
                            <CustomViewLabel label={`${index + 1} - Total Received Amount`} value={item.totalReceivedAmount} />
                            <CustomViewLabel label={`${index + 1} - Remaining Amount`} value={item.remainingAmount} />
                            <CustomViewLabel label={`${index + 1} - Payment Terms`} value={item.paymentTerms} />
                            <CustomViewLabel label={`${index + 1} - Payment Received Date`} value={formatDateTest(item.paymentReceivedDate)} />
                            <CustomViewLabel label={`${index + 1} - Invoice Number`} value={item.invoiceNumber} />
                            <CustomViewLabel label={`${index + 1} - Payment Type`} value={item.paymentReceived} />
                            <CustomViewLabel label={`${index + 1} - Payment Received`} value={item.amount} />
                        </React.Fragment>
                    ))
                ) : (
                    <div className="text-xs ">No payment details available.</div>
                )}
            </div>
            <div className="mt-4 grid grid-cols-3 gap-4">
                <CustomViewLabel label="Payment / LC Received Closed" value={plR_IsPaymentOrLCClosed} />
                <CustomViewLabel label="Payment / Lc Received Close Date" value={formatDateTest1(selectedValue?.plR_PaymentOrLCClosedDate)} />
            </div>
        </div>
    );
}
