// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

export default function ManageCustomersView({ onCancel, selectedCustomer }) {
    
    const customerName = selectedCustomer?.customerName ?? 'N/A';
    const parentCustomer = selectedCustomer?.parentCustomer ?? 'N/A';
    const trackingStatus = selectedCustomer?.trackingStatus ?? 'N/A';
    const contactName = selectedCustomer?.contactName ?? 'N/A';
    const country = selectedCustomer?.countryName ?? 'N/A';
    const contactMobile = selectedCustomer?.mobileNo ?? 'N/A';

    const isActive = selectedCustomer?.isActive ? 'Active' : 'Inactive';
    const createdDate = selectedCustomer?.createdDate ? new Date(selectedCustomer.createdDate).toLocaleDateString('en-GB') : 'N/A';
    const creatorName = selectedCustomer?.creatorName ?? 'N/A';

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">View Customers</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto">
                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3">
                        <CustomViewLabel label="Customer Name" value={customerName} />
                        <CustomViewLabel label="Parent Customer" value={parentCustomer} />
                        <CustomViewLabel label="Tracking Status" value={trackingStatus} />
                        <CustomViewLabel label="Contact Name" value={contactName} />
                        <CustomViewLabel label="Country" value={country} />
                        <CustomViewLabel label="Contact Mobile# " value={contactMobile} />
                        {/* <CustomViewLabel label="Status " value={isActive} /> */}
                        <CustomViewLabel label="Created Date " value={createdDate} />
                        <CustomViewLabel label="Created By " value={creatorName} />
                    </div>
                </div>
                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    )
}

