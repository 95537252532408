// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Tooltip } from "@mui/material";

// COMPONENTS
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomButton4 from "../../general-components/CustomButton4.component";

// ICONS
import { IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";

import EmployeeProfileEdit from "../../forms-components/EmpHistory/EmpHistoryEdit";
import EmployeeProfileView from "../../forms-components/EmpHistory/EmpHistoryView";
import { getSinglePermission } from "../../../../Helpers/Constants";
import { useAuth } from "../../../context";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "../../general-components/Pagination.component";

export default function EmpHistory() {

  const { user } = useAuth()
  const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

  useEffect(() => {
    if (Number(user?.roleId) !== 1) {
      setPermission(getSinglePermission(user?.userRoleList, "ADMIN PROFILE"))
    }
  }, [user])

  let [popupEmployeeEdit, setPopupEmployeeEdit] = useState(false);
  let [popupEmployeeView, setPopupEmployeeView] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const token = localStorage.getItem('DIYAN_IE_authToken');
  const datacomp = { searchText: "", companyId: 0, isActive: true, departmentId: 0 };

  const fetchEmployeeData = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/User/GetUserList`,
        //  data,
        { searchText: searchQuery }, // Include searchText in the request body

        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        const sortedData = response.data.data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
        setEmployeeData(sortedData);
        setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
        setCurrentPage(1);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error("Error fetching Employee Profile Details");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmpDataById = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/User/GetUserById?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.isSuccess && response.data.data) {
        setSelectedEmployeeData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      // toast.error("Error fetching department details");
    } finally {
      setIsLoading(false);
    }
  };

  const setPopupEdit = (id) => {
    fetchEmpDataById(id)
    setPopupEmployeeEdit(true);
  }

  const setPopupView = (id) => {
    fetchEmpDataById(id);
    setPopupEmployeeView(true);
  }

  const activeEmployeeData = employeeData.filter((empData) => empData.isActive === false);
  const paginatedEmployeeData = activeEmployeeData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  console.log("🚀 ~ file: EmployeeProfile.jsx:762 ~ EmployeeProfile ~ paginatedEmployeeData:", paginatedEmployeeData)

  useEffect(() => {
    fetchEmployeeData(searchText);
  }, [searchText, rowsPerPage]);

  useEffect(() => {
    const activeEmployeeData = employeeData.filter((empData) => empData.isActive === false);
    setTotalPages(Math.ceil(activeEmployeeData.length / rowsPerPage));
  }, [employeeData, rowsPerPage]);

  const clearSearch = () => {
    setSearchText(""); // Clear the search input
  };

  return (
    <>
      <div className="border-[0]">
        <div className="flex flex-row flex-wrap justify-start gap-2  md:flex-nowrap minbox">

          <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
            <input
              type="text"
              className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
              placeholder="SEARCH"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <IoMdCloseCircle
                className="min-w-[20px] cursor-pointer txt-prp-color"
                onClick={clearSearch}
              />
            ) : (
              <AiOutlineSearch className="min-w-[20px]" />
            )}
          </div>
        </div>

        {Permission?.view &&
          <>
            {/* TABLE */}
            <div className="mt-7 mx-auto max-w-[89vw] hide-scrollbar overflow-auto table-container">
              <table className="w-full custom-table" border={1}>
                <thead>
                  <tr className="table-heading">
                    <td className="">S.No.</td>
                    {(Permission?.view === true || Permission?.edit === true) &&
                      <td>Action</td>}
                    <td>Employee Name</td>
                    <td>Email ID</td>
                    <td>Mobile Number</td>
                    <td>Role</td>
                    <td>Reporting To</td>
                    <td>Status</td>
                    <td>Created Date </td>
                    <td>Created By </td>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="11" className="text-center">Loading...</td>
                    </tr>
                  ) : (paginatedEmployeeData.length > 0 ? (
                    paginatedEmployeeData
                      .filter((empData) => empData.isActive === false) // Filter for active records
                      .map((empData, index) => (
                        <tr key={empData.id}>
                          <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                          {(Permission?.view === true || Permission?.edit === true) &&
                            <td>

                              <div className="gap-2">
                                {Permission?.edit &&
                                  <Tooltip title="EDIT" arrow placement="left">
                                    <span>
                                      <CustomButton1
                                        className="bg-sixt text-white grow max-w-[50px]"
                                        icon={<BiEdit />}
                                        onClick={() => setPopupEdit(empData.id)}
                                      />
                                    </span>
                                  </Tooltip>
                                }
                                {Permission?.view &&
                                  <Tooltip title="VIEW" arrow placement="right">
                                    <span>
                                      <CustomButton4
                                        className="bg-eye text-white grow max-w-[50px]"
                                        icon={<BsEyeFill />}
                                        onClick={() => setPopupView(empData.id)}
                                      />
                                    </span>
                                  </Tooltip>
                                }
                              </div>
                            </td>}
                          <td>{empData.userName}</td>
                          <td>{empData.emailId}</td>
                          <td>{empData.mobileNumber}</td>
                          <td>{empData.roleName}</td>
                          <td>{empData.reportingToName}</td>
                          <td>
                            <div>
                              <h2 style={{ color: empData.isActive ? 'green' : 'red' }}>
                                {empData.isActive ? 'Active' : 'Inactive'}
                              </h2>
                            </div>
                          </td>
                          <td>{new Date(empData.createdDate).toLocaleDateString('en-GB')}</td>
                          <td>{empData.creatorName}</td>
                        </tr>
                      ))
                  ) : (
                    searchText?.trim() ? (
                      <tr>
                        <td colSpan="11" className="text-center py-4">
                          No results found for "{searchText}".
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="11" className="text-center py-4">
                          No data available.
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            </div >

            <Pagination
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              data={activeEmployeeData}
            />
          </>
        }
      </div >

      {/* POPUP 2 : EDIT */}
      {popupEmployeeEdit && (
        <PopUp>
          <EmployeeProfileEdit
            selectedEmployeeData={selectedEmployeeData}
            onCancel={() => {
              fetchEmployeeData();
              setPopupEmployeeEdit(false);
            }}
          />
        </PopUp>
      )}

      {popupEmployeeView && (
        <PopUp>
          <EmployeeProfileView
            selectedEmployeeData={selectedEmployeeData}
            onCancel={() => setPopupEmployeeView(false)}
          />
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />
    </>
  )
}
