// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomNumber from "../../general-components/CustomNumber.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ClientDetails({
    next,
    onCancel,
    cName,
    setCName,
    mobile,
    setMobile,
    setParentCId,
    landLineNumber,
    setLandLineNumber,
    setCustomerId,
    setCountryId,
    countryData,
}) {

    const [custType, setCustType] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };
    const [customerData, setCustomerData] = useState([]);
    const data = { searchText: "", customerId: 0, leadStatusId: 0, parentCustomerId: 0, isActive: true };
    const [mobileError, setMobileError] = useState('');
    const [cNameError, setCNameError] = useState('');

    const validateCName = (value) => {
        if (!value) {
            setCNameError('Customer Name is required');
            return false;
        } else {
            setCNameError('');
            return true;
        }
    };

    const validateMobile = (value) => {
        if (!value) {
            setMobileError('Mobile number is required');
            return false;
        } else if (value.length !== 10) {
            setMobileError('Mobile number must be exactly 10 digits');
            return false;
        } else {
            setMobileError('');
            return true;
        }
    };

    const handleMobileChange = (e) => {
        const { value } = e.target;
        const formattedValue = value.replace(/\D/g, ''); // Remove any non-digit characters
        if (formattedValue.length <= 10) {
            setMobile(formattedValue); // Set the value only if it's 10 digits or less
        }
    };

    const handleNextClick = () => {
        const isNameValid = validateCName(cName);
        const isMobileValid = validateMobile(mobile);

        if (isNameValid && isMobileValid) {
            next();
        }
    };

    const fetchCustTypeApiData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCustomerTypeList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setCustType(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer Type Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data
                setCustomerData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer list");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustTypeApiData();
        fetchListData();
    }, [])

    const handlePaperTypeChange = (selectedOption) => {
        if (selectedOption) {
            setCustomerId(selectedOption.value);
        } else {
            setCustomerId(null); // Reset to null when cleared
        }
    };

    const handleCustomerChange = (selectedOption) => {
        if (selectedOption) {
            setParentCId(selectedOption.value);
        } else {
            setParentCId(null); // Reset to null when cleared
        }
    };

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setCountryId(selectedOption.value);
        } else {
            setCountryId(null); // Reset to null when cleared
        }
    };
    return (
        <>
            <div>
                <div className="p-2 mx-auto">
                    <table className="max-w-full popup-table w-[900px]">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="cName" className="text-xs font-[400]">
                                            Customer Name
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={cName}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${cNameError ? 'border-red-500' : ''}`}
                                            id="cName"
                                            placeholder="Enter"
                                            onChange={(e) => setCName(e.target.value)}
                                            onBlur={(e) => validateCName(e.target.value)}
                                        />
                                        {cNameError && <span className="text-red-500 text-xs">{cNameError}</span>}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <label htmlFor="mobile" className="text-xs font-[400]">
                                            Phone Number
                                            <span className="text-red-500 gap-3">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={mobile}
                                            className={`p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs ${mobileError ? 'border-red-500' : ''}`}
                                            id="mobile"
                                            placeholder="Enter"
                                            onChange={handleMobileChange}
                                            onBlur={(e) => validateMobile(e.target.value)}
                                        />
                                        {mobileError && <span className="text-red-500 text-xs">{mobileError}</span>}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomSelect1
                                        label={"Parent Customer"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        options={customerData.map(cData => ({
                                            value: cData.id,
                                            label: cData.customerName
                                        }))}
                                        onChange={handleCustomerChange}
                                    ></CustomSelect1>
                                </td>
                                <td>
                                    <div>
                                        <CustomNumber
                                            label={"Landline Number"}
                                            isRequired={false}
                                            placeholder={"ENTER"}
                                            value={landLineNumber}
                                            onChange={setLandLineNumber}
                                        ></CustomNumber>
                                    </div>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <CustomSelect1
                                        label={"Customer Type"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        options={custType.map(cData => ({
                                            value: cData.id,
                                            label: cData.customerType
                                        }))}
                                        onChange={handlePaperTypeChange}
                                    ></CustomSelect1>
                                </td>
                                <td>
                                    <CustomSelect1
                                        label={"country"}
                                        isRequired={false}
                                        placeholder={"ENTER"}
                                        options={countryData.map(cData => ({
                                            value: cData.id,
                                            label: cData.countryName
                                        }))}
                                        onChange={handleCountryChange}
                                    ></CustomSelect1>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center gap-5 mt-5">
                    <div onClick={handleNextClick}>
                        <CustomButton1 label={"Save and Continue"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}
