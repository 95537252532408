import * as React from "react";
import Button from "@mui/material/Button";

function CustomButton4(props) {
    return (
        <Button
            variant={props.variant ?? "contained"}
            size={props.size ?? "small"}
            className={` ${props.className} flex gap-2 justify-center items-center relative uppercase bg-eye`}
            type={props.type ?? "button"}
            // style={{ backgroundColor: "#003C86" }}
        >
            {props.icon && <span className="text-sm">{props.icon}</span>}
            {props.label && (
                <span className=" whitespace-nowrap font-[400] font-Mitr text-white">
                    {props.label.toUpperCase()}
                </span>
            )}

            <div
                onClick={props.onClick}
                className="absolute top-0 left-0 w-full h-full"
            ></div>
        </Button>
    );
}

export default CustomButton4;
