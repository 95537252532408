// CORE
import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react';
import axios from 'axios';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import PopUp from "../../general-components/PopUp.componenet";

// ICONSbg-white
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSinglePermission } from "../../../../Helpers/Constants";
import { useAuth } from "../../../context";
import DistrictEdit from "../../forms-components/District/DistrictEdit";
import DistrictAdd from "../../forms-components/District/DistrictAdd";
import { Tooltip } from '@mui/material';
import Pagination from '../../general-components/Pagination.component';

const District = forwardRef((props, ref) => {
    const { user } = useAuth()
    const [Permission, setPermission] = useState({ add: true, view: true, edit: true })

    useEffect(() => {
        if (Number(user?.roleId) !== 1) {
            setPermission(getSinglePermission(user?.userRoleList, "MANAGE ADMIN TERRITORY"))
        }
    }, [user])
    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const data = { searchText: "" };

    const fetchApiData = async (searchQuery) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Territory/GetDistrictList`,
                // data,
                { searchText: searchQuery }, // Include searchText in the request body
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => {
                    if (a.isActive === b.isActive) {
                        return new Date(b.createdDate) - new Date(a.createdDate);
                    }
                    return a.isActive === true ? -1 : 1;
                });

                setInputData(sortedData);
                setTotalPages(Math.ceil(sortedData.length / rowsPerPage));
                setCurrentPage(1);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Paper TypeData");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditButtonClick = (id) => {
        fetchApiDataById(id);
        setPopupEdit(true);
    };

    const fetchApiDataById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/Territory/GetDistrictById?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.isSuccess && response.data.data) {
                setSelectedValue(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Manage District / Province details");
        } finally {
            setIsLoading(false);
        }
    };

    const paginatedData = inputData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    useImperativeHandle(ref, () => ({
        fetchApiData: (searchQuery) => fetchApiData(searchQuery)
    }));

    useEffect(() => {
        fetchApiData(searchText);
    }, [searchText, rowsPerPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(inputData.length / rowsPerPage));
    }, [inputData, rowsPerPage]);

    const clearSearch = () => {
        setSearchText(""); // Clear the search input
    };

    return (
        <>
            <div>
                {/* 4 BUTTONS */}
                <div className="flex flex-row flex-wrap justify-start gap-2 md:flex-nowrap minbox">
                    {Permission?.add &&
                        <CustomButton1
                            label={"Add "}
                            icon={<IoMdAdd />}
                            className="bg-prp-color text-white shrink grow md:grow-0 max-w-[50%]"
                            onClick={() => setPopupAdd(true)}
                        />
                    }
                    <div className="flex items-center justify-between gap-1 px-2  ml-auto text-[rgba(0, 0, 0, 0.50)] boreder rounded bg-white shrink grow md:grow-0">
                        <input
                            type="text"
                            className="w-[210px] grow test-sm uppercase bg-transparent placeholder:text-sm"
                            placeholder="SEARCH"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText ? (
                            <IoMdCloseCircle
                                className="min-w-[20px] cursor-pointer txt-prp-color"
                                onClick={clearSearch}
                            />
                        ) : (
                            <AiOutlineSearch className="min-w-[20px]" />
                        )}
                    </div>
                </div>

                {Permission?.view &&
                    <>
                        {/* TABLE */}
                        <div className="mt-7 max-w-[100vw] hide-scrollbar overflow-auto table-container">
                            <table className="w-full custom-table" border={1}>
                                <thead>
                                    <tr className="table-heading">
                                        <td className="">S.No</td>
                                        {Permission?.edit &&
                                            <td>Action</td>}
                                        <td>Status</td>
                                        <td>District / Province</td>
                                        <td>Created Date </td>
                                        <td>Created By </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="7" className="text-center">Loading...</td>
                                        </tr>
                                    ) : (paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((cData, index) => (
                                            <tr key={cData.id}>
                                                <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                                {Permission?.edit &&
                                                    <td>
                                                        <div className="gap-2">
                                                            <Tooltip title="EDIT" arrow placement="right">
                                                                <span>
                                                                    <CustomButton1
                                                                        className="bg-sixt text-white grow max-w-[50px]"
                                                                        icon={<BiEdit />}
                                                                        onClick={() => handleEditButtonClick(cData.id)}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>}
                                                <td>
                                                    <div>
                                                        <h2 style={{ color: cData.isActive ? 'green' : 'red' }}>{cData.isActive ? 'Active' : 'Inactive'}</h2>
                                                    </div>
                                                </td>
                                                <td>{cData.districtName}</td>
                                                <td>{new Date(cData.createdDate).toLocaleDateString('en-GB')}</td>
                                                <td>{cData.creatorName}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        searchText?.trim() ? (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No results found for "{searchText}".
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center py-4">
                                                    No data available.
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <Pagination
                            currentPage={currentPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            data={inputData}
                        />


                    </>
                }
            </div>

            {/* POPUP 1 : ADD */}
            {popupAdd && (
                <PopUp>
                    <DistrictAdd
                        onCancel={() => {
                            setPopupAdd(false);
                            fetchApiData();
                        }}
                    />
                </PopUp>
            )}

            {/* POPUP 2 : EDIT */}
            {popupEdit && (
                <PopUp>
                    <DistrictEdit
                        selectedValue={selectedValue}
                        onCancel={() => {
                            setPopupEdit(false);
                            fetchApiData();
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
});

export default District;
