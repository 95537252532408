import React, { useEffect, useState } from 'react';
import { ImEnlarge } from "react-icons/im";
import Modal from 'react-modal';
import { MdCancel } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import "../../assets/css/selectbar.css";

function CustomGlobalUpload(props) {
    const { id, label, isRequired, readOnly, value, onChange, fileText, fileOriginalName, fileUrl } = props;
    const [inputValue, setInputValue] = useState(fileText || "");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(fileOriginalName || "");
    const [fileType, setFileType] = useState("");
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [fileBase64, setFileBase64] = useState("");

    useEffect(() => {
        // Update the inputValue when fileText prop changes
        setInputValue(fileText || "");
    }, [fileText]);

    useEffect(() => {
        if (error) {
            const errorTimeout = setTimeout(() => {
                setError("");
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }
    }, [error]);

    useEffect(() => {
        if (fileUrl && !selectedFile) {
            setFileType(fileOriginalName?.endsWith(".pdf") ? "application/pdf" : "image/jpeg");
        }
    }, [fileUrl, selectedFile, fileOriginalName]);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
            if (!allowedTypes.includes(file.type)) {
                setError("jpg, jpeg, png images and PDF are allowed.");
                return;
            }
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > 10) {
                setError("File size is more than 10 MB.");
                return;
            } else {
                setError("");
                setSuccessMessage("uploaded.");
                setTimeout(() => {
                    setSuccessMessage("");
                }, 5000);
            }
            setFileName(file.name);
            setSelectedFile(file);
            setFileType(file.type);

            const base64 = await convertToBase64(file);
            setFileBase64(base64);
            onChange({ inputValue, fileName: file.name, fileBase64: base64 });
        } else {
            setFileName("");
            setSelectedFile(null);
            setFileType("");
            setFileBase64("");
            onChange({ inputValue, fileName: "", fileBase64: "" });
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, '');
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        setSelectedFile(null);
        setFileName("");
        setFileType("");
        setFileBase64("");
        onChange({ inputValue, fileName: "", fileBase64: "" });
    
        // Clear file input to allow re-uploading the same file
        document.getElementById(`file-upload-${id}`).value = null;
    };
    

    const handleDownload = () => {
        const url = selectedFile ? URL.createObjectURL(selectedFile) : fileUrl;
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEnlarge1 = () => {
        setIsModalOpen(true);
    };

    const handleEnlarge = () => {
        if (selectedFile) {
            const fileURL = URL.createObjectURL(selectedFile);
    
            // Open the file in a new tab (works for both images and PDFs)
            window.open(fileURL, '_blank');
        }
    };  

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        const value = event.target.value.toUpperCase();
        setInputValue(value);
        onChange({ inputValue: value, fileName, fileBase64 });
    };

    return (
        <div className="flex flex-col gap-1 relative">
            <div className="flex flex-col gap-1">
                <label htmlFor={id} className="text-xs font-[400]">
                    {label.toUpperCase()}
                    {isRequired && <span className="text-red-500 gap-3">*</span>}
                </label>
                <input
                    type="text"
                    readOnly={readOnly}
                    className="p-2 border uppercase rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs"
                    id={id}
                    placeholder="ENTER"
                    value={inputValue}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="flex justify-between items-center mt-1">
                    <div>
                        <label
                            htmlFor={`file-upload-${id}`}
                            className="cursor-pointer text-xs bg-prp-color text-white p-2 rounded-md"
                        >
                            Attachment
                        </label>
                        <input
                            type="file"
                            id={`file-upload-${id}`}
                            key={selectedFile ? fileName : "no-file"} // Dynamic key to force re-render
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </div>

                    {fileName && (
                        <div className="flex items-center space-x-1">
                            <button onClick={handleDownload} type='button' className="bg-prp-color text-white p-1 rounded">
                                <FaDownload h-8 w-8 />
                            </button>
                            <button onClick={handleCancel} type='button' className="bg-white txt-prp-color border border-prp p-1 rounded">
                                <MdCancel h-8 w-8 />
                            </button>
                            <button onClick={handleEnlarge} type='button' className="bg-prp-color text-white p-1 rounded">
                                <ImEnlarge h-8 w-8 />
                            </button>
                        </div>
                    )}

                    <div>
                        {!selectedFile && fileUrl && fileType.startsWith('image/') && (
                            <img
                                src={fileUrl}
                                alt="Preview"
                                className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                            />
                        )}
                        {!selectedFile && fileUrl && fileType === 'application/pdf' && (
                            <embed
                                src={fileUrl}
                                type="application/pdf"
                                className="w-10 h-10 border border-[#3D3D3D66]"
                            />
                        )}
                        {selectedFile && fileType.startsWith('image/') && (
                            <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="Preview"
                                className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
                            />
                        )}
                        {selectedFile && fileType === 'application/pdf' && (
                            <embed
                                src={URL.createObjectURL(selectedFile)}
                                type="application/pdf"
                                className="w-10 h-10 border border-[#3D3D3D66]"
                            />
                        )}
                    </div>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Enlarge File"
                    className="modal-content"
                    overlayClassName="modal-overlay"
                >
                    <div className="coman-modal-flex">
                        {fileName && (
                            <div className="coman-modal-text">
                                <p>{fileName}</p>
                            </div>
                        )}
                        <div className="coman-modal">
                            <button onClick={handleDownload} type='button' className="modal-btn-comman modal-btn-dwl"><FaDownload h-8 w-8 /> </button>
                            <button onClick={closeModal} type='button' className="modal-btn-comman modal-btn-cnl"><MdCancel h-8 w-8 /></button>
                        </div>
                    </div>
                    {selectedFile && fileType.startsWith('image/') && (
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Enlarged"
                            className="w-full h-auto"
                        />
                    )}
                    {selectedFile && fileType === 'application/pdf' && (
                        <embed
                            src={URL.createObjectURL(selectedFile)}
                            type="application/pdf"
                            alt="Enlarged"
                            className="w-full h-auto"
                        />
                    )}
                </Modal>
            </div>
            {error && <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'red' }}>{error}</p>}
            {successMessage && (
                <p className="absolute -bottom-6 text-[10px] font-normal mb-2" style={{ color: 'green' }}>{successMessage}</p>
            )}
        </div>
    );
}

CustomGlobalUpload.defaultProps = {
    isRequired: true,
};

export default CustomGlobalUpload;
